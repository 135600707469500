import Component from "core/ts/system/Component";
import Template from "../Template";
import App from "ts/app";
import StaticElement from "core/ts/system/static/StaticElement";
import FilterNode from "../../utils/FilterNode";
import BoundingBox from 'core/ts/utils/BoundingBox';
import {SignalDispatcher} from 'strongly-typed-events';

export default class StaticTemplate extends Template {

	private _onPageRecalculate = new SignalDispatcher();
	public get onPageRecalculate() {
	    return this._onPageRecalculate.asEvent();
	}

    private _pageHeight:number = 0;

	// private _queuedRender:Function[] = [];
    private _boundingBox:BoundingBox;

	private _staticFilter: FilterNode<StaticElement>;
	private _isRenderReady:boolean = false;
	private _isRecalculating:boolean = false;

    protected __build(): void {
        this.autoUpdatePageHeight = false;
        super.__build();
    }

    /**
     * @ignore
     */
    public __init() {
	    this._boundingBox = new BoundingBox();
        this._staticFilter = new FilterNode<StaticElement>(this, StaticElement);
	    this._staticFilter.onRemoved.sub(this.onStaticRemoved);

        super.__init();
    }

    public __awake():void {
    	App.RESIZE.onBeforeResize.sub( this.enterAllElements );
        App.RESIZE.onResize.sub( this.calculatePageHeight );
	    App.RESIZE.onAfterResize.sub( this.exitAllElements );
        super.__awake();

        this.node.onAnyChildAdded.sub( this.onAnyChildAdded );
	    this.node.onAnyChildRemoved.sub( this.onAnyChildRemoved );

        this.calculatePageHeight();
        this.updateScroll();

	    if(!App.SCROLL) {
		    window.addEventListener('scroll', this.updateScroll);
	    }
    }

    private onAnyChildAdded = ( comp:Component ) => {
		this.calculatePageHeight();
    };

	private onAnyChildRemoved = ( comp:Component ) => {
		this.calculatePageHeight();
	};

    private onStaticRemoved = ( comp:Component ) => {
	    comp.node.onElementRemoved.one( this.calculatePageHeight );
    };

    protected __sleep():void {
	    App.RESIZE.onBeforeResize.unsub( this.enterAllElements );
	    App.RESIZE.onResize.unsub( this.calculatePageHeight );
	    App.RESIZE.onAfterResize.unsub( this.exitAllElements );

	    this.node.onAnyChildAdded.unsub( this.onAnyChildAdded );
	    this.node.onAnyChildRemoved.unsub( this.onAnyChildRemoved );

	    if(!App.SCROLL) {
		    window.removeEventListener('scroll', this.updateScroll);
	    }

        super.__sleep();
    }

    private updateScroll = () => {
    	if(App.SCROLL) {

            this.scrollTo( -App.SCROLL.getCurrentScroll().y );
	    } else {
		    this.scrollTo( -window.pageYOffset);
	    }
    };

    public scrollTo( yScroll:number ) {
	    // console.log(yScroll);
	    if( App.SCROLL ) {
            super.scrollTo(yScroll);
	    }

	    this._boundingBox.y = yScroll * -1;
	    this._boundingBox.height = App.RESIZE.windowHeight;

        const l = this._staticFilter.children.length;
        for (let i = 0; i < l; i++) {
            const kid = this._staticFilter.children[i];
			kid.updateViewBounds( this._boundingBox );
        }
    }

    public getPageHeight(): number {
        return this.getElement().offsetHeight;
    }

	/**
	 *
	public queueRender( render:Function ) {
	    this._queuedRender.push(render);
	}
	private callAllQueued() {
		 //Move to stack and clear queue before calling queue function.
		 //If not cleared and moved, you risk an endless loop;
		 const callStack:Function[] = [];
		 this._queuedRender.forEach( item => {
			    callStack.push(item);
		    });
		 this._queuedRender = [];

		 //Call Queue
		 callStack.forEach(item=> {
			    item();
		    });
	}
	**/

    public requestRender( render:Function ) {
    	if(this._isRenderReady) {
    		render();
    		return;
	    }

	    this.enterAllElements();

	    render();
		// this.callAllQueued();

	    this.exitAllElements();
    }


    private enterAllElements = () => {
    	if(this._isRenderReady) {
    		return;
	    }
	    //Make sure all is displayed before render
	    this._staticFilter.children.forEach( item => {
		    if( !item.isEntered() ) {
			    item.showElementContainer();
		    }
	    });
	    this._isRenderReady = true;
    };

    private exitAllElements = () => {
	    if(!this._isRenderReady) {
		    return;
	    }
	    //Exit all not entered elements after render is done
	    this._staticFilter.children.forEach( item => {
		    if( !item.isEntered() ) {
			    item.hideElementContainer();
		    }
	    });
	    this._isRenderReady = false;
    };

    public calculatePageHeight = () => {
        if(!this.isAwake()) {
            return;
        }
        if( this._isRecalculating ) {
        	console.warn( 'StaticTemplate is already in progress of recalculating. \n' +
		        'Recalculation is cancel, do to possible infinite loop call. \n' +
		        'This could happen if you in the event onPageRecalculate also call calculatePageHeight()' );
        	return;
        }

        // console.log('calculate');

	    this._isRecalculating = true;
        this.requestRender( ()=> {

		    this._boundingBox.width = App.RESIZE.windowWidth;
		    this._boundingBox.x = 0;

	        const l = this._staticFilter.children.length;

	        this._pageHeight = 0;
	        for (let i = 0; i < l; i++) {
		        const module = this._staticFilter.children[i];
		        // module.__updateStaticPageY();
		        module.__updateStaticSize();
	        }

	        this._onPageRecalculate.dispatch();

	        for (let i = 0; i < l; i++) {
		        const module = this._staticFilter.children[i];
		        module.__updateStaticPageY();
		        // module.__updateStaticSize();
	        }

	        //CALLBACK ON RENDER PAGE;

        } );

        this.dispatchNewPageHeight();
        this.updateScroll();
	    this._isRecalculating = false;
    };

}
