//Import for Webpack
import './Preloader.scss';
import App from "ts/app";
import { TweenLite, Expo } from 'gsap';
import Timeout = NodeJS.Timeout;
import Component from "core/ts/system/Component";
import CursorManager from "core/ts/utils/cursor/CursorManager";

export default class Preloader extends Component {
    private _preloaderMobileGfx:HTMLElement;

    private _isLoading:boolean = false;

    private _showTimeout:Timeout = null;

    protected build(): void {
        // console.log('Preloader.build');

        this._preloaderMobileGfx = this.qs('.preloader-mobile');
        this._preloaderMobileGfx.style.display = 'none';

        App.CONTENT.onLoadingStart.sub(this.onLoadStart);
        App.CONTENT.onLoadingDone.sub(this.onLoadDone);

        // this.show();
    }

    protected awake():void {
        super.awake();
    }

    private onLoadStart = () => {
        // console.log('Preloader.onLoadStart();');

        this._isLoading = true;
        this.show();
    };

    private onLoadDone = () => {
        // console.log('Preloader.onLoadDone();');

        this._isLoading = false;
        this.hide();
    };

    private show():void {
        // console.log('Preloader.show();');

        if(this._showTimeout !== null) {
            clearTimeout(this._showTimeout);
            this._showTimeout = null;
        }

        this._showTimeout = setTimeout(this.showDelayed, 200);
    }

    private showDelayed = () => {
        CursorManager.Instance.setForcedCursor('preloader');
        this._preloaderMobileGfx.style.display = 'block';
    };

    private hide():void {
        // console.log('Preloader.hide();');

        if(this._showTimeout !== null) {
            clearTimeout(this._showTimeout);
            this._showTimeout = null;
        }

        this.hideDelayed();
    }

    private hideDelayed = () => {
        CursorManager.Instance.setForcedCursor(null);
        this._preloaderMobileGfx.style.display = 'none';
    };
}