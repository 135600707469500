//Import for Webpack
import Component from "core/ts/system/Component";
import CoreSlideshowModule from "core/modules/CoreSlideshowModule/CoreSlideshowModule";

export default class SlideshowComponent extends Component {
    protected slideshow: CoreSlideshowModule;

    public SetSlideshow(slideshow: CoreSlideshowModule) {
        this.slideshow = slideshow;
    }

    protected awake(): void {
        this.slideshow.onUpdateEvent.sub(this.onSlideshowUpdate);
        super.awake();
    }

    protected sleep(): void {
        this.slideshow.onUpdateEvent.unsub(this.onSlideshowUpdate);
        super.sleep();
    }

    private onSlideshowUpdate = () => {
        this.updateToPage();
    };

    protected updateToPage(): void {}
}