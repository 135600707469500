import Component from "core/ts/system/Component";
import {RenderItem} from "core/ts/utils/RenderLoop";
import RenderLoop from "core/ts/utils/RenderLoop";
import ColorManager from "ts/ColorManager";

export default class Cursor {
	public cursorName:string;

	private _renderItem:RenderItem = null;

	constructor(cursorName:string) {
		this.cursorName = cursorName;

		// console.log('Cursor - ' + this.cursorName);

		ColorManager.Instance.onForegroundColorChange.sub(this.onForegroundColorChange);
	}

	private onForegroundColorChange = ():void => {
		this.render(0, 0);
	};

	public activate(oldCursor:Cursor = null):void {

    }

    public deactivate():void {
		this.stopRender();
	}

	public getElement():HTMLElement {
		return null;
	}

	/**
	 * Render
	 * @param timeToStop
	 */
	protected startRender():void {
		// console.log('startRender(); ' + this.cursorName);

		this.render(0, 0);
		if(this._renderItem === null) {
			this._renderItem = RenderLoop.Instance.add(this.renderCallback, 60);
		}
	}

	protected stopRender():void {
		// console.log('stopRender(); ' + this.cursorName);

		if(this._renderItem !== null) {
			RenderLoop.Instance.remove(this._renderItem);
			this._renderItem = null;
		}
	}

	private renderCallback = (deltaTime:number, totalTime:number) => {
		this.render(deltaTime, totalTime);
	};

	protected render(deltaTime:number, totalTime:number):void {
	}
}