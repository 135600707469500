import Template from '../Template';
import TemplateManager from 'core/ts/system/TemplateManager';
import TransitionController, {MODE} from 'core/ts/system/transition/TransitionController';

export abstract class TemplateSwitcher {
	private _activeTemplates: Template[] = [];

	private readonly _queueTemplates: boolean = true;
	protected _isAnimatingIn: boolean = false;
	protected _isAnimatingOut: boolean = false;

	private _preTemplate: Template;
	private _currentTemplate: Template;

	protected manager: TemplateManager;

	constructor(queueTemplates: boolean = true) {
		this._queueTemplates = queueTemplates;
	}

	public addNewTemplate(template: Template): void {

		if(this._queueTemplates) {
			this._activeTemplates.push(template);
		} else {
			while(this._activeTemplates.length > 0) {
				this._activeTemplates.pop().__kill();
			}
			this._activeTemplates.push(template);
		}
	}

	protected shiftNewTemplate(): Template {
		if(this.queuedAnimations <= 0) {
			// console.log('NO MORE TEMPLATES FOUND');
			return null;
		}

		this._preTemplate = this._currentTemplate;
		this._currentTemplate = this._activeTemplates.shift();

		return this._currentTemplate;
	}

	protected animateIn(template: Template): TransitionController {
		this._isAnimatingIn = true;
		const controller = this.manager.setTemplate(template);

		//Set default controller Settings; TODO:set these somewhere else and make them more modular
		controller.preTemplate = this._preTemplate;
		controller.queuedAnimations = this.queuedAnimations;

		controller.onAllComplete.one((controller: TransitionController) => {
			this.onInComplete(controller);
		});
		controller.start(MODE.IN);
		return controller;
	}


	protected animateOut(template: Template): TransitionController {
		this._isAnimatingOut = true;
		const controller = this.manager.removeTemplate(template);

		//Set default controller Settings; TODO:set these somewhere else and make them more modular
		controller.queuedAnimations = this.queuedAnimations;
		controller.nextTemplate = this._currentTemplate;

		controller.onAllComplete.one((controller: TransitionController) => {
			this.onOutComplete(controller);
		});
		controller.start(MODE.OUT);
		return controller;
	}

	protected onInComplete(controller: TransitionController) {
		this._isAnimatingIn = false;
	};

	protected onOutComplete(controller: TransitionController) {
		this._isAnimatingOut = false;
	};

	public get queuedAnimations() {
		return this._activeTemplates.length;
	}

	public setManager(manager: TemplateManager): void {
		this.manager = manager;
	}

	public urlChange() {
	}

	public kill(): void {
	}
}

/**
 * AnimateOnLoad
 * -Animate in and out at the same time and wait for both new template __tryLoad and last animation out.
 */
export class AnimateOnLoad extends TemplateSwitcher {

	private _currentInController: TransitionController;

	private templateOut: Template;
	private templateIn: Template;

	public addNewTemplate(template: Template): void {
		super.addNewTemplate(template);
		this.startAnimationSequence();
	}

	private startAnimationSequence() {
		if(!this._isAnimatingIn && !this._isAnimatingOut && this.queuedAnimations > 0) {
			this.templateIn = this.shiftNewTemplate();
			if(this._currentInController) {
				this.templateOut = this._currentInController.rootModule as Template;
				this.animateOut(this.templateOut);
			}
			this._currentInController = this.animateIn(this.templateIn);
		}
	}

	protected onInComplete(controller: TransitionController) {
		// console.log('IN COMPLETE');
		super.onInComplete(controller);
		this.startAnimationSequence();
	};

	protected onOutComplete(controller: TransitionController) {
		// console.log('OUT COMPLETE');
		super.onOutComplete(controller);
		this.startAnimationSequence();
	};
}

/**
 * AnimateInstant
 * -Animates template in and out instantly, not waiting for any other templates;
 */
export class AnimateInstant extends TemplateSwitcher {

	private _currentInController: TransitionController;
	private readonly _outOnUrlChange: boolean = false;

	constructor(outOnUrlChange: boolean = true, queueTemplates: boolean = false) {
		super(queueTemplates);
		this._outOnUrlChange = outOnUrlChange;
	}

	public urlChange() {
		super.urlChange();
		if(this._outOnUrlChange) {
			this.startAnimationSequence();
		}
	}

	public addNewTemplate(template: Template): void {
		super.addNewTemplate(template);
		this.startAnimationSequence();
	}

	private startAnimationSequence() {
		if(this._currentInController && this._currentInController.isDone()) {
			this.animateOut(this._currentInController.rootModule as Template);
		}

		if(this.queuedAnimations > 0) {
			this._currentInController = this.animateIn(this.shiftNewTemplate());
		}
	}

	protected onInComplete(controller: TransitionController) {
		super.onInComplete(controller);
		if(this._currentInController !== controller || this.manager.isLoadingTemplate()) {
			this.animateOut(controller.rootModule as Template);
		}
	};

}

/**
 * AnimateOnLoad
 * -Animate in when the out animation is done.
 */
export class AnimateOnOut extends TemplateSwitcher {

	private _currentInController: TransitionController;

	private templateOut: Template;
	private templateIn: Template;

	public addNewTemplate(template: Template): void {

		// console.log('Add new template ' + template.path);

		super.addNewTemplate(template);
		this.startAnimationSequence();
	}

	private startAnimationSequence() {
		if(!this._isAnimatingIn && !this._isAnimatingOut && this.queuedAnimations > 0) {
			this.templateIn = this.shiftNewTemplate();
			if(this._currentInController) {
				this.templateOut = this._currentInController.rootModule as Template;
				this.animateOut(this.templateOut);
			} else {
				this._currentInController = this.animateIn(this.templateIn);
			}
		}
	}

	protected onInComplete(controller: TransitionController) {
		const template = controller.rootModule as Template;
		// console.log('ON IN COMPLETE ' + template.path);
		super.onInComplete(controller);
		this.startAnimationSequence();
	};

	protected onOutComplete(controller: TransitionController) {
		const template = controller.rootModule as Template;
		// console.log('ON OUT COMPLETE ' + template.path);
		super.onOutComplete(controller);
		this._currentInController = this.animateIn(this.templateIn);
	};
}

