//Import for Webpack
import './MainMenuButton.scss';

import Component from "core/ts/system/Component";
import App from "ts/app";
import Path from "core/ts/utils/Path";
import {TweenLite, Expo, Ease, Quad, Sine, Quart} from 'gsap';
import BreakpointItem from 'core/ts/utils/breakpoints/BreakpointItem';
import Breakpoints from 'core/ts/utils/breakpoints/Breakpoints';

export default class MainMenuButton extends Component {
    private _isSelected:boolean = false;
    private _thisPath:string;

    private _svg:HTMLElement = null;
    private _container:HTMLElement = null;

    private _tween:any = {ratio:0}

    private _endY:number;
    private _width:number;

    protected build(): void {
        this.getElement().style.textDecoration = 'none';
        this._thisPath = Path.getWellFormatedPath(this.getElement().getAttribute('href'));

        this.onNewTemplate();
        Breakpoints.Instance.onNewBreakpoint.sub(this.onNewBreakpoint);
    }

    protected awake(): void {
        this.getElement().addEventListener('mouseover', this.onMouseOver);
        this.getElement().addEventListener('mouseout', this.onMouseOut);

        App.CONTENT.onNewPath.sub(this.onNewTemplate);

        this._endY = 0;
        // this._width = this.getElement().offsetWidth;
        let textWrapper = this.qs('.text-wrapper');
        this._width = textWrapper.offsetWidth;

        this._container = document.createElement('div');
        this._container.style.pointerEvents = 'none';
        this._container.style.position = 'absolute';
        this._container.style.left = '-10px';
        this.getElement().appendChild(this._container);

        this._svg = document.createElement('div');

        this._svg.style.top = 20 + 'px';

        this._container.appendChild(this._svg);

        this.onNewBreakpoint();
    }

    protected sleep(): void {
        this.getElement().removeEventListener('mouseover', this.onMouseOver);
        this.getElement().removeEventListener('mouseout', this.onMouseOut);

        App.CONTENT.onNewPath.unsub(this.onNewTemplate);
    }

    private onNewTemplate = () => {
        // console.log('Path.getCurrentInternalPathAtLevel(0) : ' + Path.getCurrentInternalPathAtLevel(0));

        if(Path.getCurrentInternalPathAtLevel(0) === this._thisPath) {
            this._isSelected = true;
            this.onMouseOver();
        } else {
            this._isSelected = false;
            this.onMouseOut();
        }
    };

    private onMouseOver = () => {

        TweenLite.to(this._tween, .3, {ratio: 1, ease: Expo.easeInOut, overwrite: true, onUpdate: this.updateToRatio});
    };

    private onMouseOut = () => {
        if(!this._isSelected) {
            TweenLite.to(this._tween, .3, {ratio: 0, ease: Expo.easeInOut, overwrite: true, onUpdate: this.updateToRatio});
        }
    };

    private updateToRatio = ():void => {
        let startX:number = 10;
        let startY:number = 20;

        let endX:number = startX + this._width;

        let currPt:any = this.interpolate(startX, startY, endX, startY + this._endY, this._tween.ratio);
        
        // console.log(currPt.y);

        let data:string = '<svg xmlns="http://www.w3.org/2000/svg"><line stroke-width="1.5" x1="' + startX + '" y1="' + startY + '" x2="' + currPt.x + '" y2="' + currPt.y + '" stroke="white" /></svg>';
        // console.log('data : ' + data);
        this._svg.innerHTML = data;
    };

    private interpolate(x1:number, y1:number, x2:number, y2:number, ratio:number):any {
        let nx:number = x1 + (x2 - x1) * ratio;
        let ny:number = y1 + (y2 - y1) * ratio;
        return {x: nx,  y: ny};
    };

    private onNewBreakpoint  = ():void => {
        if(Breakpoints.Instance.getBreakpointName() === Breakpoints.Instance.mobile.getName()) {
            this._container.style.top = '15px';
        } else {
            this._container.style.top = '0px';
        }
    }
}