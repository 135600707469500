import Template from 'core/ts/system/Template';
import {TweenLite, Expo, Ease} from 'gsap';
import ScrollController from "core/ts/utils/scroll/ScrollController";

export default class ScrollControllerCustom extends ScrollController {
	/**
	 * Settings
	 */
	public static SCROLL_TO_SPEED:number = .5;
	public static SCROLL_SPEED:number = 0;
	public static SCROLL_EASE:Ease = Expo.easeOut;

	private _scrollSpeed:number = 0;

	private _fake: HTMLElement;
	private _wrapper: HTMLElement;
	private _content: HTMLElement;

	private _ease:Ease;

	private _pageHeight = 0;

	constructor() {
		super();

		this.setupContainers();

		if (ScrollController.isOnTouch()) {
			this._scrollSpeed = 0;
		}

		this._scrollSpeed = ScrollControllerCustom.SCROLL_SPEED;

		window.addEventListener('scroll', this.onUserScroll);
	}


	public scrollTo(pos: number, speed: number = -1, ease: Ease = Expo.easeOut) {
		this._scrollSpeed = speed !== - 1 ? speed : ScrollControllerCustom.SCROLL_TO_SPEED;
		this._ease = ease;

		window.scrollTo(0, pos);

		this.saveScroll();
	}

	public setTemplate(template: Template) {

		if(this.template){
			this.template.onPageHeightChange.unsub( this.updatePageHeight );
		}
		console.log('template : ' + template);

		this.currentScroll.y = template.startScrollAt();
		window.scrollTo(0, template.startScrollAt());

		super.setTemplate(template);

        this.template.onPageHeightChange.sub( this.updatePageHeight );
        this.updatePageHeight();
	}

	private setupContainers() {
		//FAKE
		this._fake = document.createElement('div');
		this._fake.style.position = 'absolute';
		this._fake.style.width = '100%';
		this._fake.style.visibility = 'hidden';
		this._fake.id = 'fake-scroll';
		document.body.appendChild(this._fake);

		//WRAPPER
		this._wrapper = document.getElementById('SiteWrapper');
		if (!this._wrapper) {
			this._wrapper = document.createElement('div');
			document.body.appendChild(this._wrapper);
		}
		this._wrapper.style.position = 'fixed';
		this._wrapper.style.width = '100%';
		this._wrapper.style.height = '100vh';
		this._wrapper.style.overflow = 'hidden';

		//CONTENT
		this._content = document.getElementById('TemplateLayer');
		if (!this._content) {
			this._content = document.createElement('div');
			this._wrapper.appendChild(this._content);
		}
		this._content.style.position = 'relative';
		this._fake.style.height = this._content.offsetHeight + 'px';
	}

	private updatePageHeight = (): void => {
		if (this.template) {
            let newHeight = this.template.getPageHeight();
            if( newHeight !== this._pageHeight ) {
                this._pageHeight = newHeight;
                this.triggerNewPageHeight.dispatch();
            } else {
                return;
            }

			this._fake.style.height = this._pageHeight + 'px';
		} else {
			this._fake.style.height = this._content.offsetHeight + 'px';
		}
	};

	private onUserScroll = (): void => {
		this.handleScroll();
	};

	private handleScroll = (): void => {
		let yPos = window.pageYOffset;

		TweenLite.killTweensOf(this.currentScroll);
		TweenLite.to(this.currentScroll, this._scrollSpeed, {
			y: yPos,
			onStart: () => {
				this.triggerStart.dispatch();
			},
			onUpdate: () => {
				if ( this.template) {
					this.template.scrollTo(-this.currentScroll.y);
				} else {
					this._content.style.top = -this.currentScroll.y + 'px';
				}

				this.saveScroll();

				this.triggerMove.dispatch();
			},
			onComplete: () => {
				requestAnimationFrame(() => {
					this.triggerEaseStop.dispatch();
				});
			},
			roundProps: ['y'],
			ease: this._ease
		});


		this._scrollSpeed = ScrollControllerCustom.SCROLL_SPEED;
		this._ease = ScrollControllerCustom.SCROLL_EASE;
	};
}
