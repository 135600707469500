import { TweenLite, Expo } from 'gsap';
import BrowserDetect from "core/ts/utils/BrowserDetect";
import Component from "core/ts/system/Component";
import RenderLoop, {RenderItem} from "core/ts/utils/RenderLoop";
import Cursor from "core/ts/utils/cursor/Cursor";
import ArrowCursor from "ts/cursors/ArrowCursor";
import LinkCursor from "ts/cursors/LinkCursor";
import DefaultCursor from "ts/cursors/DefaultCursor";
import SlideshowCursor from "ts/cursors/SlideshowCursor";
import PreloaderCursor from "ts/cursors/PreloaderCursor";

export default class CursorManager {
    private _xPos:number = 0;
    private _yPos:number = 0;

    private _cursors:Array<Cursor> = [
        new DefaultCursor ('default'),
        new ArrowCursor ('arrow'),
        new LinkCursor('link'),
        new SlideshowCursor('slideshow'),
        new PreloaderCursor('preloader'),
    ];

    private _defaultCursor:string = 'default';
    // private _defaultCursor:string = 'preloader';

    private _container:HTMLElement;

    private _activeCursor:Cursor = null;
    private _activeCursorName:string = null;
    private _forceCursor:string = null;


    private static _instance: CursorManager;
    public static get Instance(): CursorManager {
        if(this._instance == null) {
            this._instance = new CursorManager();
        }
        return this._instance;
    }

    constructor() {
        console.log('LinkCursor.constructor();');

        if(!this.isSupported()) {
            return;
        }

        window.addEventListener('mousemove', this.onMouseMove);
        document.addEventListener('mouseenter', this.onMouseMove);

        // container
        this._container = document.createElement('div') as HTMLElement;
        this._container.classList.add('cursor-container');
        this._container.style.pointerEvents = 'none';
        document.body.appendChild(this._container);
        this._container.style.position = 'fixed';

        this.setDefaultCursor();

        document.body.style.cursor = 'none';
        // document.body.style.cursor = 'inherit';

        // let renderItem:RenderItem = RenderLoop.Instance.add(this.render, 60);
    }

    private onMouseMove = (e:MouseEvent) => {
        this._xPos = e.clientX;
        this._yPos = e.clientY;

        TweenLite.set(this._container, {left: this._xPos, top: this._yPos});
    };



    private render = (deltaTime:number, totalTime:number) => {
        this._container.style.left = this._xPos + 'px';
        this._container.style.top = this._yPos + 'px';
    };


    /**
     * Cursor set
     */
    public setCursor(target:string):void {
        if(!this.isSupported()) {
            return;
        }

        // console.log('target : ' + target);

        this._activeCursorName = target;

        if(this._forceCursor !== null) {
            return;
        }

        this.updateCursor();
    }

    public setDefaultCursor():void {
        if(!this.isSupported()) {
            return;
        }

        // console.log('LinkCursor.setDefaultCursor();');

        // document.body.style.cursor = 'inherit';
        this.setCursor(this._defaultCursor);
    }

    public setForcedCursor(target:string):void {
        if(!this.isSupported()) {
            return;
        }

        this._forceCursor = target;

        this.updateCursor();
    }

    private updateCursor():void {
        let newCursorName = this._forceCursor !== null ? this._forceCursor : this._activeCursorName;

        // console.log('newCursorName : ' + newCursorName);

        if(this._activeCursor !== null && this._activeCursor.cursorName === newCursorName) {
            return;
        }

        let oldCursor:Cursor = this._activeCursor;

        if(this._activeCursor !== null) {
            this._activeCursor.deactivate();
            if(this._activeCursor.getElement() !== null) {
                this._container.removeChild(this._activeCursor.getElement());
            }
            this._activeCursor = null;
        }

        let newCursor:Cursor = this.getCursorByName(newCursorName);
        if(newCursor.getElement() !== null) {
            this._container.appendChild(newCursor.getElement());
        }
        newCursor.activate(oldCursor);

        this._activeCursor = newCursor;
    }

    private getCursorByName(target:string):Cursor {
        const l = this._cursors.length;
        for(let i = 0; i < l; i += 1) {
            let cursorItem = this._cursors[i];

            if(cursorItem.cursorName === target) {
                return cursorItem;
            }
        }

        return null;
    }

    /**
     * Positions
     */
    public xPos():number {
        return this._xPos;
    }

    public yPos():number {
        return this._yPos;
    }

    public isSupported():boolean {
        return !(BrowserDetect.isMobile() && BrowserDetect.isTablet());
    }
}