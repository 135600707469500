import { SignalDispatcher } from 'strongly-typed-events';
import ResizeManager from "core/ts/utils/ResizeManager";
import App from "ts/app";

export default class BreakpointItem {
    private _name:string;
    private _width:number;

    constructor(name:string, width:number) {
        this._name = name;
        this._width = width;
    }

    public getName():string {
        return this._name;
    }

    public getWidth():number {
        return this._width;
    }
}