import Component from "core/ts/system/Component";
import ViewInfo from "core/ts/utils/ViewInfo";
import App from "ts/app";
import BoundingBox from "../utils/BoundingBox";
import LinkHandler from "core/ts/utils/LinkHandler";

export default abstract class Module extends Component {

    public viewInfo:ViewInfo;

    private _staticBoundsRect: BoundingBox;
    private _staticBoundsOffset: number = -1;

    protected useStaticBounds: Boolean = false;

    /**
     * @ignore
     */
    protected __build() {
        this.viewInfo = new ViewInfo(this);
        super.__build();
    }

    public updateStaticClientRect() {
        if (!this.useStaticBounds) {
            return;
        }
        let bounds = this.getElement().getBoundingClientRect();
        this._staticBoundsOffset = bounds.top + App.SCROLL.getCurrentScroll().y;
        this._staticBoundsRect = BoundingBox.from( bounds );
        if(this.viewInfo) {
            this.viewInfo.updateRect( this._staticBoundsRect );
        }
    }

    public getBoundingClientRect() {
        if(!this.useStaticBounds) {
            return super.getBoundingClientRect();
        }

        //Performance opt by not calling getBoundingClientRect and redrawing the entire DOM
        if (!this._staticBoundsRect) {
            this.updateStaticClientRect();
        }

        this._staticBoundsRect.y = this._staticBoundsOffset - App.SCROLL.getCurrentScroll().y;

        return this._staticBoundsRect;
    }
}