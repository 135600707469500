import Component from "core/ts/system/Component";
import ColorManager from "ts/ColorManager";
import StringUtils from "core/ts/utils/StringUtils";
import LinkHandler from "core/ts/utils/LinkHandler";
import RenderLoop, {RenderItem} from "core/ts/utils/RenderLoop";
import PreloaderCursor from "ts/cursors/PreloaderCursor";

export default class PreloaderIcon extends Component {
    private _animation:PreloaderCursor;

    protected build():void {
        super.build();

        // console.log('PreloaderIcon.build()');

        this._animation = new PreloaderCursor('preloader');
        this.getElement().appendChild(this._animation.getElement());
    }

    protected awake():void {
        super.awake();

        // console.log('PreloaderIcon.awake()');

        ColorManager.Instance.onForegroundColorChange.sub(this.onForegroundChange);
        this.onForegroundChange();

        this._animation.activate(null);
    }

    protected sleep(): void {
        // console.log('PreloaderIcon.sleep()');

        ColorManager.Instance.onForegroundColorChange.unsub(this.onForegroundChange);

        this._animation.deactivate();

        super.sleep();
    }

    private onForegroundChange = () => {
        // console.log('PreloaderIcon.onForegroundChange()');

        this._animation.setColor(ColorManager.Instance.foregroundColor);
    }
}