//Import for Webpack
import './CookieBanner.scss';

import Module from 'core/ts/system/Module';
import LinkHandler from "core/ts/utils/LinkHandler";
import CookieHandler from 'core/ts/utils/CookieHandler';

export default class CookieBanner extends Module {

    private _functionalCookieInput:HTMLInputElement = null;
    private _statisticalCookieInput:HTMLInputElement = null;

    /**
    * Template and modules will never be added or build before all nested modules are loaded
    * If you need something loaded before this and all childmodules gets build() and added to the dom. This is where you do it.
    * Call onComplete() parameter when you are done loading. (Dont forget this, also make sure to listen for fail and call onComplete).
    *
    * --This will only ever get called once.
    */
    // protected load( onComplete:Function ): void {
    //      //Call onComplete when loading is done
    //      onComplete();
    // }

    /**
    * Called when this and all nested modules are loaded.
    * Use this function to setup and manipulate the dom.
    *
    * --This will only ever get called once.
    * --Element is not yet added to the DOM
    */
    // protected build(): void {
    // }

    /**
    * Called right after the element is added to the DOM
    * Add eventlisteners and prepare/init the component
    */
    protected awake(): void {   

        if(CookieHandler.getCookie('cookie_accepted') === 'true') {
            this.handleAccepted();
            return;
        }
        
        document.body.prepend(this.getElement());

        this.qs('.button').addEventListener('click', this.onAccept);
        this._functionalCookieInput = this.qs('.checkbox-functional') as HTMLInputElement;
        this._statisticalCookieInput = this.qs('.checkbox-statistical') as HTMLInputElement;

        this._functionalCookieInput.checked = true;
        

        LinkHandler.Instance.addAllAtags(this.getElement());
    }

    private handleAccepted() {

        if(CookieHandler.getCookie('functional-cookie-accepted') === 'true') {
            this.addNecessaryCookieFunctionality();
        }
        if(CookieHandler.getCookie('statistical-cookie-accepted') === 'true') {
            this.addGoogleAnalytics();
        }

        this.getElement().parentNode.removeChild(this.getElement());
    }

    private addNecessaryCookieFunctionality() {
        // TODO, toggle functional/necessary cookies
    }

    private addGoogleAnalytics(){
        // Add google analytics code

        const GAId = "UA-179394555-1";

        const head = document.getElementsByTagName('head')[0];
        const scriptOne = document.createElement('script');
        scriptOne.async = true;
        scriptOne.src = "https://www.googletagmanager.com/gtag/js?id=" + GAId;

        const scriptTwo = document.createElement('script');
        const inlineScript = document.createTextNode('window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag(\'js\', new Date()); gtag(\'config\', \''+ GAId + "\');");
        scriptTwo.appendChild(inlineScript);

        head.prepend(scriptTwo);
        head.prepend(scriptOne);
    }

    private onAccept = (e:Event):void => {
        CookieHandler.setCookie('cookie_accepted', 'true');
        CookieHandler.setCookie('functional-cookie-accepted',  this._functionalCookieInput.checked.toString());
        CookieHandler.setCookie('statistical-cookie-accepted', this._statisticalCookieInput.checked.toString());

        this.handleAccepted();
    };

    /**
    * Called right before element gets removed from the dom
    * Remove listeners and kill stuff
    */
    protected sleep(): void {
        LinkHandler.Instance.removeAllAtags(this.getElement());
    }
}