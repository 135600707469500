import Component from "core/ts/system/Component";
import Breakpoints from "core/ts/utils/breakpoints/Breakpoints";
import ResizeManager from "core/ts/utils/ResizeManager";

export default class WindowInnerHeight extends Component {
    private _parsedInnerHeightOffset:Array<number> = null;
    private _parsedShouldResize:Array<boolean> = null;

    protected awake(): void {
        super.awake();

        ResizeManager.Instance.onResize.sub(this.onResize);
        Breakpoints.Instance.onNewBreakpoint.sub(this.onResize);

        let breakpointIndex:number = Breakpoints.Instance.getBreakPointIndex();
        let offset:number = this.getOffset(breakpointIndex);
        this.resize(offset);
    }

    protected sleep(): void {
        super.sleep();

        ResizeManager.Instance.onResize.unsub(this.onResize);
        Breakpoints.Instance.onNewBreakpoint.unsub(this.onResize);
    }

    protected onResize = () => {
        // console.log('WindowInnerHeight.onResize();');

        let breakpointIndex:number = Breakpoints.Instance.getBreakPointIndex();

        // console.log('breakpointIndex : ' + breakpointIndex);
        // console.log('this.shouldResize(breakpointIndex) : ' + this.shouldResize(breakpointIndex));

        if(!this.shouldResize(breakpointIndex)) {
            return;
        }

        let offset:number = this.getOffset(breakpointIndex);
        this.resize(offset);
    };

    private resize(offset:number):void {
        // console.log('offset : ' + offset);
        this.getElement().style.height = (window.innerHeight - offset) + 'px';
    }

    private shouldResize(index:number):boolean {
        if(this._parsedShouldResize === null) {
            this._parsedShouldResize = new Array<boolean>();

            let raw:string = this.params.plain.shouldresize;

            if(raw) {
                let rawArray:Array<string> = raw.split(',');
                const l = rawArray.length;
                for(let i = 0; i < l; i += 1) {
                    this._parsedShouldResize[i] = rawArray[i] === 'true';
                }
            }
        }

        // console.log('index : ' + index);

        const l2 = this._parsedShouldResize.length;
        if(l2 === 0) {
            return true;
        }

        if(l2 === 1) {
            return this._parsedShouldResize[0];
        }

        if(index < l2) {
            return this._parsedShouldResize[index];
        }

        return true;
    }

    private getOffset(index:number):number {
        if(this._parsedInnerHeightOffset === null) {
            this._parsedInnerHeightOffset = new Array<number>();

            let raw:string = this.params.plain.innerheightoffset;

            if(raw) {
                let rawArray:Array<string> = raw.split(',');
                const l = rawArray.length;
                for(let i = 0; i < l; i += 1) {
                    this._parsedInnerHeightOffset[i] = parseFloat(rawArray[i]);
                }
            }
        }

        // console.log('index : ' + index);

        const l2 = this._parsedInnerHeightOffset.length;
        if(l2 === 0) {
            return 0;
        }

        if(l2 === 1) {
            return this._parsedInnerHeightOffset[0];
        }

        if(index < l2) {
            return this._parsedInnerHeightOffset[index];
        }

        return 0;
    }
}