//Import for Webpack
import './RecognitionModule.scss';

import Module from 'core/ts/system/Module';
import { TweenLite, Sine } from 'gsap';

export default class RecognitionModule extends Module {
        /**
    * Template and modules will never be added or build before all nested modules are loaded
    * If you need something loaded before this and all childmodules gets build() and added to the dom. This is where you do it.
    * Call onComplete() parameter when you are done loading. (Dont forget this, also make sure to listen for fail and call onComplete).
    *
    * --This will only ever get called once.
    */
    // protected load( onComplete:Function ): void {
    //      //Call onComplete when loading is done
    //      onComplete();
    // }
    private _button:HTMLElement = null;
    private _items:NodeListOf<HTMLElement> = null;
    private _itemsContainer:HTMLElement = null;

    private _pages:number = 0;
    private _currPage:number = 0;
    private _itemsPerPage:number = 11; // TODO: has to be 11;
    private _numberOfItems:number = 6;

    /**
    * Called when this and all nested modules are loaded.
    * Use this function to setup and manipulate the dom.
    *
    * --This will only ever get called once.
    * --Element is not yet added to the DOM
    */
    protected build(): void {
        this._button = this.qs('.button-show-more');
        this._items = this.qsAll('.item');

        this._itemsContainer = this.qs('.items');
        this._numberOfItems = this._items.length;

        this._pages = Math.ceil(this._items.length / this._itemsPerPage);
    }

    /**
    * Called right after the element is added to the DOM
    * Add eventlisteners and prepare/init the component
    */
    protected awake(): void {
        this.removeAllFromParent();

        this._button.addEventListener('click', this.onButtonClick);

        this.updateHeight();
    }

    /**
    * Called when the component is about to leave the scene
    * Use this to stop any ongoing loadings.
    * So that new loading content doesnt get added during an animation out or other exit/leave/destroy operations are in progress.
    *
    * --This gets called before animating out of screen or destroy/kill commands
    * --Added new content (by async loading) while destroying could potentially create some errors.
    */
    // protected unload(): void {
    // }

    /**
    * Called right before element gets removed from the dom
    * Remove listeners and kill stuff
    */
    protected sleep(): void {
        this._button.removeEventListener('click', this.onButtonClick);
    }

    private onButtonClick = (e:Event):void => {
        this._currPage += 1;

        if(this._currPage >= this._pages - 1) {
            this._currPage = this._pages - 1;
        }

        this.updateHeight();
    }

    private removeAllFromParent():void {
        for (let i:number = 0; i < this._numberOfItems; i +=1) {
            let item:HTMLElement = this._items[i];
            if (item.parentNode !== null) {
                item.parentNode.removeChild(item);
            }
        }
    }

    private updateHeight(){
        this.removeAllFromParent();

        let numberOfItems:number = this._items.length;
        let itemIndex:number = Math.min(this._currPage * this._itemsPerPage + this._itemsPerPage, numberOfItems);

        if(itemIndex === numberOfItems) {
            this._button.style.visibility = 'hidden';
        } else {
            this._button.style.visibility = 'visible';
        }

        for(let i:number = 0; i < itemIndex; i += 1) {
            this._itemsContainer.appendChild(this._items[i]);
        }
    }
}