//Import for Webpack
import './RevealText.scss';

import Module from 'core/ts/system/Module';
import RenderLoop, {RenderItem} from "core/ts/utils/RenderLoop";
import {TweenLite, Expo, Ease, Quad, Sine, Quart} from 'gsap';

export default class RevealText extends Module {

    private _renderItem:RenderItem = null;
    private _wasInView:boolean = false;

    /**
    * Called right after the element is added to the DOM
    * Add eventlisteners and prepare/init the component
    */
    protected awake(): void {
        super.awake();

        this.useStaticBounds = true;

        TweenLite.set(this.getElement(), {opacity: .0});
        this._renderItem = RenderLoop.Instance.add(this.render, 6);
    }


    protected sleep(): void {
        super.sleep();

        if(this._renderItem !== null) {
            RenderLoop.Instance.remove(this._renderItem);
            this._renderItem = null;
        }
    }

    private render = (delta:number, total:number):void => {
        this.updateStaticClientRect();

        // console.log('this.viewInfo.isInView() : ' + this.viewInfo.isInView());
        // console.log('this.viewInfo.visualY : ' + this.viewInfo.visualY);

        if(this._wasInView === false && this.viewInfo.isAllInView()) {
            this._wasInView = true;

            TweenLite.to(this.getElement(), .5, {opacity: 1, ease: Sine.easeOut, delay: .1});

            RenderLoop.Instance.remove(this._renderItem);
            this._renderItem = null;
        }
    };
}