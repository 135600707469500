import { SignalDispatcher } from 'strongly-typed-events';

export default class ColorManager {
    private _backgroundColor: string = "#ffffff";
    private _foregroundColor: string = "#1E1E1E";

    private static _instance: ColorManager;
    public static get Instance(): ColorManager {
        if(this._instance == null) {
            this._instance = new ColorManager();
        }
        return this._instance;
    }

    private _backgroundColorChange = new SignalDispatcher();
    public get onBackgroundColorChange() {
        return this._backgroundColorChange.asEvent();
    }

    public get backgroundColor() {
        return this._backgroundColor;
    }

    public set backgroundColor(color: string) {
        if(color === this._backgroundColor) {
            return;
        }

        this._backgroundColor = color;
        this._backgroundColorChange.dispatch();
    }


    private _foregroundColorChange = new SignalDispatcher();
    public get onForegroundColorChange() {
        return this._foregroundColorChange.asEvent();
    }

    public get foregroundColor() {
        return this._foregroundColor;
    }

    public set foregroundColor(color: string) {
        if(color === this._foregroundColor) {
            return;
        }

        this._foregroundColor = color;
        this._foregroundColorChange.dispatch();
    }
}