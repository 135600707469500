//Import for Webpack
import './SimpleHero.scss';

import Module from 'core/ts/system/Module';
import MenuModule from "modules/MenuModule/MenuModule";

export default class SimpleHero extends Module {

    /**
    * Called right after the element is added to the DOM
    * Add eventlisteners and prepare/init the component
    */
    protected awake(): void {

        console.log('getLastUsedColor() : ' + MenuModule.getLastUsedColor());
        this.getElement().style.backgroundColor = MenuModule.getLastUsedColor();
    }
}