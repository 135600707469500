import Component from "core/ts/system/Component";
import ColorItem from "components/ColorItem";
import ColorUtils from "core/ts/utils/ColorUtils";

export default class ColorItemBorderTopToForeground extends ColorItem {
    protected foregroundChange(color: string): void {
        let style = window.getComputedStyle(this.getElement());
        // console.log('style.borderTop : ' + style.borderTop);

        this.getElement().style.borderTop = '1px solid ' + ColorUtils.hexToRGB(color, this.getOpacityFromBorderStyle(style.borderTop));
    }
}