import Component from "core/ts/system/Component";

/**
 * @ignore
 */
export enum Severity {
	trivial,
	severe
}

/**
 * @ignore
 */
export default class VarDumpWarning {
	constructor(
		private _message: string, 
		private _severity: Severity) 
		{}

		public getElement(): HTMLElement {
			const element = <p class="dump-warning">{this._message}</p>;
			element.classList.add(this._severity.toString());

			return element;
		}
}