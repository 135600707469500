import { SignalDispatcher } from 'strongly-typed-events';

export default class ResizeManager {

    private static _instance: ResizeManager;
    public static get Instance(): ResizeManager {
        return this._instance;
    }

    private _windowHeight:number = 0;
    public get windowHeight() {
        return this._windowHeight;
    }

    private _windowWidth:number = 0;
    public get windowWidth() {
        return this._windowWidth;
    }

    private _bodyHeight:number = 0;
    public get bodyHeight() {
        return this._bodyHeight;
    }

    private _bodyWidth:number = 0;
    public get bodyWidth() {
        return this._bodyWidth;
    }

    private _triggerResize = new SignalDispatcher();
    public get onResize() {
        return this._triggerResize.asEvent();
    }

    private _triggerBeforeResize = new SignalDispatcher();
    public get onBeforeResize() {
        return this._triggerBeforeResize.asEvent();
    }

    private _triggerAfterResize = new SignalDispatcher();
    public get onAfterResize() {
        return this._triggerAfterResize.asEvent();
    }

    private readonly _heightCalc:HTMLElement;

    constructor() {
        if (ResizeManager._instance) {
            console.log("TemplateManager is men't to be a singleton. Only one instance is allowed at a time.");
        }
        ResizeManager._instance = this;

        this._heightCalc = document.createElement( 'div' );
        this._heightCalc.style.position = 'absolute';
        this._heightCalc.style.height = 100 + 'vh';
        document.body.appendChild( this._heightCalc );
        this._windowHeight = this._heightCalc.offsetHeight;

        window.addEventListener( 'resize', this.internalResize );
        this.internalResize();
    }

    private internalResize = () => {
	    this._triggerBeforeResize.dispatch();

        this._windowWidth = window.innerWidth;
        this._windowHeight = window.innerHeight;
        this._windowHeight = this._heightCalc.offsetHeight;

        // console.log('this._windowWidth : ' + this._windowWidth);

        this._bodyWidth = document.body.offsetWidth;
        this._bodyHeight = document.body.offsetHeight;

        this._triggerResize.dispatch();
	    this._triggerAfterResize.dispatch();
    };

}