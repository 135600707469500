import VarDumpVariable from "./VarDumpVariable";
import { Severity } from "./VarDumpWarning";
/**
 * @ignore
 */
export default class VarDumpString extends VarDumpVariable {
	private checkIfHasTrailingWhitespace(value: string): boolean {
		return value.match(/\s+$/) !== null;
	}

	public createElement() : HTMLElement {
		if(this.checkIfHasTrailingWhitespace(this.value)) {
			this.addWarning("Trailing whitespace", Severity.severe);
		}
		
		return super.createElement();
	}
}