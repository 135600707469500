import Component from "core/ts/system/Component";
import {SimpleEventDispatcher} from "strongly-typed-events";

export default class FilterNode<T extends Component> {

    private _onAdded = new SimpleEventDispatcher<T>();
    public get onAdded() {
        return this._onAdded.asEvent();
    }

    private _onRemoved = new SimpleEventDispatcher<T>();
    public get onRemoved() {
        return this._onRemoved.asEvent();
    }

    private readonly _comp: Component;
    private readonly _filterClass: Function;

    public readonly children:T[] = [];

    constructor(comp: Component, filterClass: Function) {
        this._comp = comp;
        this._filterClass = filterClass;
        this._comp.getComponents( false, true).forEach(item => {
            this.onChildAdded(item);
        });
        this._comp.node.onAnyChildAdded.sub(this.onChildAdded);
        this._comp.node.onAnyChildRemoved.sub(this.onChildRemoved);
    }

    private onChildAdded = (comp: Component) => {
        //IS FILTER
        if (comp instanceof this._filterClass) {
            // IS THIS PARENT
            if (this.isParentThis(comp as T)) {
                // console.log('ON NEW ADDED' );
                this.children.push(comp as T);
                this._onAdded.dispatch( comp as T);
            }
        }
    };

    private onChildRemoved = (comp: Component) => {
        if (comp instanceof this._filterClass) {
            const index = this.children.indexOf(comp as T);
            if(index !== -1) {
                this.children.splice(index, 1);
	            this._onRemoved.dispatch( comp as T );
            }
        }
    };

    public kill() {
    	this._onRemoved.clear();
	    this._onAdded.clear();
        this._comp.node.onAnyChildAdded.unsub(this.onChildAdded);
        this._comp.node.onAnyChildRemoved.unsub(this.onChildRemoved);
    }

    public isParentThis(comp: Component): boolean {
	    if(comp.getParent() === null ){
	    	return false;
	    }

        const siblings = comp.getParent().getComponents();
        const l = siblings.length;

        //Search this as parent;
        for (let i = 0; i < l; i++) {
            if (siblings[i] === this._comp) {
                return true;
            }
        }

        //Search filter class as parent;
        for (let i = 0; i < l; i++) {
            if (siblings[i] instanceof this._filterClass) {
                return false;
            }
        }

        //Recursive backwards;
        for (let i = 0; i < l; i++) {
            if(this.isParentThis( siblings[i] ) ){
                return true;
            }
        }

        return false;
    }
}
