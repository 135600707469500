//Import for Webpack
import './NewsletterSignupModule.scss';

import Module from 'core/ts/system/Module';
import {TweenLite, Expo, Ease, Quad, Sine, Quart} from 'gsap';
import HTTPDataParams from "core/ts/utils/HTTP/DataTypes/HTTPDataParams";
import HTTPMethod from "core/ts/utils/HTTP/HTTPMethod";
import HTTPReply from "core/ts/utils/HTTP/HTTPReply";
import HTTP from "core/ts/utils/HTTP/HTTP";

export default class NewsletterSignupModule extends Module {

    private _button:HTMLElement = null;
    private _input:HTMLInputElement = null;
    private _checks:HTMLInputElement[] = Array<HTMLInputElement>();
    private _message:HTMLElement = null;

    private _email:string;
    private _isSending:boolean = false;


    protected build(): void {
        this._button = this.qs('.button');
        this._message = this.qs('.message');
        this._input = this.qs('.search') as HTMLInputElement;

        const checkboxes = this.qsAll('.checkbox');

        checkboxes.forEach(element => {
            const input = element.querySelector('input') as HTMLInputElement;
            if(input != null) {
                this._checks.push(input);
            }
        });
    }

    protected awake(): void {
        this._button.addEventListener('click', this.onButtonClick);
    }

    protected sleep(): void {
        this._button.removeEventListener('click', this.onButtonClick);
    }
    private onButtonClick = (e:Event):void => {
        let allChecked:boolean = true; 

        this._checks.forEach(element => {
            if(element.checked === false ) {
                allChecked = false;
            }
        });

        if(!allChecked) {
            this.showMessage('Please accept our terms', 2);
            return;
        }

        if(this._isSending === true) {
            return;
        }

        this._isSending = true;
        this._email = this._input.value;

        let data:HTTPDataParams = new HTTPDataParams();
        data.addParam('email', this._email);

        this.showMessage('Please wait...');

        HTTP.call('/php/Subscribe.php', HTTPMethod.POST, data, (reply:HTTPReply):void => {
            console.log('reply.raw : ' + reply.raw);

            let json:any = JSON.parse(reply.raw);

            this._isSending = false;

            let message:string = json.message;

            if(json.status === 'success' || json.message === 'already subscribed') {
                this._input.value = '';
            }

            this.showMessage(message && message.indexOf('400') !== - 1 ? 'Please try again' : 'Thank you', 3);
        });
    };

    private showMessage(message:string, hideAfter:number = - 1):void {
        this._message.innerText = message;
        this._message.style.display = 'block';
        TweenLite.set(this._message, {opacity: 1, overwrite: true});

        if(hideAfter !== - 1) {
            TweenLite.to(this._message, 1, {alpha: 0, ease: Sine.easeOut, delay: hideAfter, overwrite: true, onStart: this.messageHidden});
        }
    }

    private messageHidden = ():void => {
        this._message.style.pointerEvents = 'none';
    }
}