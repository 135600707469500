//Import for Webpack
import './PersonItem.scss';

import Module from 'core/ts/system/Module';
import LinkHandler from 'core/ts/utils/LinkHandler';
import LazyLoadImage from 'core/ts/utils/image/LazyLoadImage';
import App from 'ts/app';

const levenshtein = require('js-levenshtein');

export default class PersonItem extends Module {
	private _name: string;
	private _gender: string;
	private _positions: Array<string> = new Array<string>();
	private _phone: string;
	private _email: string;
	private _practices: Array<string> = null;

	private _useCount: number = 0;

	private _image: LazyLoadImage = null;

	protected build(): void {
		this._name = this.qs('.name').innerHTML.toLowerCase();

		let raw: Array<string> = this.qs('.positions')
			.textContent.toLowerCase()
			.trim()
			.split(',');

		// console.log('raw : ' + raw);

		raw.forEach((item: string): void => {
			if (item !== '' && item !== null && item !== undefined) {
				// console.log('item : ' + item);
				this._positions.push(item.trim());
			}
		});

		// console.log('this._positions : ' + this._positions);

		this._phone = this.qs('.phone').textContent.toLowerCase();
		this._email = this.qs('.email').textContent.toLowerCase();

		this._gender = this.qs('.gender').textContent.toLowerCase();

		let list: NodeListOf<HTMLElement> = this.getElement().querySelectorAll('.practice');
		if (list.length > 0) {
			this._practices = new Array<string>();
			list.forEach((element: HTMLElement): void => {
				this._practices.push(element.textContent.toLowerCase());
			});
		}

		this._image = this.getComponentByType<LazyLoadImage>(LazyLoadImage);
		this._image.setImageHeightToParent = this.params.plain.setparentheight === 'true' ? true : false;

		// console.log('PersonItem.build();');
		// console.log('this._name : ' + this._name);
		// console.log('this._positions : ' + this._positions);
		// console.log('this._phone : ' + this._phone);
		// console.log('this._email : ' + this._email);
		// console.log('this._practices : ' + this._practices);
	}

	protected awake(): void {
		App.RESIZE.onResize.sub(this.onResize);

		LinkHandler.Instance.addAllAtags(this.getElement());

		this.onResize();

		this._image.loadAppropriateImage();
	}

	private onResize = (): void => {
		this._image.getElement().style.height = this.getElement().offsetWidth * 1.235 + 'px';
	};

	protected sleep(): void {
		App.RESIZE.onResize.unsub(this.onResize);

		LinkHandler.Instance.removeAllAtags(this.getElement());
	}

	public getName(): string {
		return this._name;
	}

	public getGender(): string {
		return this._gender;
	}

	public hasPosition(positions: Array<string>): boolean {
		// console.log('positions : ' + positions);
		// console.log('this._title : ' + this._title);

		if (positions.length === 0) {
			return true;
		}

		const l: number = this._positions.length;
		for (let i: number = 0; i < l; i += 1) {
			if (positions.indexOf(this._positions[i]) !== -1) {
				return true;
			}
		}

		return false;
	}

	public hasPractices(practices: Array<string>): boolean {
		// console.log('practices : ' + practices);
		// console.log('this._practices : ' + this._practices);

		if (practices.length === 0 || practices === null) {
			return true;
		}

		if (this._practices === null) {
			return false;
		}

		let l: number = this._practices.length;

		for (let i: number = 0; i < l; i += 1) {
			if (practices.indexOf(this._practices[i]) !== -1) {
				return true;
			}
		}

		return false;
	}
	public getHitRatio(search: string): number {
		search = search.toLowerCase();

		let nameScore: number = 1 - (1 / this._name.length) * levenshtein(search, this._name);
		let titleScore: number = this.getBestScoreInArray(this._positions, search);
		let phoneScore: number = 1 - (1 / this._phone.length) * levenshtein(search, this._phone);
		let emailScore: number = 1 - (1 / this._email.length) * levenshtein(search, this._email);

		let tempNameArray = this._name.split(' ');

		tempNameArray.forEach(element => {
			if (element.startsWith(search)) {
				nameScore = 1;
			}
		});
		// Brug noget ala nedenstående, hvis de vil have det til at virke med mellemnavne og bredere søgninger
		// if (this._name.includes(search)) {
		// 	nameScore = 0.8;
		// }
		if (this._name.startsWith(search)) {
			nameScore = 1;
		}

		console.log('this._name : ' + this._name + ' search : ' + search + ' nameScore : ' + nameScore);

		if (this.doesAnyInArrayStartWith(this._positions, search)) {
			titleScore = 1;
		}

		if (this._phone.startsWith(search)) {
			phoneScore = 1;
		}

		if (this._email.startsWith(search)) {
			emailScore = 1;
		}

		// console.log('nameScore : ' + nameScore);

		let hitRatio: number = Math.max(nameScore, titleScore, phoneScore, emailScore);
		// console.log('hitRatio : ' + hitRatio);

		return hitRatio;
	}

	private doesAnyInArrayStartWith(array: Array<string>, search: string): boolean {
		const l: number = array.length;
		for (let i: number = 0; i < l; i += 1) {
			if (array[i].startsWith(search)) {
				return true;
			}
		}
		return false;
	}

	private getBestScoreInArray(array: Array<string>, search: string): number {
		const l: number = array.length;
		let bestScore: number = 0;
		for (let i: number = 0; i < l; i += 1) {
			let item: string = array[i];
			let score: number = 1 - (1 / item.length) * levenshtein(search, item);

			if (score > bestScore) {
				bestScore = score;
			}
		}
		return bestScore;
	}

	public getUseCount(): number {
		return this._useCount;
	}

	public used(): void {
		this._useCount += 1;
	}
}
