import Component from "core/ts/system/Component";
import Cursor from "core/ts/utils/cursor/Cursor";
import { TweenLite, Expo } from 'gsap';
import ColorManager from "ts/ColorManager";

export default class LinkCursor extends Cursor {
	public pt1:any = {x: 50, y: 50};
	public pt2:any = {x: 50, y: 50};

	private _element:HTMLElement = document.createElement('div');

	public getElement():HTMLElement {
		this._element.style.position = 'absolute';
		this._element.style.left = '-50px';
		this._element.style.top = '-50px';

		return this._element;
	}

	public activate(oldCursor:Cursor = null):void {
		// console.log('activate');

		TweenLite.killTweensOf([this.pt1, this.pt2]);

		this.pt1.x = 50;
		this.pt1.y = 50;

		this.pt2.x = 50;
		this.pt2.y = 50;

		const width:number = 32;
		const halfWidth:number = width / 2;

		TweenLite.to(this.pt1, .4, {x: 50 - halfWidth, ease: Expo.easeOut });
		TweenLite.to(this.pt2, .4, {x: 50 + halfWidth, ease: Expo.easeOut, onComplete: this.animationDone });

		this.startRender();
    }

	protected animationDone = () => {
		super.stopRender();
	};

	protected render(deltaTime:number, totalTime:number) {
		super.render(deltaTime, totalTime);

		let content:string = '<svg height="100" width="100">\' +\n' +
			'\t\t\t\'<line x1="' + this.pt1.x + '" y1="' + this.pt1.y + '" x2="' + this.pt2.x + '" y2="' + this.pt2.y + '" style="stroke:' + ColorManager.Instance.foregroundColor + ';stroke-width:2" />\' +\n' +
			'\t\t\'</svg>';

		this._element.innerHTML = content;
	}
}