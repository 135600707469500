import VarDumpItem from "./VarDumpItem";
import VarDumpVariable from "./VarDumpVariable";
import VarDumpString from "./VarDumpString";
import Component from "core/ts/system/Component";
import {SignalDispatcher} from "strongly-typed-events";
import VarDumpNull from "./VarDumpNull";
/**
 * @ignore
 */
export default class VarDumpArray extends VarDumpItem {

    private _onToggle = new SignalDispatcher();
    public get onToggle() {
        return this._onToggle.asEvent();
    }

	private _children: VarDumpItem[] = Array();
	private _indentationLevel: number = 0;
	private _length: number;
	private _open: boolean = true;
	private _numericArray: boolean = true;
	private _childContainer: HTMLElement;

	constructor(content: object, name: string, indentation: number = 0, path: string, parentArray?: VarDumpArray) {
		super(name, path, parentArray);
		this._length = this.findLength(content);

		this._indentationLevel = indentation;
		this._numericArray = VarDumpArray.checkIfNumericArray(content);

		this.loopThroughObject(content);
	}

	public get isNumericArray(): boolean {
		return this._numericArray;
	}

	private static checkIfNumericArray(item: object): boolean {
		return Array.isArray(item);
	}

	protected get length(): number { return this._length; }

	protected get type(): string {
		return this._numericArray ? 'array' : 'object';
	}

	private loopThroughObject(content: { [key: string]: any }) {
		for (let property in content) {
			if (content.hasOwnProperty(property)) {
				const key = property;
				const value: any = content[property];

				if(value == null) {
					this._children.push(new VarDumpNull(key, this.path, this));
				} else if (typeof value === 'object') {
					this._children.push(new VarDumpArray(value, key, this._indentationLevel + 1, this.path, this));
				} else {
					if(typeof(value) == 'string') {
						this._children.push(new VarDumpString(value, key, this.path, this));
					} else {
						this._children.push(new VarDumpVariable(value, key, this.path, this));
					}
				}
			}
		}
	}

	private toggleFold = () => {
		this._open = !this._open;
		this.copyToClipboard(this.path);

		if (!this._open) {
			this._childContainer.classList.add('closed');
		} else {
			this._childContainer.classList.remove('closed');
		}

        this._onToggle.dispatch();
	};

	public createElement(): HTMLElement {
		const container = this.getContainer();
		container.classList.add('dump-array-element');

		const arrayHeaderContainer = (
		<div class="dump-item dump-array">
			<div class="name">{this.name}</div>
			<div class="type">
				<p>{this.type}</p>
				<div class="length">{this.length.toString()}</div>
			</div>
		</div>);

		//TODO: REMOVE THIS EVENT LISTENER ON DESTROY, IM TIRED AND LAZY.
		arrayHeaderContainer.addEventListener('mouseup', this.toggleFold);
		container.appendChild(arrayHeaderContainer);
			
		this._childContainer = <div class="children-container" />;
		this._childContainer.style.marginLeft = this._indentationLevel + 1 * 10 + 'px';
		container.appendChild(this._childContainer);

		let line = <div class="array-indent-line" />;
		this._childContainer.appendChild(line);
		
		this._children.forEach(element => {
			this._childContainer.appendChild(element.createElement());
		});

		return container;
	}
}
