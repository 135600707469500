import Component from "core/ts/system/Component";
import ColorItem from "components/ColorItem";
import ColorUtils from "core/ts/utils/ColorUtils";

export default class ColorItemBackgroundToForeground extends ColorItem {
    protected foregroundChange(color: string): void {
        let style = window.getComputedStyle(this.getElement());

        this.getElement().style.backgroundColor = ColorUtils.hexToRGB(color, this.getOpacityFromBorderStyle(style.backgroundColor));
    }
}