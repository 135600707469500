//Import for Webpack
import './OurPeopleModule.scss';

import Module from 'core/ts/system/Module';
import App from "ts/app";
import TemplateManager from "core/ts/system/TemplateManager";
import LinkHandler from "core/ts/utils/LinkHandler";
import PersonItem from "modules/PersonItem/PersonItem";
import DefaultTemplate from "templates/DefaultTemplate";

import { TweenLite, Expo, Sine } from 'gsap';
import Timeout = NodeJS.Timeout;
import LazyLoadImage from "core/ts/utils/image/LazyLoadImage";
import ScrollControllerCustom from "core/ts/utils/scroll/ScrollControllerCustom";
import ScrollControllerNative from "core/ts/utils/scroll/ScrollControllerNative";
import Breakpoints from "core/ts/utils/breakpoints/Breakpoints";

export default class OurPeopleModule extends Module {
    private _container:HTMLElement = null;
    private _content:HTMLElement = null;
    private _mainContainer:HTMLElement = null;

    private _input:HTMLInputElement = null;
    private _allPeople:Array<PersonItem> = null;
    private _paginationList:Array<PersonItem> = null;
    private _peopleContainer:HTMLElement = null;

    private _template:DefaultTemplate = null;

    private _positionFilter:NodeListOf<HTMLInputElement> = null;
    private _practicesFilter:NodeListOf<HTMLInputElement> = null;

    private _isCompactMode:boolean = false;

    private _timeout:Timeout = null;
    private _searchTimeout:Timeout = null;
    private _totalPages:number = 0;
    private _currentPage:number = 0;

    private _originalSearchContainerParent:HTMLElement = null;

    private _lastSearch:string = null;

    protected awake(): void {
        super.awake();

        this._isCompactMode = this.getElement().classList.contains('compact');

        // console.log('this._isCompactMode : ' + this._isCompactMode);

        this._positionFilter = this.qs('.filter-positions').querySelector('.params').querySelectorAll('input');
        this._practicesFilter = this.qs('.filter-practices').querySelector('.params').querySelectorAll('input');


        this._peopleContainer = this.qs('.right-section');
        this._mainContainer = this.qs('.container');
        this._input = this.qs('.search') as HTMLInputElement;

        this._container = this.qs('.search-section-container');
        this._originalSearchContainerParent = this._container.parentElement;
        this._content = this.qs('.search-section');


        // this._renderItem = RenderLoop.Instance.add(this.render, 60);

        TemplateManager.Instance.onNewTemplate.sub(this.onNewTemplate);
        if(TemplateManager.Instance.getCurrentTemplate()) {
            this.onNewTemplate();
        }

        LinkHandler.Instance.addAllAtags(this.getElement());

        this._allPeople = this.getComponentsByType<PersonItem>(PersonItem, true, false);

        this.shuffleAll();

        // Search events
        this._input.addEventListener('change', this.onInputChange);
        this._input.addEventListener('keyup', this.onInputChange);

        this._positionFilter.forEach((element:HTMLInputElement):void => {
            element.addEventListener('change', this.onInputChange);
        });

        this._practicesFilter.forEach((element:HTMLInputElement):void => {
            element.addEventListener('change', this.onInputChange);
        });

        if(this._isCompactMode === false) {
            Breakpoints.Instance.onNewBreakpoint.sub(this.onNewBreakpoint);
            this.onNewBreakpoint();
        } else {
            if(this.getElement().classList.contains('compact') === false) {
                this.getElement().classList.add('compact');
            }
            this.qs('.link-container').style.display = 'none';

            this.autoPagination();
        }
    }

    private shuffleAll():void {
        let allPeopleCloned: Array<PersonItem> = new Array<PersonItem>();
        this._allPeople.forEach((item: PersonItem): void => {
            allPeopleCloned.push(item);
        });

        let shuffledList:Array<PersonItem> = new Array<PersonItem>();
        while (allPeopleCloned.length > 0) {
            let pick:number = Math.floor(Math.random() * allPeopleCloned.length);
            shuffledList.push(allPeopleCloned[pick]);
            allPeopleCloned.splice(pick, 1);
        }

        shuffledList.forEach((item:PersonItem):void => {
            this._peopleContainer.appendChild(item.getElement());
        });
    }


    private onNewBreakpoint = ():void => {
        App.SCROLL.onMove().unsub(this.onScroll);

        if(App.SCROLL instanceof ScrollControllerCustom) {
            if(Breakpoints.Instance.getBreakpointName() !== Breakpoints.Instance.mobile.getName()) {
                // document.getElementById('TemplateLayer').prepend(this._container);
                // App.SCROLL.onMove().sub(this.onScroll);

                // this.onScroll();
            } else {
                this._originalSearchContainerParent.prepend(this._container);
                TweenLite.set(this._container, {y: 0});
            }
        }
    };

    protected sleep(): void {
        super.sleep();

        if(this._timeout !== null) {
            clearInterval(this._timeout);
            this._timeout = null;
        }

        if(this._searchTimeout != null) {
            clearTimeout(this._searchTimeout);
            this._searchTimeout = null;
        }

        Breakpoints.Instance.onNewBreakpoint.unsub(this.onNewBreakpoint);

        TemplateManager.Instance.onNewTemplate.unsub(this.onNewTemplate);

        App.SCROLL.onMove().unsub(this.onScroll);

        this._template.onTransitionUpdate.unsub(this.onScroll);

        LinkHandler.Instance.removeAllAtags(this.getElement());

        this._input.removeEventListener('change', this.onInputChange);
        this._input.removeEventListener('keyup', this.onInputChange);

        this._positionFilter.forEach((element:HTMLInputElement):void => {
            // console.dir(element);
            element.removeEventListener('change', this.onInputChange);
        });

        this._practicesFilter.forEach((element:HTMLInputElement):void => {
            // console.dir(element);
            element.removeEventListener('change', this.onInputChange);
        });

        this._container.parentNode.removeChild(this._container);
    }


    /**
     * Auto pagination
     */
    private autoPagination():void {
        let itemsPerPage:number = 4;

        let allFemale:Array<PersonItem> = this.getPeopleByGender(this._allPeople, 'female');
        let allMen:Array<PersonItem> = this.getPeopleByGender(this._allPeople, 'male');

        // console.log('this._allPeople.length : ' + this._allPeople.length);
        // console.log('allMen.length : ' + allMen.length);
        // console.log('allWomen.length : ' + allFemale.length);


        this._totalPages = Math.ceil(allMen.length / (itemsPerPage - 1));

        // console.log('pages : ' + this._totalPages);

        this.shuffleArray(allFemale);
        this.shuffleArray(allMen);

        this._paginationList = new Array<PersonItem>();

        for(let page:number = 0; page < this._totalPages; page += 1) {
            let lessUsedMale:Array<PersonItem> = this.getLessUsedPersons(allMen);

            let pageItems:Array<PersonItem> = new Array<PersonItem>();


            lessUsedMale[0].used();
            lessUsedMale[1].used();
            lessUsedMale[2].used();

            pageItems.push(lessUsedMale[0]);
            pageItems.push(lessUsedMale[1]);
            pageItems.push(lessUsedMale[2]);

            let insertAt:number = Math.round((pageItems.length + 1) * Math.random());

            let lessUsedFemale:Array<PersonItem> = this.getLessUsedPersons(allFemale);
            lessUsedFemale[0].used();
            pageItems.splice(insertAt, 0, lessUsedFemale[0]);


            pageItems.forEach((item:PersonItem) => {
                this._paginationList.push(item);
            });
        }

        // console.log('this._shufflePeople.length : ' + this._shufflePeople.length);
        // console.log('');
        // console.log('Sort order');

        // this._shufflePeople.forEach((person:PersonItem):void => {
        //     console.log(person.getName() + ' - ' + person.getUseCount());
        // });

        // console.log('');
        // console.log('all people');
        // this._allPeople.forEach((person:PersonItem):void => {
        //     console.log(person.getName() + ' - ' + person.getUseCount());
        // });

        this.hideAllPeople();
        this.showPeopleAtPage(0);

        this._timeout = setInterval(this.nextPage, 7000);
    }

    private nextPage = ():void => {
        this._currentPage += 1;

        if(this._currentPage >= this._totalPages) {
            this._currentPage = 0;
        }

        this.showPeopleAtPage(this._currentPage);
    };

    private shuffleArray(array:Array<any>) {
        for (var i = array.length - 1; i > 0; i--) {
            var j = Math.floor(Math.random() * (i + 1));
            var temp = array[i];
            array[i] = array[j];
            array[j] = temp;
        }
    }

    private showPeopleAtPage(page:number):void {
        this.hideAllPeople();

        let itemsPerPage:number = 4;

        let index:number = page * itemsPerPage;
        let endIndex:number = index + itemsPerPage;

        let delay:number = 0;

        for(index; index < endIndex; index += 1) {
            let person:PersonItem = this._paginationList[index];

            TweenLite.set(person.getElement(), {opacity: 0});
            this._peopleContainer.appendChild(person.getElement());
            TweenLite.to(person.getElement(), .3, {opacity: 1, ease: Sine.easeOut, overwrite: true, delay: delay});

            delay += .1;
        }

        LazyLoadImage.forceUpdate();
    }

    private hideAllPeople():void {
        this._allPeople.forEach((person:PersonItem):void => {
            // console.log(person.getName() + ' - ' + person.getUseCount());

            if(person.getElement().parentNode !== null) {
                person.getElement().parentNode.removeChild(person.getElement());
            }
        });
    }

    private getLessUsedPersons(array:Array<PersonItem>):Array<PersonItem> {
        return array.sort((a, b) => (a.getUseCount() > b.getUseCount()) ? 1 : -1);
    }

    private getPeopleByGender(array:Array<PersonItem>, gender:string):Array<PersonItem> {
        let newArray:Array<PersonItem> = new Array<PersonItem>();

        array.forEach((person:PersonItem):void => {
            if(person.getGender() === gender) {
                newArray.push(person);
            }
        });

        return newArray;
    }



    /**
     * Search
     * @param e
     */
    private onInputChange = (e:Event):void => {
        // console.log('this._input.value : ' + this._input.value);

        if(this._timeout !== null) {
            clearInterval(this._timeout);
            this._timeout = null;
        }

        if(this._searchTimeout != null) {
            clearTimeout(this._searchTimeout);
            this._searchTimeout = null;
        }

        this._searchTimeout = setTimeout(this.delayedSearch, 500);
    };

    private delayedSearch = ():void => {
        let value:string = this._input.value;

        this._lastSearch = value;


        // hide all
        this._allPeople.forEach((person:PersonItem):void => {
            if(person.getElement().parentNode !== null) {
                person.getElement().parentNode.removeChild(person.getElement());
            }
        });

        // filter
        let filteredPeople:Array<PersonItem> = this.getPeopleInPositionsFilter(this._allPeople);
        filteredPeople = this.getPeopleIPracticesFilter(filteredPeople);


        // console.log('filteredPeople.length : ' + filteredPeople.length);

        // search
        let hits:Array<any> = new Array<any>();
        filteredPeople.forEach((person:PersonItem):void => {
            // console.log('person.getHitRatio(value) : ' + person.getHitRatio(value));

            let dist:number = person.getHitRatio(value);

            // console.log(person.getName() + ' - ' + dist);

            if(dist > .5) {
                hits.push({person: person, dist: dist});
            }
        });

        hits = hits.sort((a, b) => (a.dist > b.dist) ? -1 : 1);

        let maxHits:number = this._isCompactMode === true ? 4 : Number.MAX_VALUE;

        // console.log('maxHits : ' + maxHits);

        let index:number = 0;

        // show hits
        let delay:number = 0;

        hits.forEach((item:any):void => {
            // console.log('item.dist ' + item.dist);

            if(index >= maxHits) {
                return;
            }

            let person:PersonItem = item.person;

            TweenLite.set(person.getElement(), {opacity: 0});
            this._peopleContainer.appendChild(person.getElement());
            TweenLite.to(person.getElement(), .3, {opacity: 1, ease: Sine.easeOut, overwrite: true, delay: delay});

            index += 1;

            delay += .1;
        });

        LazyLoadImage.forceUpdate();

        App.SCROLL.scrollTo(this.getElement().offsetTop);
    };

    /**
     * Positions filter
     * @param list
     */
    private getPeopleInPositionsFilter(list:Array<PersonItem>):Array<PersonItem> {
        let checked:Array<string> = this.getCheckedPositions();

        let hits:Array<PersonItem> = new Array<PersonItem>();

        list.forEach((person:PersonItem):void => {
            if(person.hasPosition(checked) === true) {
                hits.push(person);
            }
        });

        return hits;
    }

    private getCheckedPositions():Array<string> {
        let list:Array<string> = new Array<string>();

        this._positionFilter.forEach((element:HTMLInputElement):void => {
            if(element.checked === true) {
                list.push(element.getAttribute('data-value').toLowerCase());
            }
        });

        return list;
    }


    /**
     * Practices filter
     * @param list
     */
    private getPeopleIPracticesFilter(list:Array<PersonItem>):Array<PersonItem> {
        let checked:Array<string> = this.getCheckedPractices();

        let hits:Array<PersonItem> = new Array<PersonItem>();

        list.forEach((person:PersonItem):void => {
            if(person.hasPractices(checked) === true) {
                hits.push(person);
            }
        });

        return hits;
    }


    private getCheckedPractices():Array<string> {
        let list:Array<string> = new Array<string>();

        this._practicesFilter.forEach((element:HTMLInputElement):void => {
            if(element.checked === true) {
                list.push(element.getAttribute('data-value').toLowerCase());
            }
        });

        return list;
    }



    private onNewTemplate = ():void => {
        this._template = TemplateManager.Instance.getCurrentTemplate() as DefaultTemplate;

        // let firstModule:Module = TemplateManager.Instance.getCurrentTemplate().getComponentByType<Module>(Module, false, false);
        // console.dir();

        if(this._isCompactMode === false) {
            this._template.onTransitionUpdate.sub(this.onScroll);
        }

        this.onScroll();
    };

    private onScroll = ():void => {
        if(this._isCompactMode === true) {
            return;
        }

        if(App.SCROLL instanceof ScrollControllerNative) {
            return;
        }

        if(Breakpoints.Instance.getBreakpointName() === Breakpoints.Instance.mobile.getName()) {
            return;
        }

        let yPos:number = this.getElement().getBoundingClientRect().y + parseFloat(window.getComputedStyle(this._mainContainer).paddingTop);
        const minY:number = 120;

        // for the sticky seach, (decided not to use for now)
        // if(yPos <= minY) {
        //     yPos = minY;
        // }


        // TweenLite.set(this._container, {y: yPos});
    };
}