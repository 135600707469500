//Import for Webpack
import './QuoteModule.scss';
import { TweenLite, Expo } from 'gsap';

import Module from 'core/ts/system/Module';
import CoreSlideshowModule from "core/modules/CoreSlideshowModule/CoreSlideshowModule";

export default class QuoteModule extends CoreSlideshowModule {

    protected onResize():void {
        super.onResize();


        let xPos:number = 0;

        this.slides.forEach((slide:HTMLElement) => {
            slide.style.position = 'absolute';

            TweenLite.set(slide, {x: xPos});

            xPos += slide.offsetWidth;

            // console.log('slide.offsetWidth : ' + slide.offsetWidth);

            (slide.parentNode as HTMLElement).style.height = slide.offsetHeight + 'px';
        });

    }
}