export default class Path {

	public static IGNORE_DOMAINS: string[] = [window.location.hostname];
	public static LOCAL_DOMAINS:string[] = [ 'snailtemplate:3000' ];

	public static getBaseUrl() {
		return document.location.protocol + '//' + window.location.host;
	}

	public static genFullUrl(url: string) {
		if (url.length === 0) {
			return window.location.href;
		} else if (url.substring(0, 1) === '/') {
			return this.getBaseUrl() + url;
		} else {
			return url;
		}
	}

	public static isSubdomain(url: string) {
		const l = Path.IGNORE_DOMAINS.length;
		for (let i = 0; i < l; i++) {
			//Unify hostlink, always check without www.
			const hostIndex = url.indexOf(Path.IGNORE_DOMAINS[i].replace('www.', ''));
			if (hostIndex !== -1) {
				if (hostIndex >= 1 && url.substr(hostIndex - 1, 1) === '.') {
					if (hostIndex < 4) {
						return true;
					} else if (hostIndex >= 4 && url.substr(hostIndex - 4, 4) !== 'www.') {
						return true;
					}
				}
			}
		}
		return false;
	}

	public static isLocal(url:string):boolean {
		const l = Path.LOCAL_DOMAINS.length;
		for( let i = 0; i < l; i++ ) {
			if(url.indexOf(  Path.LOCAL_DOMAINS[i] ) !== -1){
				return true;
			}
		}
		return false;
	}

	public static isInternal(url:string):boolean {
		const l: number = Path.IGNORE_DOMAINS.length;
		for (let i = 0; i < l; i++) {
			//Unify host link, always check without www.
			const hostIndex = url.indexOf(Path.IGNORE_DOMAINS[i].replace('www.', ''));
			if (hostIndex !== -1) {
				return true;
			}
		}
		return false;
	}

	public static isFile(url: string) {
		return (
			url
				.split('/')
				.pop()
				.split('.').length > 1
		);
	}

	public static isEmail(email: string): boolean {
		email = email.replace('mailto:', '');
		const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(email.toLowerCase());
	}

	public static getCurrentInternalPathFull():string {
		return this.getWellFormatedPath(window.location.pathname);
	}

	public static getWellFormatedPath(path:string) {
		// console.log('Path.getBaseUrl() : ' + Path.getBaseUrl());

		path = path.replace(Path.getBaseUrl(), '');

		let array = path.split('/');
		let l = array.length;
		for(let i = 0; i < l; i += 1) {
			if(array[0] === '') {
				array.splice(i, 1);
				l -= 1;
				i -= 1;
			}
		}

		return array.join('/');
	}

	public static getCurrentInternalPathAtLevel(index:number) {
		const fullPath:string = this.getCurrentInternalPathFull();

		// console.log('fullPath : ' + fullPath);

		const array = fullPath.split('/');
		if(index > array.length) {
			return null;
		}

		return array[index];
	}
}