import { SignalDispatcher } from 'strongly-typed-events';
import BrowserDetect from 'core/ts/utils/BrowserDetect';
import Template from 'core/ts/system/Template';
import {TweenLite, Expo, Ease} from 'gsap';

export default class ScrollController {
	protected triggerNewPageHeight: SignalDispatcher = new SignalDispatcher();
	protected triggerStart: SignalDispatcher = new SignalDispatcher();
	protected triggerMove: SignalDispatcher = new SignalDispatcher();
	protected triggerStop: SignalDispatcher = new SignalDispatcher();
	protected triggerEaseStop: SignalDispatcher = new SignalDispatcher();

	protected currentScroll = { x: 0, y: 0 };

	protected template: Template;

	private _scrollData:any = [];

	/**
	 * Events
	 */
	public onStart() {
		return this.triggerStart.asEvent();
	}

    public onNewPageHeight() {
        return this.triggerNewPageHeight.asEvent();
    }

	public onMove() {
		return this.triggerMove.asEvent();
	}

	public onStop() {
		return this.triggerStop.asEvent();
	}

	public onEaseStop() {
		return this.triggerEaseStop.asEvent();
	}

	constructor() {
		history.scrollRestoration = 'manual';
	}

	public getCurrentScroll() {
	    return this.currentScroll;
    }

	public scrollTo(yPos: number, speed: number = -1, ease: Ease = Expo.easeOut) {

	}

	public setTemplate(template: Template) {
		this.template = template;
	}

	public static isOnTouch(): boolean {
		return BrowserDetect.isMobile() || BrowserDetect.isTablet();
	}


	/**
	 * Scroll restoration
	 */
	protected saveScroll():void {
		if(!this.template) {
			return;
		}
		// console.log('this.template.path : ' + this.template.path + ' - ' + this.currentScroll.y);

		this._scrollData[this.template.path] = this.currentScroll.y;
	}

	public restoreScroll():void {
		// console.log('ScrollController.restoreScroll();');
		if(!this.template) {
			return;
		}

		// console.log('this.template.path : ' + this.template.path);

		let scrollY:number = this._scrollData[this.template.path];

		// console.log('scrollY : '+ scrollY);

		this.scrollTo(scrollY, 0);
	}
}
