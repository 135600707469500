import Component from 'core/ts/system/Component';
import App from 'ts/app';
import StaticTemplate from 'core/ts/system/static/StaticTemplate';

export class StaticWrapper {

	public cacheRoot:boolean = false;

	private readonly _comp:Component;
	private readonly _cachedRoot:StaticTemplate;

	public get template():StaticTemplate {
		if(this.cacheRoot) {
			return this._cachedRoot;
		}
		return this._comp.getRoot().getComponentByType<StaticTemplate>(StaticTemplate);
	}

	constructor( comp:Component, cacheRoot:boolean = true ) {
		this.cacheRoot = cacheRoot;
		this._comp = comp;
		this._cachedRoot = this._comp.getRoot().getComponentByType<StaticTemplate>(StaticTemplate);
	}

	/**
	 public queueRender( render:Function ) {
		if(this.template) {
			this.template.queueRender( render );
		} else {
			render();
		}
	}
	 **/

	/**
	 * If StaticTemplate is not found this will dispatch on resize instead
	 * Just like onPageRecalculate also dispatchs (recalculates) on resize;
	 */
	public get onPageRecalculate() {
		return this.template ? this.template.onPageRecalculate : App.RESIZE.onResize;
	}

	public requestRender( render:Function ) {
		if(this.template) {
			this.template.requestRender( render );
		} else {
			render();
		}
	}

	public calculatePageHeight() {
		if(this.template) {
			this.template.calculatePageHeight();
		}
	}

}