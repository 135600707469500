import Component from 'core/ts/system/Component';
import ITransition from 'core/ts/system/transition/ITransition';
import { Transition } from 'core/ts/system/transition/TransitionController';
import { TweenLite } from 'gsap';
import { SignalDispatcher } from 'strongly-typed-events';
import Timeout = NodeJS.Timeout;
import TemplateManager from "core/ts/system/TemplateManager";
import ScrollControllerNative from "core/ts/utils/scroll/ScrollControllerNative";
import ScrollController from "core/ts/utils/scroll/ScrollController";
import App from "ts/app";

export default class Template extends Component implements ITransition {
	private _onPageHeightChange = new SignalDispatcher();
	public get onPageHeightChange() {
		return this._onPageHeightChange.asEvent();
	}

	private _interval: Timeout;
	private _savedPageHeight = -1;

	protected scrollContent:HTMLElement;
	protected autoUpdatePageHeight:boolean = true;

	private _marginTop:number = 0;
	private _marginBottom:number = 0;


	public path:string = '';

	public __init() {
        super.__init();
        this.scrollContent = this.getElement();
    }

    protected __awake(): void {
	    super.__awake();
        this._savedPageHeight = this.getPageHeight();
        this.dispatchNewPageHeight();

        if(this.autoUpdatePageHeight) {
            this._interval = setInterval(() => {
                if(this._savedPageHeight !== this.getPageHeight()){
                    this.dispatchNewPageHeight();
                }
            }, 0.2 * 1000);
        }

	    this._marginBottom = Number.parseFloat(window.getComputedStyle(this.getElement()).marginBottom);
	    this._marginTop = Number.parseFloat(window.getComputedStyle(this.getElement()).marginTop);
	}

	protected __sleep(): void {
        super.__sleep();
		clearInterval(this._interval);
	}

	public transitionIn(controller: Transition): void {
		controller.done();

		if(TemplateManager.Instance.getUrlChangeMode() === 'replace' ) {
			App.SCROLL.restoreScroll();
		}
	}

	public transitionOut(controller: Transition): void {
		controller.done();
	}

	public startScrollAt() {
		return 0;
	}

	public scrollTo(yScroll: number) {
		TweenLite.set(this.scrollContent, {
			y: yScroll
		});
	}

	public getPageHeight() {
		// const menu = document.body.querySelector('.MenuModule') as HTMLElement;
		
		// console.log(Number.parseFloat(window.getComputedStyle(this.getElement()).marginTop));
		return this.getElement().offsetHeight + this._marginTop + this._marginBottom;//menu.offsetHeight;
	}

	protected dispatchNewPageHeight = () => {
		this._savedPageHeight = this.getPageHeight();
		this._onPageHeightChange.dispatch();
	};
}
