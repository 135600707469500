import html2canvas from 'html2canvas';
import Path from "core/ts/utils/Path";
import Module from "core/ts/system/Module";

export default class HTTPData {

    public getSerializedData():any {
        throw new Error('Function not implemented');
    }

    public getContentType():string {
        throw new Error('Function not implemented');
    }
}