//Import for Webpack
import './CollapseableChildrenBased.scss';

import Module from 'core/ts/system/Module';
import App from "ts/app";
import Breakpoints from 'core/ts/utils/breakpoints/Breakpoints';

export default class CollapseableChildrenBased extends Module {
    /**
     * Settings
     */
    private _numberOfChildrenWhenCollapsed:number = 5;
    private _readLessText:string = 'Read less';
    private _selector:string = '.item';
    private _selectorContainer:string = '.items';



    private _target:HTMLElement = null;
    private _currentHeight:number = 0;
    private _mask:HTMLElement = null;
    private _children:NodeListOf<HTMLElement> = null;
    private _collapseButton:HTMLElement = null;
    private _isCollapsed:boolean = true;
    private _readMoreText:string = null;


    protected build(): void {
        this._target = this.qs(this._selectorContainer);

        this._collapseButton = this.qs('.collapse-button');
        this._readMoreText = this._collapseButton.textContent;
    }

    protected awake(): void {

        App.RESIZE.onResize.sub(this.onResize);

        if(Breakpoints.Instance.getBreakpointName() === Breakpoints.Instance.mobile.getName()) {
            if(this.params.plain.mobilechildren) {
                this._numberOfChildrenWhenCollapsed = parseInt(this.params.plain.mobilechildren);
            }
        } else {
            if(this.params.plain.desktopchildren) {
                this._numberOfChildrenWhenCollapsed = parseInt(this.params.plain.desktopchildren);
            }
        }
        
        
        this._mask = document.createElement('div');
        this._target.parentNode.prepend(this._mask);
        this._mask.appendChild(this._target);

        this._mask.style.overflow = 'hidden';
        // this._mask.style.backgroundColor = 'yellow';

        this._children = this.qsAll(this._selector);

        this._collapseButton.addEventListener('click', this.onCollapse);

        this.onResize();
    }

    private onCollapse = (e:Event):void => {
        if(this._isCollapsed === false) {
            this._isCollapsed = true;
        } else {
            this._isCollapsed = false;
        }
        this.updateHeight();
    };

    private updateHeight():void {
        // console.log('this._isCollapsed  : ' + this._isCollapsed );

        if(this._isCollapsed === true) {
            this._mask.style.height = this.getCollapsedHeight() + 'px';
            this._collapseButton.innerText = this._readMoreText;
        } else {
            this._mask.style.height = this.getFullHeight() + 'px';
            this._collapseButton.innerText = this._readLessText;
        }
    }


    protected sleep(): void {
        this._collapseButton.removeEventListener('click', this.onCollapse);
        App.RESIZE.onResize.unsub(this.onResize);
    }


    private onResize = ():void => {
        this._currentHeight = this._target.offsetHeight;

        this._mask.style.width = this._target.style.width;
        this._mask.style.position = this._target.style.position;


        if(this._numberOfChildrenWhenCollapsed >= this._children.length) {
            this._collapseButton.style.display = 'none';
            this._isCollapsed = false;
        }

        this.updateHeight();

        // console.log('this._currentHeight : ' + this._currentHeight);
    };

    private getCollapsedHeight():number {
        let index:number = this._numberOfChildrenWhenCollapsed - 1;
        let max:number = this._children.length;
        if(index > max) {
            index = max;
        }

        let targetChild:HTMLElement = this._children[index];

        let style:CSSStyleDeclaration = window.getComputedStyle(this._target);
        console.log(targetChild);
        return targetChild.offsetTop  + targetChild.offsetHeight;
    }

    private getFullHeight():number {
        let numberOfChildren:number = this._children.length;
        if(this._children.length <= 0) {
            return 0;
        }
        let max:number = numberOfChildren - 1;
        let targetChild:HTMLElement = this._children[max];
        let style:CSSStyleDeclaration = window.getComputedStyle(this._target);
        return targetChild.offsetTop + targetChild.offsetHeight;
    }
}