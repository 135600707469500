export default class CookieHandler {
	public static setCookie(key:string, value:string, expiresInDays: number = 100) {
        let date = new Date();
		date.setTime(date.getTime() + (expiresInDays*24*60*60*1000));
        const expires = 'expires=' + date.toUTCString();
        document.cookie = key + '=' + value + ';' + expires;
    }
	
	public static getCookie(name:string) {
        const value = "; " + document.cookie;
        const parts = value.split("; " + name + "=");
        
        if (parts.length == 2) {
            return parts.pop().split(";").shift();
        } else {
            return null
        }
	}
	
	public static deleteCookie(name: string){
		const date = new Date();
	
		// Set it expire in -1 days
		date.setTime(date.getTime() + (-1 * 24 * 60 * 60 * 1000));
	
		// Set it
		document.cookie = name+"=; expires="+date.toUTCString()+";";
	}

	//#region cookie list functions
	public static AddToListCookie(name: string, value: string){
		const currentCookieValue = this.getCookie(name);
		// If the cookie does not exist
		if(currentCookieValue == null) {
			this.setCookie(name, value);
			return true;
		}
		// If the cookie value already exists on the cookie, return false.
		if(this.getSpecificItemFromListCookie(name, value)){
			return false;
		}
		const newCookieValue = currentCookieValue + "|" + value;
		this.setCookie(name, newCookieValue);
		return true;
	}

	public static removeFromListCookie(name: string, value: string){
		const currentCookieValue = this.getCookie(name);
		if(currentCookieValue === null){
			return;
		}
		const parts = currentCookieValue.split("|");

		parts.forEach(element => {
			if(element === value){
				parts.splice(parts.indexOf(element), 1);
			}
		});
		const newCookieValue = parts.join("|");
		this.setCookie(name, newCookieValue);

	}

	public static getSpecificItemFromListCookie(name: string, value: string){
		const currentCookieValue = this.getCookie(name);
		if(currentCookieValue == null) {
			return null;
		}
		const parts = currentCookieValue.split("|")

		return parts.includes(value);
	}
	//#endregion cookie list functions 
}