import html2canvas from 'html2canvas';
import Path from "core/ts/utils/Path";
import Module from "core/ts/system/Module";
import HTTPData from "core/ts/utils/HTTP/DataTypes/HTTPData";

export default class HTTPDataParams extends HTTPData{
    private _params:any = {};

    public addParam(key:string, value:string):void {
        this._params[key] = value;
    }

    public getSerializedData():any {
        let values:Array<string> = new Array<string>();

        for (let key in this._params) {
            values.push(key + '=' + this._params[key]);
        }
        return values.join('&');
    }

    public getContentType():string {
        return 'application/x-www-form-urlencoded; charset=UTF-8';
    }
}