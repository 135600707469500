import Component from "core/ts/system/Component";
import App from "ts/app";

export default class ScaleFontToParentWidth extends Component {
    protected awake(): void {
        super.awake();

        this.getElement().style.width = '100%';

        this.resizeText();

        App.RESIZE.onResize.sub(this.onResize);
    }

    protected sleep(): void {
        super.sleep();

        App.RESIZE.onResize.unsub(this.onResize);
    }

    private onResize = () => {
        this.resizeText();
    };

    private resizeText():void {
        this.getElement().style.fontSize = '0px';

        let size:number = 1;

        while(size < 500) {
            size += 1;
            this.getElement().style.fontSize = size + 'px';

            // console.log('this.getNumberOfLines(this.getElement()) : ' + this.getNumberOfLines(this.getElement()));

            if(this.getNumberOfLines(this.getElement()) > 1) {
                // to big - go one back
                size -= 1;

                this.getElement().style.fontSize = size + 'px';
                break;
            }
        }

        // console.log('size : ' + size);
    }

    private getNumberOfLines(target:HTMLElement):number {
        let divHeight = target.offsetHeight;
        // console.log('divHeight : ' + divHeight);
        // console.log('window.getComputedStyle(target).lineHeight : ' + window.getComputedStyle(target).lineHeight);
        let lineHeight = Number.parseFloat(window.getComputedStyle(target).lineHeight);
        // console.log('lineHeight : ' + lineHeight);
        let lines = Math.round(divHeight / lineHeight);

        // console.log('lines : ' + lines);
        return lines;
    }
}