//Import for Webpack
import './PublicationsModule.scss';

import Module from 'core/ts/system/Module';
import NewsPreview from "modules/NewsPreview/NewsPreview";
import LazyLoadImage from "core/ts/utils/image/LazyLoadImage";

export default class PublicationsModule extends Module {
    private _button:HTMLElement = null;
    private _input:HTMLInputElement = null;
    private _itemsContainer:HTMLElement = null;
    private _items:Array<NewsPreview> = null;
    private _activeItems:Array<NewsPreview> = null;

    private _pages:number = 0;
    private _currPage:number = 0;
    private _itemsPerPage:number = 6;
    private _numberOfItems:number = 6;


    protected build(): void {
        this._button = this.qs('.button');
        this._input = this.qs('input') as HTMLInputElement;
        this._items = this.getComponentsByType<NewsPreview>(NewsPreview, true, false);

        this._activeItems = this._items;

        this._itemsContainer = this.qs('.items');
        this._numberOfItems = this._items.length;

        console.log('this._items.length : ' + this._items.length);

        this._pages = Math.ceil(this._items.length / this._itemsPerPage );

        // console.log('this._pages : ' + this._pages);
    }

    protected awake(): void {
        this.removeAllFromParent();

        this._button.addEventListener('click', this.onButtonClick);
        this._input.addEventListener('change', this.onInputChange);
        this._input.addEventListener('keyup', this.onInputChange);

        this.updateHeight();
    }

    protected sleep(): void {
        this._button.removeEventListener('click', this.onButtonClick);
        this._input.addEventListener('change', this.onInputChange);
        this._input.addEventListener('keyup', this.onInputChange);
    }

    private onInputChange = (e:Event):void => {
        let value:string = this._input.value;

        if(value === null || value === undefined || value === '') {
            this._activeItems = this._items;
        } else {
            this._activeItems = this.getItemInSearch(value);
        }

        this._currPage = 0;
        this.updateHeight();

        // console.log('value : ' + value);
    };

    private getItemInSearch(search:string):Array<NewsPreview> {
        // console.log('PublicationsModule.getItemInSearch();');

        let hits:Array<NewsPreview> = new Array<NewsPreview>();

        this._items.forEach((item:NewsPreview):void => {
            if(item.matchesSearch(search) === true) {
                hits.push(item);
            }
        });

        return hits;
    }

    private onButtonClick = (e:Event):void => {
        this._currPage += 1;

        if(this._currPage >= this._pages - 1) {
            this._currPage = this._pages - 1;
        }

        this.updateHeight();
    };

    private removeAllFromParent():void {
        for(let i:number = 0; i < this._numberOfItems; i += 1) {
            let item:NewsPreview = this._items[i];
            if(item.getElement().parentNode !== null) {
                item.getElement().parentNode.removeChild(item.getElement());
            }
        }
    }

    private updateHeight():void {
        this.removeAllFromParent();

        let numberOfItems:number = this._activeItems.length;
        let itemIndex:number = Math.min(this._currPage * this._itemsPerPage + this._itemsPerPage, numberOfItems);

        if(itemIndex === numberOfItems) {
            this._button.style.visibility = 'hidden';
        } else {
            this._button.style.visibility = 'visible';
        }

        // console.log('this._currPage : ' + this._currPage);
        // console.log('itemIndex : ' + itemIndex);
        // console.log('numberOfItems : ' + numberOfItems);

        for(let i:number = 0; i < itemIndex; i += 1) {
            this._itemsContainer.appendChild(this._activeItems[i].getElement());
        }

        LazyLoadImage.forceUpdate();
    }

}