import { SignalDispatcher } from 'strongly-typed-events';
import ResizeManager from "core/ts/utils/ResizeManager";
import App from "ts/app";
import BreakpointItem from "core/ts/utils/breakpoints/BreakpointItem";

export default class Breakpoints {
    public mobile:BreakpointItem = new BreakpointItem('mobile', 0);
    public desktop:BreakpointItem = new BreakpointItem('desktop', 850);
    private _breakpoints:Array<BreakpointItem> = new Array<BreakpointItem>(this.mobile, this.desktop);

    private _currentBreakpointIndex:number = 0;
    private _currentBreakpointItem:BreakpointItem = null;

    private static _instance: Breakpoints;
    public static get Instance(): Breakpoints {
        if(this._instance == null) {
            this._instance = new Breakpoints();
        }

        return this._instance;
    }

    constructor() {
        // console.log('Breakpoints.constructor();');

        this._currentBreakpointIndex = this.getBreakPointIndexByWidth(App.RESIZE.windowWidth);
        this._currentBreakpointItem = this._breakpoints[this._currentBreakpointIndex];

        // console.log('Starting on breakpoint : ' + this._currentBreakpointIndex);

        ResizeManager.Instance.onResize.sub(this.onResize);
        this.onResize();
    }

    private _onNewBreakpoint = new SignalDispatcher();
    public get onNewBreakpoint() {
        return this._onNewBreakpoint.asEvent();
    }

    private onResize = () => {
        // console.log('Breakpoints.onResize();');

        let newBreakpointIndex = this.getBreakPointIndexByWidth(App.RESIZE.windowWidth);

        if(newBreakpointIndex !== this._currentBreakpointIndex) {
            // console.log('newBreakpointIndex : ' + newBreakpointIndex);

            this._currentBreakpointIndex = newBreakpointIndex;
            this._currentBreakpointItem = this._breakpoints[this._currentBreakpointIndex];

            this._onNewBreakpoint.dispatch();
        }
    };

    public getBreakPointIndexByWidth(width:number): number {
        // console.log('width : ' + width);

        if(width <= -1) {
            return 0;
        }

        const l = this._breakpoints.length;
        for (let i = 0; i < l; i++) {
            if (width < this._breakpoints[i].getWidth()) {
                return i - 1;
            }
        }
        return l - 1;
    }

    public getBreakPointIndex():number {
        return this._currentBreakpointIndex;
    }

    public getBreakpointName():string {
        return this._breakpoints[this.getBreakPointIndex()].getName();
    }
}