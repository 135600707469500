//Import for Webpack
import './VacantPositionsEmbedModule.scss';

import Module from 'core/ts/system/Module';

export default class VacantPositionsEmbedModule extends Module {
	protected build(): void {
		// Create the div element
		var divElement = document.createElement('div');
		divElement.id = 'hr-on';
		divElement.innerHTML = '<noscript>This page requires javascript</noscript>';

		// Create the second script element
		var script2 = document.createElement('script');
		script2.defer = true;
		script2.src = 'https://recruit.hr-on.com/frame-api/customers/moalemweitemeyeradvokatpartnerselskab-singlescript.js';
		script2.type = 'text/javascript';

		const container = document.createElement('div');

		container.appendChild(divElement);
		container.appendChild(script2);

		this.qs('.iframe-container').appendChild(container);
	}
}
