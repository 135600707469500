//Import for Webpack
import './CollapseableHeightBased.scss';

import Module from 'core/ts/system/Module';
import App from "ts/app";

export default class CollapseableHeightBased extends Module {
    /**
     * Settings
     */
    private _heightWhenCollapsd:number = 600;
    private _readLessText:string = 'Read less';
    private _selectorContainer:string = '.collapseable-container';
    // private _extraHeight:number = 0;



    private _target:HTMLElement = null;
    private _currentHeight:number = 0;
    private _mask:HTMLElement = null;
    private _collapseButton:HTMLElement = null;
    private _isCollapsed:boolean = true;
    private _readMoreText:string = null;


    protected build(): void {
        this._target = this.qs(this._selectorContainer);

        this._collapseButton = this.qs('.collapse-button');
        this._readMoreText = this._collapseButton.textContent;
    }

    protected awake(): void {

        App.RESIZE.onResize.sub(this.onResize);

        if(this.params.plain.sectionheight) {
            this._heightWhenCollapsd = parseInt(this.params.plain.sectionheight);
        }
        
        
        
        this._mask = document.createElement('div');
        this._target.parentNode.prepend(this._mask);
        this._mask.appendChild(this._target);

        this._mask.style.overflow = 'hidden';
        // this._mask.style.webkitMask = '-webkit-gradient(linear, left 70%, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)))'
        // this._mask.style.backgroundColor = 'yellow';

        this._collapseButton.addEventListener('click', this.onCollapse);

        this._collapseButton.style.paddingTop = '26px';

        this.onResize();
    }

    private onCollapse = (e:Event):void => {
        if(this._isCollapsed === false) {
            this._isCollapsed = true;
            this._mask.style.webkitMask = '-webkit-gradient(linear, left 70%, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)))'
        } else {
            this._isCollapsed = false;
            this._mask.style.webkitMask = 'none';
        }
        this.updateHeight();
    };

    private updateHeight():void {
        // console.log('this._isCollapsed  : ' + this._isCollapsed );

        if(this._isCollapsed === true) {
            this._mask.style.height = this.getCollapsedHeight() + 'px';
            this._collapseButton.innerText = this._readMoreText;
        } else {
            this._mask.style.height = this.getFullHeight() + 'px';
            this._collapseButton.innerText = this._readLessText;
        }
    }


    protected sleep(): void {
        this._collapseButton.removeEventListener('click', this.onCollapse);
        App.RESIZE.onResize.unsub(this.onResize);
    }


    private onResize = ():void => {
        this._currentHeight = this._target.offsetHeight;

        this._mask.style.width = this._target.style.width;
        this._mask.style.position = this._target.style.position;

        // console.log('this.getCollapsedHeight() : ' + this.getCollapsedHeight());
        // console.log('this.getFullHeight() : ' + this.getFullHeight());

        if(this.getCollapsedHeight() >= this.getFullHeight() - 200) {
            console.log("Collapsed height was tall");
            this._collapseButton.style.display = 'none';
            this._isCollapsed = false;
        } else {
            this._mask.style.webkitMask = '-webkit-gradient(linear, left 70%, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)))'
        }

        this.updateHeight();

        // console.log('this._currentHeight : ' + this._currentHeight);
    };

    private getCollapsedHeight():number {
        let height:number = this._heightWhenCollapsd;
        let fullHeight:number = this.getFullHeight();
        if(height >= fullHeight) {
            height = fullHeight;
        }

        return height;
    }

    private getFullHeight():number {
        let style:CSSStyleDeclaration = window.getComputedStyle(this._target);
        return this._target.offsetHeight;
    }
}