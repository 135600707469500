import App from 'ts/app';
import Component from 'core/ts/system/Component';
import BoundingBox from "core/ts/utils/BoundingBox";

/**
 * Contains information on Component view compared to the screen
 *
 * For performance reasons, the calculations are done based on its bound rect.
 * This rect is not updated automaticly, so before reading any values you should always call [[updateRect]]
 */
export default class ViewInfo {

	private _rect: BoundingBox;
	private _element:Component;

    /**
     * Every time [[updateRect]] gets call, this variable gets added to the box.
     *
     * So if you wanted all of you calculations to have a margin of 10px you could add that offset here.
     *
     * Offset x and y by -10px and adding 20px to the width and height
     * ```
     * viewInfo.offsetRect = new BoundingBox( -10, -10, 20, 20 );
     * ```
     *
     */
	public offsetRect:BoundingBox = new BoundingBox();

    /**
     * The current rect the the calculations are based upon. You can update this using [[updateRect]]
     */
	public get rect() {
		return this._rect;
	}

	constructor( element: Component = null ) {
		this._element = element;
		this.updateRect();
	}


    /**
     * Updates the rect wich all calculations are based on.
     * Always call this before reading any values.
     *
     * @param rect Set your own custom [[BoundingBox]]. If not set then the rect gets set from the getBoundingClientRect() function on [[Component]]. Notice that [[Module]] have a cached version of this function.
     */
	public updateRect( rect:BoundingBox = null ): void {
	    if(rect) {
            this._rect = rect;
        } else {
            this._rect = this._element.getBoundingClientRect();
        }
        this._rect.apply( this.offsetRect );
	}

	public get screenX() {
		return (1 / this._rect.width) * (this._rect.x * -1 + App.RESIZE.windowWidth * 0.5 + this._rect.width * 0.5) - 1;
	}

	public get screenY() {
		return (1 / this._rect.height) * (this._rect.y * -1 + App.RESIZE.windowHeight * 0.5 + this._rect.height * 0.5) - 1;
	}

	public get contentX(): number {
		const topRatio = (1 / this._rect.width) * this._rect.x * -1;
		const botRatio = (1 / this._rect.width) * (this._rect.x * -1 + App.RESIZE.windowWidth) - 1;

		if (botRatio < 0) {
			return botRatio < -1 ? -1 : botRatio;
		} else if (topRatio > 0) {
			return topRatio > 1 ? 1 : topRatio;
		} else {
			return 0;
		}
	}

	public get contentY(): number {
		const topRatio = (1 / this._rect.height) * this._rect.y * -1;
		const botRatio = (1 / this._rect.height) * (this._rect.y * -1 + App.RESIZE.windowHeight) - 1;

		if (botRatio < 0) {
			return botRatio < -1 ? -1 : botRatio;
		} else if (topRatio > 0) {
			return topRatio > 1 ? 1 : topRatio;
		} else {
			return 0;
		}
	}

    /**
     * Gives you the amount of the rect X that visible on the screen
     * @returns A number from 1-0 where 0 is completely outside of view and 1 is entirely inside.
     */
	public get visualX(): number {
		const topRatio = 1 - (1 / this._rect.width) * this._rect.x * -1;
		const botRatio = (1 / this._rect.width) * (this._rect.x * -1 + App.RESIZE.windowWidth);

		// console.log(this._rect.x);

		if (botRatio < 1) {
			return botRatio < 0 ? 0 : botRatio;
		} else if (topRatio < 1) {
			return topRatio < 0 ? 0 : topRatio;
		} else {
			return 1;
		}
	}

    /**
     * Gives you the amount of the rect Y that visible on the screen
     * @returns A number from 1-0 where 0 is completely outside of view and 1 is entirely inside.
     */
	public get visualY(): number {
		const topRatio = 1 - (1 / this._rect.height) * this._rect.y * -1;
		const botRatio = (1 / this._rect.height) * (this._rect.y * -1 + App.RESIZE.windowHeight);

		if (botRatio < 1) {
			return botRatio < 0 ? 0 : botRatio;
		} else if (topRatio < 1) {
			return topRatio < 0 ? 0 : topRatio;
		} else {
			return 1;
		}
	}


	public get scrollInsideY() {
        const amountInside = App.RESIZE.windowHeight - this._rect.height;
        const ratioInside = this._rect.y / amountInside;

        if(ratioInside > 1) {
            return 1;
        } else if(ratioInside < 0) {
            return 0;
        }

	    return ratioInside;
    }

    public get scrollOutsideY() {
        const amountOutside = App.RESIZE.windowHeight + this._rect.height;
        const ratioOutside = (this._rect.y + this._rect.height) / amountOutside;

        if(ratioOutside > 1) {
            return 1;
        } else if(ratioOutside < 0) {
            return 0;
        }

        return ratioOutside;
    }

    public get amountInside() {
		return App.RESIZE.windowHeight - this._rect.height;
    }

    public get scrollOffset() {
        const amountInside = App.RESIZE.windowHeight - this._rect.height;
        let offset = amountInside * this.scrollInsideY;
        return offset < 0 ? offset : 0;
    }


    /**
     *
     * ```
     *    ------------
     *    |          |
     *    |   000000 |
     *    |   000000 |
     *    |   000000 |
     *    |          |
     *    ------------
     * ```
     *
     * @returns Returns true if the entire rect is within the clients window
     */
    public isAllInView(): boolean {
		return this.visual >= 1;
	}

    /**
     * All thought the content is outside of the view, it's only on the x axis.
     * So this would still return true:
     * ```
     *    ------------
     *    |          |
     *    |   0000000|00
     *    |   0000000|00
     *    |   0000000|00
     *    |          |
     *    ------------
     * ```
     *
     * @returns Returns true if all the height of the rect is within the clients window height.
     */
    public isAllInViewY(): boolean {
        return this.visualY >= 1;
    }

    /**
     * All thought the  content is outside of the view, it's only on the y axis.
     * So this would still return true:
     * ```
     *        000000
     *    ------------
     *    |   000000 |
     *    |   000000 |
     *    |   000000 |
     *    |          |
     *    ------------
     * ```
     * @returns Returns true if all the width of the rect is within the clients window width
     */
    public isAllInViewX(): boolean {
        return this.visualX >= 1;
    }


    /**
     *
     * ```
     *             000000
     *    ------------000
     *    |        00|000
     *    |        00|000
     *    |          |
     *    |          |
     *    ------------
     * ```
     * @returns Returns true if any part is within view
     */
    public isInView(): boolean {
		return this.visual > 0;
	}

    /**
     * Gives you the amount of the rect that visible on the screen
     * @returns A number from 1-0 where 0 is completely outside of view and 1 is entirely inside.
     */
	public get visual(): number {
	    // console.log(this.visualX);
		return this.visualY * this.visualX;
	}
}
