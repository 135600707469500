import Template from 'core/ts/system/Template';
import ColorManager from 'ts/ColorManager';
import Component from 'core/ts/system/Component';
import Module from 'core/ts/system/Module';
import AllModules from 'modules/AllModules';
import App from 'ts/app';
import { Transition } from 'core/ts/system/transition/TransitionController';
import { TweenLite, Expo, Ease, Quad, Sine, Quart } from 'gsap';
import TemplateManager from 'core/ts/system/TemplateManager';
import { SignalDispatcher } from 'strongly-typed-events';
import PreloaderModule from 'modules/PreloaderModule/PreloaderModule';
import MainMenuButton from 'components/MainMenuButton';
import MenuModule from 'modules/MenuModule/MenuModule';
import ScrollControllerNative from 'core/ts/utils/scroll/ScrollControllerNative';
import ScrollControllerCustom from 'core/ts/utils/scroll/ScrollControllerCustom';

/**
 * @ignore
 *
 */
export default class DefaultTemplate extends Template {
	private _onTransitionUpdate = new SignalDispatcher();
	public get onTransitionUpdate() {
		return this._onTransitionUpdate.asEvent();
	}

	private _onTransitionInDone = new SignalDispatcher();
	public get onTransitionInDone() {
		return this._onTransitionInDone.asEvent();
	}

	private _isAnimatingOut: boolean = false;

	private _transitionCover: HTMLElement;

	private _transitionSpeed: number = 1;
	private _ease: Ease = Expo.easeInOut;

	private static _lastUsedColor: string = null;

	// protected awake(): void {
	// TweenMax.set(this.getElement(), {
	// 	x: App.RESIZE.windowWidth
	// });

	// window.scrollTo(0, 0);
	// }

	public transitionIn(controller: Transition): void {
		// console.log('DefaultTemplate.transitionIn();');

		let siteWrapper: HTMLElement = document.getElementById('SiteWrapper');

		TweenLite.set(this.getElement(), { y: -100 });

		TweenLite.to(this.getElement(), this._transitionSpeed / 2, {
			ease: Sine.easeOut,
			y: 0,
			// scale: .9,
			// opacity: .1,
			// transformOrigin: 'center top',
			onUpdate: () => {
				this._onTransitionUpdate.dispatch();
			}
		});

		let cover: HTMLElement = this.getTransitionCover();
		cover.style.height = window.innerHeight + 'px';
		// cover.style.backgroundColor = 'red';
		siteWrapper.appendChild(cover);

		TweenLite.to(cover, this._transitionSpeed / 2, {
			ease: Sine.easeOut,
			opacity: 0,
			onStart: () => {
				if (TemplateManager.Instance.getUrlChangeMode() === 'replace') {
					App.SCROLL.restoreScroll();
				} else {
					App.SCROLL.scrollTo(0, 0);
				}
			},
			onComplete: () => {
				cover.parentNode.removeChild(cover);
				controller.done();
				PreloaderModule.Instance.end();
				this._onTransitionInDone.dispatch();
			}
		});
	}

	public transitionOut(controller: Transition): void {
		// console.log('DefaultTemplate.transitionOut();');

		let siteWrapper: HTMLElement = document.getElementById('SiteWrapper');

		TweenLite.to(this.getElement(), this._transitionSpeed, {
			ease: this._ease,
			y: window.innerHeight * 0.8,
			// scale: .9,
			// opacity: .1,
			transformOrigin: 'center top',
			onComplete: () => {
				controller.done();
			},
			onUpdate: () => {
				this._onTransitionUpdate.dispatch();
			}
		});

		this._transitionCover = this.getTransitionCover();
		if (App.SCROLL instanceof ScrollControllerCustom) {
			siteWrapper.appendChild(this._transitionCover);
		} else {
			document.body.appendChild(this._transitionCover);
		}

		TweenLite.to(this._transitionCover, this._transitionSpeed, {
			ease: this._ease,
			height: window.innerHeight + 10,
			onComplete: () => {
				this._transitionCover.parentElement.removeChild(this._transitionCover);
			}
		});
	}

	private getTransitionCover(): HTMLElement {
		let firstModule: Module = TemplateManager.Instance.getCurrentTemplate().getComponentByType<Module>(Module, false, false);

		// console.log(firstModule.getElement().getAttribute('data-module'));

		let color: string = window.getComputedStyle(firstModule.getElement()).backgroundColor;

		if (!color) {
			color = DefaultTemplate._lastUsedColor;
		}

		let cover: HTMLElement = document.createElement('div');
		cover.style.position = 'fixed';
		cover.style.width = window.innerWidth + 'px';
		cover.style.height = 10 + 'px';
		// cover.style.backgroundColor = color;
		cover.style.backgroundColor = MenuModule.getLastUsedColor();
		// cover.style.backgroundColor = 'red';
		cover.style.top = '0px';
		cover.style.left = '0px';
		cover.style.zIndex = '90';

		DefaultTemplate._lastUsedColor = cover.style.backgroundColor;

		return cover;
	}

	public scrollTo(yScroll: number): void {
		//Ignore scroll when animating out;
		if (this._isAnimatingOut) {
			return;
		}

		super.scrollTo(yScroll);
	}
}
