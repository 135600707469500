import Component from "core/ts/system/Component";
import Cursor from "core/ts/utils/cursor/Cursor";
import { TweenLite, Expo } from 'gsap';
import DefaultCursor from "ts/cursors/DefaultCursor";
import Timeout = NodeJS.Timeout;
import ColorUtils from "core/ts/utils/ColorUtils";
import ColorManager from "ts/ColorManager";

export default class PreloaderCursor extends Cursor {
	public points:Array<any> = new Array<any>();

	private _numberOfLines:number = 12;
	private _halfSize:number = 16;

	private _timeout1:Timeout = null;
	private _timeout2:Timeout = null;

	private _step:string = null;

	private _element:HTMLElement = document.createElement('div');

	private _color:string = 'rgb(255,255,255)';

	public getElement():HTMLElement {
		this._element.style.position = 'absolute';
		this._element.style.left = '-50px';
		this._element.style.top = '-50px';
		this._element.classList.add('cursor');

		return this._element;
	}

	constructor(name:string) {
		super(name);

		for(let i:number = 0; i < this._numberOfLines; i += 1) {
			this.points.push({size: 0});
		}
	}

	public setColor(color:string):void {
		// console.log('color : ' + color);

		this._color = ColorUtils.hexToRGB(color, 1);
		this.render(0, 0);
	}


	public activate(oldCursor:Cursor = null):void {
		TweenLite.killTweensOf(this.points);

		if(this._timeout1 !== null) {
			clearTimeout(this._timeout1);
		}

		if(this._timeout2 !== null) {
			clearTimeout(this._timeout2);
		}

		for(let i:number = 0; i < this._numberOfLines; i += 1) {
			this.points[i].size = 0;
		}

		// if(oldCursor !== null) {
		// 	if(oldCursor instanceof DefaultCursor) {
		// 	}
		// }

		this.startRender();

		this.firstStep();
	}

	public deactivate(): void {
		super.stopRender();
		super.deactivate();
	}

	private firstStep = () => {
		this._step = 'firstStep';



		for(let i:number = 0; i < this._numberOfLines / 2; i += 1) {
			TweenLite.to(this.points[i], .0, {size: this._halfSize, ease: Expo.easeOut, delay: .05 * i});
			TweenLite.to(this.points[i + 6], .0, {size: this._halfSize, ease: Expo.easeOut, delay: .05 * i});
		}
		this._timeout1 = setTimeout(this.secondStep, 1000);
	};

	private secondStep = () => {
		this._step = 'secondStep';

		for(let i:number = 0; i < this._numberOfLines / 2; i += 1) {
			TweenLite.to(this.points[i], .0, {size: 0, ease: Expo.easeOut, delay: .05 * i});
			TweenLite.to(this.points[i + 6], .0, {size: 0, ease: Expo.easeOut, delay: .05 * i});
		}
		this._timeout2 = setTimeout(this.firstStep, 1000);
	};

	protected render(deltaTime:number, totalTime:number) {
		super.render(deltaTime, totalTime);

    	const startPosX:number = 50;
		const startPosY:number = 50;

		let content:string = '<svg height="100" width="100">';

		for(let i:number = 0; i < this._numberOfLines; i += 1) {
			let line:any = this.points[i];

			let rotation:number = 360 / this._numberOfLines * i;

			let endPtX:number = startPosX + Math.cos(rotation * (Math.PI / 180)) * line.size;
			let endPtY:number = startPosY + Math.sin(rotation * (Math.PI / 180)) * line.size;

			for(let i:number = 0; i < this._numberOfLines / 2; i += 1) {
				content += '<line x1="' + startPosX + '" y1="' + startPosY + '" x2="' + endPtX + '" y2="' + endPtY + '" style="stroke:' + ColorManager.Instance.foregroundColor + ';stroke-width:2" />';
			}
		}

		content += '</svg>';

		this._element.innerHTML = content;
	}
}