import {TweenLite, Expo, Ease} from 'gsap';
import ScrollController from "core/ts/utils/scroll/ScrollController";


export default class ScrollControllerNative extends ScrollController {
    constructor() {
        super();

        window.addEventListener('wheel', this.onScrollWheel);
    }

    private onScrollWheel = () => {
        TweenLite.killTweensOf(this.currentScroll);

        this.currentScroll.y = window.scrollY;

        this.saveScroll();
    };

    public scrollTo(pos:number, speed: number = -1, ease: Ease = Expo.easeOut):void {
        TweenLite.killTweensOf(this.currentScroll);
        this.currentScroll.x = window.scrollX;
        this.currentScroll.y = window.scrollY;

        // console.log('ScrollControllerNative.scrollTo();');
        // console.log('x : ' + x);
        // console.log('y : ' + y);

        if(speed <= 0) {
            this.currentScroll.y = pos;
            this.updateWindowScroll();
        } else {
            TweenLite.to(this.currentScroll, 1,
                {
                    y: pos,
                    roundProps: ['x', 'y'],
                    ease: ease,
                    onUpdate: this.updateWindowScroll
                }
            );
        }
    }

    private updateWindowScroll = () => {
        window.scrollTo(0, this.currentScroll.y);

        this.triggerMove.dispatch();

        this.saveScroll();
    };
}