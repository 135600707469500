//Import for Webpack
import './TextShuffler.scss';

import Module from 'core/ts/system/Module';
import { TweenLite, Expo } from 'gsap';
import ResizeManager from "core/ts/utils/ResizeManager";

export default class TextShuffler extends Module {
    private _activeLine:HTMLElement = null;
    private _oldLine:HTMLElement = null;

    private _currText:string;
    private _isAnimating:boolean = false;
    private _targetText:string;

    protected build(): void {
        super.build();


        this._activeLine = this.qs('.target');
        this._currText = this._activeLine.innerHTML;
    }

    protected awake(): void {
        super.awake();

        ResizeManager.Instance.onResize.sub(this.onResize);

        this.onResize();
    }

    protected sleep(): void {
        super.sleep();

        ResizeManager.Instance.onResize.unsub(this.onResize);
    }

    private onResize = ():void => {
        this.getElement().style.height = this._activeLine.offsetHeight + 'px';
    };

    public changeText = (text:string):void => {
        this._targetText = text;

        if(this._isAnimating) {
            return;
        }

        if(text === this._currText) {
            return;
        }

        this._isAnimating = true;
        this._oldLine = this._activeLine;
        this._currText = text;

        TweenLite.to(this._oldLine, 1, {y: - this._activeLine.offsetHeight, ease: Expo.easeInOut});

        this._activeLine = this._activeLine.cloneNode(true) as HTMLElement;
        this._activeLine.innerHTML = text;
        this.getElement().appendChild(this._activeLine);

        this.getElement().style.height = this._activeLine.offsetHeight + 'px';

        TweenLite.set(this._activeLine, {y: this._activeLine.offsetHeight});
        TweenLite.to(this._activeLine, 1, {y: 0, ease: Expo.easeInOut, onComplete: this.onAnimationComplete});

    };

    private onAnimationComplete = ():void => {
        this._oldLine.parentNode.removeChild(this._oldLine);
        this._oldLine = null;

        this._isAnimating = false;

        this.changeText(this._targetText);
    };

}