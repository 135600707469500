//Import for Webpack
import './MenuModule.scss';

import Module from "core/ts/system/Module";
import LinkHandler from "core/ts/utils/LinkHandler";
import ScrollControllerNative from "core/ts/utils/scroll/ScrollControllerNative";
import RenderLoop, {RenderItem} from "core/ts/utils/RenderLoop";
import {TweenLite, Expo, Ease, Linear, Quad} from 'gsap';
import Component from "core/ts/system/Component";
import TemplateManager from "core/ts/system/TemplateManager";
import App from "ts/app";
import ScrollControllerCustom from "core/ts/utils/scroll/ScrollControllerCustom";
import Breakpoints from "core/ts/utils/breakpoints/Breakpoints";

export default class MenuModule extends Module {
    private static _lastUsedColor:string = null;

    private _wrapper:HTMLElement = null;
    private _menuButton:HTMLElement = null;
    private _menuButtonOval:HTMLElement = null;
    private _backToTopButton:HTMLElement = null;
    private _topBar:HTMLElement = null;

    private _isOpen:boolean = false;

    private _logo:HTMLElement = null;
    private _topLogo:HTMLElement = null;

    private _originalParent:HTMLElement = null;


    private static darkGreen: string = "#283C37";
    private static plum: string = "#423C41";
    private static lightBlue: string = "#87A0A5";
    private static gold: string = "#B49646";
    private static _randomColors:Array<string> = new Array<string>(MenuModule.darkGreen, MenuModule.darkGreen, MenuModule.plum, MenuModule.lightBlue, MenuModule.gold);

    private _skipColor:boolean = true;

    public static getLastUsedColor():string {
        return MenuModule._lastUsedColor;
    }

    public static getContrastColor(color:string):string {
        let availableColors:Array<string> = new Array<string>();

        this._randomColors.forEach((color:string):void => {
            if(color !== MenuModule._lastUsedColor) {
                availableColors.push(color);
            }
        });

        let pick:number = Math.floor(availableColors.length * Math.random());
        return availableColors[pick];
    }

    protected build(): void {
        LinkHandler.Instance.addAllAtags(this.getElement());
        
        this._logo = this.qs('.logo');
        this._topLogo = this.getElement().parentElement.querySelector(".top-logo");
        // console.log(this._topLogo);

        this._wrapper = this.qs('.wrapper');
        this._menuButton = this.qs('.open-button');
        this._menuButtonOval = this.qs('.oval');
        this._backToTopButton = this.qs('.back-to-top-button');
        console.log(this.getElement().parentElement);
        this._topBar = this.getElement().parentElement.querySelector(".top-bar");
        // console.log(this._menuButtonOval);
        console.log(this.getElement().parentElement);
        

        this._menuButton.addEventListener('click', this.toggleMenu);
        this._backToTopButton.addEventListener('click', this.backToTop);

        this._originalParent = this.getElement().parentElement;

        Breakpoints.Instance.onNewBreakpoint.sub(this.onNewBreakpoint);
        LinkHandler.Instance.addAllAtags(this._topLogo);

        this.changeColor();
    }

    private backToTop = ():void => {
        App.SCROLL.scrollTo(0, 1);
    }

    private onNewBreakpoint = ():void => {
        console.log('Breakpoints.Instance.getBreakpointName() : ' + Breakpoints.Instance.getBreakpointName());

        if(Breakpoints.Instance.getBreakpointName() === Breakpoints.Instance.mobile.getName()) {
            document.body.insertBefore(this._topLogo, this.getElement().parentElement);
            document.body.insertBefore(this._topBar, this.getElement().parentElement);
            document.body.prepend(this.getElement());
            // console.log(this.getElement().parentElement);
            this._topLogo.style.display = 'none';
            
            if(this._isOpen === false) {
                this._wrapper.style.display = 'none';
                this._topLogo.style.display = 'block';
            }
        } else {
            this._topLogo.style.display = 'none';
            this._originalParent.prepend(this.getElement());
            // this._isOpen = true;
            this._wrapper.style.display = 'block';
            this._wrapper.style.pointerEvents = 'all';
        }

        App.SCROLL.onMove().unsub(this.onScroll);

        if(Breakpoints.Instance.getBreakpointName() !== Breakpoints.Instance.mobile.getName()) {
            if(App.SCROLL instanceof ScrollControllerCustom) {
                App.SCROLL.onMove().sub(this.onScroll);
            }
        } else {
            TweenLite.set(this._logo, {scale: 1, transformOrigin:"bottom left"});
            // this.onScroll();
        }

        console.log('this._wrapper.style.display : ' + this._wrapper.style.display);
    };

    private toggleMenu = ():void => {
        console.log('MenuModule.toggleMenu();');

        if(this._isOpen === true) {
            this._isOpen = false;
            this._topLogo.style.display = 'block';
            this._wrapper.style.display = 'none';
            this._wrapper.style.pointerEvents = 'none';
        } else {
            this._isOpen = true;
            this._topLogo.style.display = 'none';
            this._wrapper.style.display = 'block';
            this._wrapper.style.pointerEvents = 'all';
        }
    };

    protected awake(): void {
        super.awake();

        // let component:Component[] = this.getRoot();

        TemplateManager.Instance.onLoadingStart.sub(this.onLoadingStart);
        TemplateManager.Instance.onNewTemplate.sub(this.onNewTemplate);

        this.onNewBreakpoint();

    }

    private onLoadingStart = ():void => {
        if(Breakpoints.Instance.getBreakpointName() !== Breakpoints.Instance.mobile.getName()) {
            return;
        }

        this._wrapper.style.display = 'none';
        this._topLogo.style.display = 'block';
        this._isOpen = false;
    };

    private onNewTemplate = ():void => {
        console.log('MenuModule.onNewTemplate();');

        if(this._skipColor === true) {
            this._skipColor = false;
            return;
        }

        this.changeColor();
    };

    private changeColor():void {
        MenuModule._lastUsedColor = this.getColor();
        console.log('MenuModule._lastUsedColor : ' + MenuModule._lastUsedColor);

        this._wrapper.style.backgroundColor = MenuModule._lastUsedColor;
        // console.log(this._topLogo);
        if(this._topLogo !== null) {
            // console.log(this._topLogo);
            // this._topLogo.style.backgroundColor = MenuModule._lastUsedColor;
            this._topBar.style.backgroundColor = MenuModule._lastUsedColor;
        }
        
        // this.getElement().parentElement.querySelector('#TemplateLayer').querySelector('.template').prepend(this._topBar);

        /*     Changing color of oval in menubutton -> Needs to darken the color a bit
        if(this._menuButtonOval !== null) {
            const g:SVGAElement = this._menuButtonOval.querySelector("g").querySelector("g") as SVGAElement;
            console.log(g);
            g.setAttribute("fill", MenuModule._lastUsedColor);
            //  = MenuModule._lastUsedColor;
        }
        */
    }



    private getColor():string {
        let availableColors:Array<string> = new Array<string>();

        MenuModule._randomColors.forEach((color:string):void => {
           if(color !== MenuModule._lastUsedColor) {
               availableColors.push(color);
           }
        });

        let pick:number = Math.floor(availableColors.length * Math.random());
        return availableColors[pick];
    }

    private onScroll = ():void => {
        // console.log('App.SCROLL.getCurrentScroll().y : ' + App.SCROLL.getCurrentScroll().y);

        let scrollRatio:number = 1 / 160 * App.SCROLL.getCurrentScroll().y;

        if(scrollRatio > 1) {
            scrollRatio = 1;
        }

        // console.log('scrollRatio : ' + scrollRatio);
        // console.log('heightRatio : ' + heightRatio);

        TweenLite.set(this._wrapper, {top: -55 * scrollRatio});
        TweenLite.set(this.getElement(), {height: 160 - 10 * scrollRatio});
        TweenLite.set(this._logo, {scale: 1 - (scrollRatio * .2), transformOrigin:"bottom left"});
    };

    protected sleep(): void {
        LinkHandler.Instance.removeAllAtags(this.getElement());
        LinkHandler.Instance.removeAllAtags(this._topLogo);
    }
}