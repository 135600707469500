//Import for Webpack
import Module from 'core/ts/system/Module';
import RenderLoop, {RenderItem} from "core/ts/utils/RenderLoop";
import {TweenLite, Expo, Sine, Linear, Ease} from 'gsap';

export default class CoreVideoModule extends Module {
    private _playButton: HTMLElement;

    private _playIcon: HTMLElement;
    private _pauseIcon: HTMLElement;

    private _playbackTime: HTMLElement;
    private _placeholder: HTMLElement;
    private _video:HTMLVideoElement;

    private _isPlaying: boolean = false;
    private _wasInteractedWith:boolean = false;

    private _renderItem:RenderItem = null;
    private _videoReadyForPlay:boolean = false;

    private _autoPlay:boolean = false;
    private _videoUI:string;

    private _videoContainer:HTMLElement;

    private _isVideoLoaded:boolean = false;
    private _restoreTime:number = 0;


    protected build(): void {
        this._playButton = this.qs('.play-button');
        this._playIcon = this.qs('.play-icon');
        this._pauseIcon = this.qs('.pause-icon');
        this._placeholder = this.qs('.placeholder');
        this._playbackTime = this.qs('.playback-time');
        this._video = this.qs('video') as HTMLVideoElement;

        this._videoUI = this.params.plain.videoui;
        if(!this._videoUI) {
            this._videoUI = 'default';
        }
    }

    protected awake(): void {
        if(this._video.autoplay) {
            this._autoPlay = true;
            this._video.autoplay = false;
        }

        this._videoReadyForPlay = false;

        // this._video.pause();
        this._videoContainer = this._video.parentElement;
        this._videoContainer.removeChild(this._video);

        this.hideAllUI();

        this._renderItem = RenderLoop.Instance.add(this.render, 5);
    }



    private render = (deltaTime:number, totalTime:number):void => {
        this.viewInfo.updateRect();

        if (this._video != null) {
            if(this.viewInfo.isInView() === true) {
                this.loadVideo();

                if(!this._videoContainer.contains(this._video)) {
                    this._videoContainer.appendChild(this._video);
                }

                // console.log('state : ' + this._video.src + " - " + this._video.readyState);

                if(this._videoReadyForPlay === false && this._video.readyState === 4) {
                    if(this.isReadyForPlay()) {
                        this.videoReadyForPlay();
                    }
                }

                if(this._videoReadyForPlay === true) {
                    if(this._wasInteractedWith === false && this._autoPlay === true) {
                        this.Play();
                    }
                }
            } else {
                this.unloadVideo();
            }

            let timeLeft = this._video.duration - this._video.currentTime;

            if(isNaN(timeLeft)) {
                timeLeft = 0;
            }

            let date = new Date(null);
            date.setSeconds(timeLeft);

            this._playbackTime.innerText = date.toISOString().substr(14, 5);
        }
    };


    private videoReadyForPlay():void {
        this._videoReadyForPlay = true;

        if(this._videoUI !== 'none') {
            this._playButton.addEventListener('click', this.onPlay);
        }

        this.updateUIToState();
    }

    protected sleep(): void {
        if(this._renderItem !== null) {
            RenderLoop.Instance.remove(this._renderItem);
            this._renderItem = null;
        }

        if(this._video !== null) {
            this.unloadVideo(true);
            this._video = null;
        }
    }

    protected isReadyForPlay():boolean {
        return true;
    }

    private onPlay = () => {
        // console.log('VideoModule.onPlay();');

        this._wasInteractedWith = true;

        if (!this._isPlaying) {
            this.Play();
        } else {
            this.Pause();
        }
    };

    private Play():void {
        if(this._video.readyState !== 4) {
            return;
        }

        if(this._isPlaying === true) {
            return;
        }
        // console.log('VideoModule.Play(); ' + this._video.src);

        this._isPlaying = true;

        let playPromise = this._video.play();

        if(this._restoreTime !== 0) {
            this._video.currentTime = this._restoreTime;
            this._restoreTime = 0;
        }

        if (playPromise !== undefined) {
            playPromise.then(_ => {
                // console.log(this._video.src + ' playing');

                TweenLite.to(this._video, 0, {opacity: 1, ease: Linear.easeOut});
            }).catch(error => {
                // console.log(this._video.src + ' ' + error);
            });
        } else {
            // console.warn('playPromise was undefined');
        }

        this.updateUIToState();
    }

    private Pause():void {
        if(this._isPlaying === false) {
            return;
        }

        // console.log('VideoModule.Pause(); ' + this._video.src);

        this._isPlaying = false;
        this._video.pause();

        this.updateUIToState();
    }

    private loadVideo():void {
        if(this._isVideoLoaded === true) {
            return;
        }

        this._isVideoLoaded = true;
        this._video.setAttribute('src', this._video.getAttribute('data-src'));
        // console.log('VideoModule.loadVideo(); ' + this._video.src);
        this._video.load();
    }

    private unloadVideo(force:boolean = false):void {
        if (force !== true) {
            if(this._isVideoLoaded === false) {
                return;
            }
        }

        if(this._isPlaying === true) {
            this._restoreTime = this._video.currentTime;
        }

        this._isVideoLoaded = false;
        this._isPlaying = false;

        // console.log('VideoModule.unloadVideo(); ' + this._video.src);

        this._video.pause();
        this._video.removeAttribute('src');
        this._video.load();

        if(this._videoContainer.contains(this._video)) {
            this._videoContainer.removeChild(this._video);
        }

        if(this._videoUI !== 'none') {
            this._playButton.removeEventListener('click', this.onPlay);
        }

        this.updateUIToState();
    }

    private updateUIToState():void {
        if(this._isPlaying === true) {
            // if(this._placeholder !== null) {
            //     this._placeholder.style.visibility = 'hidden';
            // }

            // TweenLite.set(this._video, {opacity: 1});
            // this._video.style.visibility = 'visible';

            if(this._videoUI === 'default') {
                this._pauseIcon.style.display = 'block';
                this._playIcon.style.display = 'none';
                this._playbackTime.style.display = 'block';
            } else if(this._videoUI === 'default') {
                this.hideAllUI();
            }
        } else {
            // if(this._placeholder !== null) {
            //     this._placeholder.style.visibility = 'visible';
            // }

            // TweenLite.set(this._video, {opacity: 0});
            // this._video.style.visibility = 'hidden';
            TweenLite.killTweensOf(this._video);
            TweenLite.set(this._video, {opacity: 0});

            if(this._videoUI === 'default') {
                this._pauseIcon.style.display = 'none';
                this._playIcon.style.display = 'block';
                this._playbackTime.style.display = 'block';
            } else if(this._videoUI === 'default') {
                this.hideAllUI();
            }
        }
    }

    private hideAllUI():void {
        this._pauseIcon.style.display = 'none';
        this._playIcon.style.display = 'none';
        this._playbackTime.style.display = 'none';

        TweenLite.killTweensOf(this._video);
        TweenLite.set(this._video, {opacity: 0});
    }
}