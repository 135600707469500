import IAnimate from 'core/ts/system/transition/ITransition';
import { SimpleEventDispatcher } from 'strongly-typed-events';
import Template from 'core/ts/system/Template';
import Component from 'core/ts/system/Component';
import ITransition from 'core/ts/system/transition/ITransition';

/**
 * @ignore
 */
export enum MODE {
	IN,
	OUT
}

export default class TransitionController {
	private _onAllComplete = new SimpleEventDispatcher<TransitionController>();
	public get onAllComplete() {
		return this._onAllComplete.asEvent();
	}

	private readonly _rootModule: IAnimate;
	public get rootModule(): IAnimate {
		return this._rootModule;
	}

	public nextTemplate: Template;
	public preTemplate: Template;

	public waitAtLeast: number = 0;

	public queuedAnimations: number = 0;

    private _nextDepth: number = 0;
	private _queuedAnimations: number = 1;
    private _animationsInProgress: number = 0;
	private _isAnimating: boolean = false;
	private _isDone: boolean = false;
	private _mode: MODE = MODE.IN;

	private _useTree: boolean = true;

	private _startedAt: number;

	private _aniTree: RecursiveAnimations;
	private _reverseAnimation: boolean;
	private readonly _recursiveInInstant: boolean;

	constructor(rootModule: IAnimate, recursiveInInstant: boolean = false) {
		this._queuedAnimations = 1;
		this._rootModule = rootModule;
		this._recursiveInInstant = recursiveInInstant;

		this._aniTree = new RecursiveAnimations(rootModule);
	}

	public get mode() {
		return this._mode;
	}

	public start(mode: MODE, reverseAnimation: boolean = mode === MODE.OUT): void {
		this._startedAt = Date.now();
		this._isAnimating = true;
		this._mode = mode;
		this._reverseAnimation = reverseAnimation;

		this._useTree = true;
		if (!this._reverseAnimation && this._recursiveInInstant && this._mode === MODE.IN) {
			this._useTree = false;
		}

		if (this._useTree && this._aniTree.deepestDepth() > 0) {
		    if(this._reverseAnimation) {
			    this._nextDepth = this._aniTree.deepestDepth() - 1;
            } else {
                this._nextDepth = -1;
            }
			this._queuedAnimations = 0;
            this._animationsInProgress = 0;
			this.animateNewTree();
		} else {
			this.animateElement(this.rootModule);
		}
	}

	private done = (module: IAnimate) => {
        this._animationsInProgress--;

        if (this._animationsInProgress === 0) {
            this._isAnimating = false;

            this.dispatchIfDone();
        }
	};

    private continue = (module: IAnimate) => {
        if (this._useTree) {
            this._queuedAnimations--;
            if (this._queuedAnimations === 0) {
                this.animateNewTree();
            }
        } else {
            this.animateRecursive(module);
            this._queuedAnimations--;
        }

    };

	public get numOfAnimationLevels() {
		return this._aniTree.deepestDepth();
	}

	public isDone(): boolean {
		return this._isDone;
	}

	public isAnimating(): boolean {
		return this._animationsInProgress > 0;
	}

	public get timeElapsed() {
		return Date.now() - this._startedAt;
	}

	private dispatchIfDone() {
		if (!this.isAnimating()) {
			let timeElapsed: number = this.timeElapsed;

			if (this.waitAtLeast - timeElapsed < 0) {
				this._isDone = true;
				this._onAllComplete.dispatch(this);
			} else {
				setTimeout(() => {
					this._isDone = true;
					this._onAllComplete.dispatch(this);
				}, this.waitAtLeast - timeElapsed);
			}
		}
	}


	private animateNewTree() {
		let modules: RecursiveAnimations[] = this._aniTree.getAtDepth(this._nextDepth);
		if (this._reverseAnimation) {
			this._nextDepth--;
		} else {
			this._nextDepth++;
		}

		const l: number = modules.length;

		for (let i = 0; i < l; i++) {
			this._queuedAnimations++;
			this.animateElement(modules[i].module);
		}
	}

	private animateRecursive(module: Component) {
        let modules: Component[] = module.getComponents(false, false);
		// let modules: Component[] = module.getAllChildTypes('', false);
		const l = modules.length;

		console.log(modules.length);

		for (let i = 0; i < l; i++) {
			if ('transitionIn' in modules[i] && 'transitionOut' in modules[i]) {
				this._queuedAnimations++;
				this.animateElement(modules[i] as IAnimate);
			} else {
				this.animateRecursive(modules[i]);
			}
		}
	}

	private animateElement(module: IAnimate) {
		let event = new Transition(module, this, this._nextDepth);
		event.onComplete.one(this.done);
        event.onContinue.one(this.continue);

        this._animationsInProgress++;
		if (this._mode === MODE.IN) {
			module.transitionIn(event);
		} else {
			module.transitionOut(event);
		}
	}
}

export class Transition {
	private _onComplete = new SimpleEventDispatcher<IAnimate>();
	public get onComplete() {
		return this._onComplete.asEvent();
	}

	private _onContinue = new SimpleEventDispatcher<IAnimate>();
	public get onContinue() {
		return this._onContinue.asEvent();
	}

	private readonly _module: IAnimate;
	private readonly _controller: TransitionController;
	private readonly _animationLevel: number;

	private _isContinued:boolean = false;

	constructor(module: IAnimate, controller: TransitionController, animationLevel: number) {
		this._animationLevel = animationLevel;
		this._module = module;
		this._controller = controller;
	}

	public get numOfAnimationLevels() {
		return this._controller.numOfAnimationLevels;
	}

	public get animationLevel() {
		return this._animationLevel;
	}

	public get totalTimeElapsed() {
		return this._controller.timeElapsed;
	}

	public continue(): void {
        this._isContinued = true;
		this._onContinue.dispatch(this._module);
	}

	public done(): void {
	    if(!this._isContinued ) {
	        this.continue();
        }
		this._onComplete.dispatch(this._module);
	}
}

export class RecursiveAnimations {
	private _tree: RecursiveAnimations[] = [];
	private readonly _depth: number = 0;

	public readonly module: ITransition;

	constructor(module: ITransition, depth: number = 0) {
		this.module = module;
		this._depth = depth;

		this.searchForNestedAnimations(module);
	}

	private searchForNestedAnimations(module: Component) {
        let modules: Component[] = module.getComponents( false, false);
		// let modules: Component[] = module.getAllChildTypes( '', false);
		const l = modules.length;

		for (let i = 0; i < l; i++) {
			let child: Component = modules[i];
			if ('transitionIn' in child && 'transitionOut' in child) {
				this._tree.push(new RecursiveAnimations(child as ITransition, this._depth + 1));
			} else {
				this.searchForNestedAnimations(child);
			}
		}
	}

	public depth(): number {
		return this._depth;
	}

	public deepestDepth(): number {
		if (this._depth !== 0 && this._tree.length === 0) {
			return this.depth();
		}

		let deepestDepth: number = this.depth();
		const l = this._tree.length;
		for (let i = 0; i < l; i++) {
			let itemDepth = this._tree[i].deepestDepth();
			if (deepestDepth < itemDepth) {
				deepestDepth = itemDepth;
			}
		}

		return deepestDepth;
	}

	public getAtDepth(depth: number): RecursiveAnimations[] {
		let rVal: RecursiveAnimations[] = [];
		if(depth === -1){
		    return [this];
        }
		else if (depth < this._depth) {
			return [];
		} else if (this._depth === depth) {
			return this._tree;
		} else {
			this._tree.forEach(c => {
				rVal = rVal.concat(c.getAtDepth(depth));
			});
		}

		return rVal;
	}
}
