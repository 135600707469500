//Import for Webpack
import './CollapseModule.scss';
import { TweenLite, Expo } from 'gsap';
import Module from 'core/ts/system/Module';
import CoreCollapseModule from "core/modules/CoreCollapseModule/CoreCollapseModule";
import LazyLoadImage from "core/ts/utils/image/LazyLoadImage";

export default class CollapseModule extends CoreCollapseModule {

    protected showOrHide(show: boolean) {
        super.showOrHide(show);

        LazyLoadImage.forceUpdate();
        TweenLite.to(this.qs('.cross'), .5, {rotation: show === true ? 45 : 0, ease: Expo.easeOut});
    }
}