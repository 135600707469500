import './../scss/global.scss';
import bugsnag from '@bugsnag/js'

import AllModules from '../modules/AllModules';
import Component from 'core/ts/system/Component';
import TemplateManager, { Route } from 'core/ts/system/TemplateManager';
import { AnimateOnLoad, AnimateInstant, AnimateOnOut } from 'core/ts/system/transition/TemplateAnimation';
import ResizeManager from 'core/ts/utils/ResizeManager';
import ImgixImage from "core/components/ImgixImage";
import DebugTools from 'core/ts/utils/DebugTools';
import Template from 'core/ts/system/Template';
import ScrollControllerNative from "core/ts/utils/scroll/ScrollControllerNative";
import ScrollController from "core/ts/utils/scroll/ScrollController";
import ScrollControllerCustom from "core/ts/utils/scroll/ScrollControllerCustom";


window.onload = function() {
	// console.log('JEJJ');
	new App();
};

/**
 * @ignore
 */
export default class App {
	public static SCROLL: ScrollController;
	public static RESIZE: ResizeManager;
	public static CONTENT: TemplateManager;
	public static BUGSNAG: any;
	public static DEBUG: DebugTools;

	//@ts-ignore
	public static ENV: any = window.__SERVER;

	constructor() {
		// console.log("App.ENV.bugsnagKey : " + App.ENV.bugsnagKey);
		// console.log("App.ENV.env : " + App.ENV.env);

		// console.log('App.ENV.guide : ' + App.ENV.guide);

		if(App.ENV.guide === true) {
			return;
		}

		if(App.ENV.env === 'prod') {
            App.BUGSNAG = bugsnag(App.ENV.bugsnagKey);
        } else {
            App.DEBUG = new DebugTools();
            App.DEBUG.listenForCacheClear();
        }


		//TODO: Find an alternative to this. Used for webpack;
		new AllModules();

        /**
         * Reading .env values in JS like this.
         * -WARNING, NEVER READ OR LOG SECURITY VALUE LIKE PASSWORDS etc. THEY WILL BE COMPILED AND OUTPUT TO THE JS
         * -SOMETHING LIKE DB_PASS should never be used!
         * TODO: Wrap this in a seperate class with common used values
         */
        // console.log('DB_HOST: ' + process.env.MAMP_HOST);
        // console.log('DB_PORT: ' + process.env.MAMP_PORT);
        // console.log('ENV: ' + process.env.ENV);


		history.scrollRestoration = 'manual';

		App.RESIZE = new ResizeManager();

		this.setupDefaultModules();
		this.addTemplateManager();
		this.setupScroll();

		Component.create(document.body, null, true, []);

		/**
         * Init the first element;
         * -Do this when everything else is setup.
         **/
		App.CONTENT.createBodyTemplate();
	}

	private addTemplateManager() {
		// caching
		console.log('App.ENV.lastUpdate : ' + App.ENV.lastUpdate);

		// if(App.ENV.env === 'prod') {
			// TemplateManager.localCacheHash = App.ENV.lastUpdate;
		// }

		App.CONTENT = new TemplateManager(
			document.getElementById('TemplateLayer'),

			/** Choose or create your own page animation style/sequence **/
			// new AnimateOnLoad(false)
			// new AnimateInstant(true)
			new AnimateOnOut( false )
			//...
		);
        // console.log( 'test' );
		/**
		 * Listen for Page/Template __tryLoad
		 * -Could be used for a static loading overlay if needed for a specific page animation
		 */
		App.CONTENT.onLoadingStart.subscribe(this.onLoadingNewPage);
		App.CONTENT.onLoadingDone.subscribe(this.onLoadingNewPageDone);
		App.CONTENT.onNewTemplate.sub(this.onNewTemplate);



		/**
		 * Pointer feature
		 * -point urls' to different path's
		 */

		/** f.eks. Show the '/contact' page, on the '/contact-us' url but keep '/contact-us' as the url **/
		// App.CONTENT.addRoute( new Route( '/contact-us', 'contact', false ) )

		/** f.eks. Point old urls, to new ones and set the history to the pointer **/
		// App.CONTENT.addRoute( new Route( '#games/daddylonglegs', '/game/daddy', true ))
	}

	private onLoadingNewPage = () => {
		// console.log( 'SHOW - LOADING SCREEN OR NOT DEPENDING ON PAGE TRANSITION' );
	};

	private onLoadingNewPageDone = () => {
		// console.log( 'HIDE - LOADING SCREEN OR NOT DEPENDING ON PAGE TRANSITION' );
	};

	private onNewTemplate(template:Template){
		App.SCROLL.setTemplate(template);
	}


    private setupDefaultModules() {
        /**
         * This will only be applied to children of modules ( everything under Templates and other modules like the MainMenu )
         * -So basically everything if you use the system. :)
         */

        ImgixImage.QUALITY = '80';
        Component.applyComponentToTag( 'IMG', 'ImgixImage' );
    }

	private setupScroll() {
		if (ScrollController.isOnTouch()) {
			App.SCROLL = new ScrollControllerNative();
		} else {
			App.SCROLL = new ScrollControllerCustom();
		}
	}
}
