import Component from "core/ts/system/Component";
import Cursor from "core/ts/utils/cursor/Cursor";
import { TweenLite, Expo } from 'gsap';
import ArrowCursor from "ts/cursors/ArrowCursor";
import ColorManager from "ts/ColorManager";

export default class DefaultCursor extends Cursor {
	public pt1:any = {x: 50, y: 50};
	public pt2:any = {x: 50, y: 50};

	public pt3:any = {x: 50, y: 50};
	public pt4:any = {x: 50, y: 50};

	public pt5:any = {x: 50, y: 50};
	public pt6:any = {x: 50, y: 50};

	private _element:HTMLElement = document.createElement('div');

	public getElement():HTMLElement {
		this._element.style.position = 'absolute';
		this._element.style.left = '-50px';
		this._element.style.top = '-50px';
		this._element.classList.add('cursor');

		return this._element;
	}

	public activate(oldCursor:Cursor = null):void {
		TweenLite.killTweensOf([this.pt1, this.pt2, this.pt3, this.pt4, this.pt5, this.pt6]);

		const offsetY:number = 1;
		const offsetX:number = .5;
		const height:number = 32;
		const halfHeight:number = height / 2;
		const wingSize:number = 0;

		this.pt1.x = 50;
		this.pt1.y = 50;
		this.pt2.x = 50;
		this.pt2.y = 50;

		this.pt3.x = 50 + offsetX;
		this.pt3.y = 50 + halfHeight + offsetY;
		this.pt4.x = 50 + offsetX;
		this.pt4.y = 50 + halfHeight + offsetY;

		this.pt5.x = 50 - offsetX;
		this.pt5.y = 50 + halfHeight + offsetY;
		this.pt6.x = 50 - offsetX;
		this.pt6.y = 50 + halfHeight + offsetY;

		if(oldCursor !== null) {
			if(oldCursor instanceof ArrowCursor) {
				this.pt1.y = (oldCursor as ArrowCursor).pt1.y;
				this.pt2.y = (oldCursor as ArrowCursor).pt2.y;

				this.pt4.x = (oldCursor as ArrowCursor).pt4.x;
				this.pt4.y = (oldCursor as ArrowCursor).pt4.y;

				this.pt6.x = (oldCursor as ArrowCursor).pt6.x;
				this.pt6.y = (oldCursor as ArrowCursor).pt6.y;
			}
		}

		TweenLite.to(this.pt1, .4, {y: 50 - halfHeight, ease: Expo.easeOut });
		TweenLite.to(this.pt2, .4, {y: 50 + halfHeight, ease: Expo.easeOut });

		TweenLite.to(this.pt4, .4, {x: 50 - wingSize + offsetX, y: 50 + halfHeight - wingSize + offsetY, ease: Expo.easeOut, delay: .1 });
		TweenLite.to(this.pt6, .4, {x: 50 + wingSize - offsetX, y: 50 + halfHeight - wingSize + offsetY, ease: Expo.easeOut, delay: .1, onComplete: this.animationDone });

		this.startRender();
	}

	protected animationDone = () => {
		super.stopRender();
	};

	protected render(deltaTime:number, totalTime:number) {
		super.render(deltaTime, totalTime);

		let content:string = '<svg height="100" width="100">\' +\n' +
			'\t\t\t\'<line x1="' + this.pt1.x + '" y1="' + this.pt1.y + '" x2="' + this.pt2.x + '" y2="' + this.pt2.y + '" style="stroke:' + ColorManager.Instance.foregroundColor + ';stroke-width:2" />\' +\n' +
			'\t\t\t\'<line x1="' + this.pt3.x + '" y1="' + this.pt3.y + '" x2="' + this.pt4.x + '" y2="' + this.pt4.y + '" style="stroke:' + ColorManager.Instance.foregroundColor + ';stroke-width:2" />\' +\n' +
			'\t\t\t\'<line x1="' + this.pt5.x + '" y1="' + this.pt5.y + '" x2="' + this.pt6.x + '" y2="' + this.pt6.y + '" style="stroke:' + ColorManager.Instance.foregroundColor + ';stroke-width:2" />\' +\n' +
			'\t\t\'</svg>';

		this._element.innerHTML = content;
	}
}