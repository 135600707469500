export class DOMParameters {

    public readonly plain: { [k: string]: string };
    public readonly arr: { [k: string]: string[] };
    public readonly refs: { [k: string]: NodeListOf<HTMLElement> };
    public readonly ref: { [k: string]: HTMLElement };

    constructor( element:Element ) {
        this.plain = DOMParameters.getParsedPlain( element );
        this.arr = DOMParameters.getParsedArr( element );
        this.refs = DOMParameters.getParsedRefs( element );
        this.ref = DOMParameters.getParsedRef( element );
    }

    private static getParsedPlain(content: Element): { [k: string]: string } {
        let params: { [k: string]: string } = {};
        this.loopParams(content, (varName:string, varValue:string )=> {
            if( varName[0] === '@' ||
                varName[0] === '[' && varName[1] === ']'
            ) {
                return;
            }

            if (varValue === '') {
                //Parse Boolean
                params[varName] = 'true';
            } else {
                params[varName] = varValue;
            }
        });
        return params;
    }

    private static getParsedArr(content: Element): { [k: string]: string[] } {
        let params: { [k: string]: string[] } = {};
        this.loopParams(content, (varName:string, varValue:string )=> {
            if(varName[0] !== '[' && varName[1] !== ']' ) {
                return;
            }
            varName = varName.substring(2, varName.length);
            params[varName] = varValue.replace(' ', '').split(',');
        });

        return params;
    }

    private static getParsedRefs(content: Element): { [k: string]: NodeListOf<HTMLElement> } {
        let params: { [k: string]: NodeListOf<HTMLElement> } = {};
        this.loopParams(content, (varName:string, varValue:string )=> {
            if( varName[0] !== '@' || varName[1] !== '[' && varName[2] !== ']'  ) {
                return;
            }
            varName = varName.substring(3, varName.length);

            let search = varValue;
            let searchOn = content;
            while (search.length > 0 && search.substring(0, 1) === '<') {
                if (content.parentElement) {
                    searchOn = content.parentElement;
                }
                search = search.substring(1, varValue.length);
            }
            params[varName] = searchOn.querySelectorAll(search) as  NodeListOf<HTMLElement>;
        });

        return params;
    }

    private static getParsedRef(content: Element): { [k: string]: HTMLElement } {
        let params: { [k: string]: HTMLElement } = {};
        this.loopParams(content, (varName:string, varValue:string )=> {
            if( varName[0] !== '@' || varName[1] === '[' && varName[2] === ']' ) {
                return;
            }
            varName = varName.substring(1, varName.length);

            let search = varValue;
            let searchOn = content;
            while (search.length > 0 && search.substring(0, 1) === '<') {
                if (content.parentElement) {
                    searchOn = content.parentElement;
                }
                search = search.substring(1, varValue.length);
            }
            params[varName] = searchOn.querySelector(search) as HTMLElement;
        });

        return params;
    }


    private static loopParams( element:Element, callback:Function ) {
        let params: string[] = [];
        let names: NamedNodeMap = element.attributes;

        const l = names.length;
        for (let i = 0; i < l; i++) {
            if (names.item(i).name.indexOf('data-') !== -1) {
                let varName = names.item(i).name.replace('data-', '').toLowerCase();
                let varValue = element.getAttribute( names.item(i).name );

                if (params.indexOf(varName) !== -1) {
                    console.error('Double params found: ' + varName + ' The last parameter will be ignored.');
                    continue;
                }

                callback( varName, varValue );
                params.push(varName);
            }
        }
    }
}