import html2canvas from 'html2canvas';
import Path from "core/ts/utils/Path";
import Module from "core/ts/system/Module";

export default class HTTPReply {
    public raw:string;

    constructor(request:XMLHttpRequest) {
        this.raw = request.responseText;
    }
}