//Import for Webpack
import './SubMenu.scss';

import Module from 'core/ts/system/Module';
import TemplateManager from 'core/ts/system/TemplateManager';
import { TweenLite } from 'gsap';
import App from 'ts/app';
import DefaultTemplate from 'templates/DefaultTemplate';
import PreloaderModule from 'modules/PreloaderModule/PreloaderModule';
import ScrollControllerCustom from 'core/ts/utils/scroll/ScrollControllerCustom';
import Breakpoints from 'core/ts/utils/breakpoints/Breakpoints';
import MenuModule from 'modules/MenuModule/MenuModule';
import ColorUtils from 'core/ts/utils/ColorUtils';

export default class SubMenu extends Module {
	private _container: HTMLElement = null;
	private _content: HTMLElement = null;
	private _buttons: Array<HTMLElement> = null;
	private _sections: Array<HTMLElement> = null;
	private _template: DefaultTemplate = null;

	private _menuModule: HTMLElement = null;
	private _menuHeight: any = null;

	protected awake(): void {
		super.awake();

		this.createButtons();

		this._container = this.qs('.sub-menu-container');
		this._content = this.qs('.sub-menu-content');

		this._menuModule = document.getElementById('SiteWrapper').querySelector('.MenuModule');

		Breakpoints.Instance.onNewBreakpoint.sub(this.onNewBreakpoint);
		this.onNewBreakpoint();

		if (App.SCROLL instanceof ScrollControllerCustom) {
			// document.getElementById('TemplateLayer').prepend(this._container);
			// App.SCROLL.onMove().sub(this.onScroll);
			// App.RESIZE.onResize.sub(this.onResize);
		}

		TemplateManager.Instance.onNewTemplate.sub(this.onNewTemplate);
		if (TemplateManager.Instance.getCurrentTemplate()) {
			this.onNewTemplate();
		}

		App.SCROLL.onMove().sub(this.checkActiveSubmenu);
	}

	private onNewBreakpoint = (): void => {
		App.SCROLL.onMove().unsub(this.onScroll);

		if (App.SCROLL instanceof ScrollControllerCustom) {
			if (Breakpoints.Instance.getBreakpointName() !== Breakpoints.Instance.mobile.getName()) {
				document.getElementById('TemplateLayer').prepend(this._container);
				App.SCROLL.onMove().sub(this.onScroll);
				App.RESIZE.onResize.sub(this.onResize);

				this._menuHeight = this._menuModule.offsetHeight;

				this.onScroll();
			} else {
				this.getElement().parentElement.insertBefore(this._container, this.getElement().nextSibling);
			}
		}
	};

	private createButtons(): void {
		this._buttons = new Array<HTMLElement>();
		this._sections = new Array<HTMLElement>();

		let templateButton: HTMLElement = this.qs('.button');

		// console.dir(templateButton);

		let list: NodeListOf<HTMLElement> = this.getRoot()
			.getElement()
			.querySelectorAll('.sub-menu-section');
		// console.log('list : ' + list);
		const l: number = list.length;
		// console.log('l : ' + l);

		for (let i: number = 0; i < l; i += 1) {
			// console.dir(list[i]);

			let target: HTMLElement = list[i];
			let buttonText: string = target.getAttribute('data-section-name');

			if (buttonText === '' || buttonText === null) {
				continue;
			}

			let button: HTMLElement = templateButton.cloneNode(true) as HTMLElement;
			button.innerText = target.getAttribute('data-section-name');

			templateButton.parentNode.appendChild(button);

			button.addEventListener('click', this.onButtonClick);

			this._buttons.push(button);
			this._sections.push(target);
		}

		templateButton.style.display = 'none';
	}

	private checkActiveSubmenu = () => {
		let activeSections: number[] = [];

		this._sections.forEach((element, index) => {
			if (element.getBoundingClientRect().y + App.SCROLL.getCurrentScroll().y - this._menuHeight - 100 < App.SCROLL.getCurrentScroll().y) {
				activeSections.push(index);
			}
		});

		let active = activeSections[activeSections.length - 1];

		if (active === undefined) {
			let url = new URL(window.location.origin + window.location.pathname);

			window.history.replaceState(null, null, url.toString());
		} else {
			this._buttons.forEach((element, index) => {
				if (active === index) {
					element.style.textDecoration = 'underline';
					let url = new URL(window.location.origin + window.location.pathname);
					url.searchParams.append('sub', encodeURIComponent(element.textContent));

					window.history.replaceState(null, element.textContent, url.toString());
				} else {
					element.style.textDecoration = 'none';
				}
			});
		}
	};

	private checkIsDeeplink = () => {
		let search = new URL(document.location.href).searchParams;
		let searchValue = decodeURIComponent(search.get('sub'));

		this._buttons.forEach((element, index) => {
			if (element.textContent === searchValue) {
				App.SCROLL.scrollTo(this._sections[index].getBoundingClientRect().y + App.SCROLL.getCurrentScroll().y - this._menuHeight, 0.75);
			}
		});
	};

	private onButtonClick = (e: Event): void => {
		let index: number = this._buttons.indexOf(e.currentTarget as HTMLElement);
		let section: HTMLElement = this._sections[index];

		App.SCROLL.scrollTo(section.getBoundingClientRect().y + App.SCROLL.getCurrentScroll().y - this._menuHeight, 0.75);
	};

	protected sleep(): void {
		super.sleep();

		TemplateManager.Instance.onNewTemplate.unsub(this.onNewTemplate);

		Breakpoints.Instance.onNewBreakpoint.unsub(this.onNewBreakpoint);
		App.SCROLL.onMove().unsub(this.onScroll);
		App.RESIZE.onResize.unsub(this.onResize);

		this._template.onTransitionUpdate.unsub(this.onScroll);
		this._container.parentNode.removeChild(this._container);

		const l: number = this._buttons.length;
		for (let i: number = 0; i < l; i += 1) {
			let button: HTMLElement = this._buttons[i];

			button.removeEventListener('click', this.onButtonClick);
		}
		App.SCROLL.onMove().unsub(this.checkActiveSubmenu);
	}

	private onNewTemplate = (): void => {
		this._template = TemplateManager.Instance.getCurrentTemplate() as DefaultTemplate;
		// console.dir();

		if (App.SCROLL instanceof ScrollControllerCustom) {
			this.onScroll();
			this._template.onTransitionUpdate.sub(this.onScroll);
		}

		const subMenuColor = ColorUtils.mix(MenuModule.getLastUsedColor(), '#FFFFFF', 90);
		this._content.style.backgroundColor = subMenuColor;
		this._template.onTransitionInDone.sub(e => {
			this.checkIsDeeplink();
		});
	};

	private onResize = (): void => {
		this._menuHeight = this._menuModule.offsetHeight;
		this.onScroll();
	};

	private onScroll = (): void => {
		// console.log('ScrollControllerNative.Instance.getCurrentY() : ' + ScrollControllerNative.Instance.getCurrentY());
		let minY: number = 95;
		if (Breakpoints.Instance.getBreakpointName() === Breakpoints.Instance.mobile.getName()) {
			minY = 0;
		}
		let yPos: number = this.getElement().getBoundingClientRect().y;

		// Check if simple hero is present before, if not. Match the menu in position and height
		if (this.getParent().getChildComponentNodes()[0].params.plain.module === 'SimpleHero') {
			if (yPos <= minY) {
				yPos = minY;
			}
		} else {
			let scrollRatio: number = (1 / 160) * App.SCROLL.getCurrentScroll().y;

			if (scrollRatio > 1) {
				scrollRatio = 1;
			}

			yPos = -55 * scrollRatio + 160 - 10 * scrollRatio;
		}

		TweenLite.set(this._container, { y: yPos - PreloaderModule.Instance.tweenValues.offsetTop });
	};
}
