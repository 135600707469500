//Import for Webpack
import './VacantPositionsModule.scss';

import Module from 'core/ts/system/Module';
import LinkHandler from "core/ts/utils/LinkHandler";

export default class VacantPositionsModule extends Module {
    protected build(): void {
        LinkHandler.Instance.addAllAtags(this.getElement());
    }

    protected sleep(): void {
        LinkHandler.Instance.removeAllAtags(this.getElement());
    }
}