//Import for Webpack
import './LatestNewsModule.scss';

import Module from 'core/ts/system/Module';
import LinkHandler from "core/ts/utils/LinkHandler";
import CoreSlideshowModule from "core/modules/CoreSlideshowModule/CoreSlideshowModule"; 
import LazyLoadImage from 'core/ts/utils/image/LazyLoadImage';
import Breakpoints from 'core/ts/utils/breakpoints/Breakpoints';

export default class LatestNewsModule extends CoreSlideshowModule {

    private _size:number = null;


	protected build() {
		super.build();
		const images = this.getComponentsByType<LazyLoadImage>(LazyLoadImage);
		images.forEach(img=> {
			img.setImageHeightToParent = false;
        })
	}

	protected awake(): void {
        super.awake();

        LinkHandler.Instance.addAllAtags(this.getElement());
        Breakpoints.Instance.onNewBreakpoint.sub(this.onNewBreakpoint);
        this.onNewBreakpoint();
        

        //************************** */ Quick fix begin ***************************
        //for forcing recent insights to bottom. Should be removed, when the data is changed in CMS
        const childArray:HTMLCollection = this.getElement().parentNode.children;
        const children:Array<HTMLElement> = Array.prototype.slice.call(childArray);


        const thisIndex:number = children.indexOf(this.getElement());
        const previousElement = children[thisIndex-1];

        const footerElement = children[childArray.length-1];

        if(previousElement.className == "Module BackModule") {
            console.log("previous element was backmodule");
            this.getElement().parentElement.insertBefore(this.getElement(), footerElement);
        }
        //******************** */ Quick fix end **********************

        // this.onResize();
    }

    private onNewBreakpoint = ():void => {
        this.onResize();
    }

    private repositionDots = () => {
        
        if(Breakpoints.Instance.getBreakpointName() !== Breakpoints.Instance.mobile.getName()) {
            const dots = this.qs('.dots');
            const img = this.qs('.image');
            const seeAllButton = this.qs('.see-all-news');
    
            let dotsPosition = img.offsetHeight - 45;
            let spacing = 28;
    
            dots.style.marginTop =  dotsPosition + 'px';

            if(seeAllButton) {
                seeAllButton.style.marginTop = spacing + 'px';
            }
        }
    };

    protected onResize():void {
        let containerSize:number = this.slideContainer.parentElement.parentElement.offsetWidth;
        let size:number = (this.slideContainer.parentElement.parentElement.offsetWidth - 30) / 3;;

        if(Breakpoints.Instance.getBreakpointName() === Breakpoints.Instance.mobile.getName()) {
            size = (this.slideContainer.parentElement.parentElement.offsetWidth);
        }

        
        // console.log('size : ' + size);
        // console.log('this.slides.length : ' + this.slides.length);

        let totalWidth:number = size * this.slides.length;

        if(totalWidth < containerSize){
            totalWidth = containerSize;
        }

        // console.log('totalWidth : ' + totalWidth);
        this.slideContainer.style.width = totalWidth + 'px';

        this.offsetItems(15);
        this.repositionDots();



        super.onResize();
    }

    protected sleep(): void {
        LinkHandler.Instance.removeAllAtags(this.getElement());
    }

}