import VarDumpItem from "./VarDumpItem";
import VarDumpArray from "./VarDumpArray";
import Component from "core/ts/system/Component";

/**
 * @ignore
 */
export default class VarDumpVariable extends VarDumpItem {
	private _type: string;
	private _value: string;
	private _length: number;

	constructor(item: any, name: string, path: string, parentArray: VarDumpArray) {
		super(name, path, parentArray);

		this._type = typeof(item);
		this._length = this.findLength(item);
	
		this._value = item;
	}

	protected get length(): number {
		return this._length;
	}

	protected get type(): string {
		return this._type;
	}

	protected get value(): string {
		return this._value;
	}

	//TODO: This isn't optimal, what if something is already encoded?
	private static decodeHTML(text: string): string {
		text = text.replace(/&gt;/g, ">");
		text = text.replace(/&lt;/g, "<");
		text = text.replace(/&amp;/g, "&");

		return text;
	}

	public createElement():HTMLElement {
		this.getContainer().classList.add('dump-variable-element');
		
		const container = <div class="dump-item">
			<div class="value">
				<p>{VarDumpVariable.decodeHTML(this._value.toString())}</p>
			</div>
			<div class="name">
				{this.name}
			</div>
			<div class="type">
				<p>{this.type}</p>
				<div class="length">{this.length.toString()}</div>
			</div>
		</div>;

		this.getContainer().appendChild(container);

		//TODO: change this to be included when variables in tsx can be used as attributes
		container.classList.add('dump-'+this.type);

		return this.getContainer();
	}
}