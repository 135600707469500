import html2canvas from 'html2canvas';
import Path from "core/ts/utils/Path";
import Module from "core/ts/system/Module";

export default class DebugTools {

	private static _moduleToPicture:Array<HTMLElement> = new Array<HTMLElement>();

	private static _cloned:HTMLElement = null;

	public listenForCacheClear() {
		return;

		const keysDown:string[] = [];
		window.addEventListener('keydown', (e)=> {
			keysDown.push(e.key);
			if(
				keysDown.indexOf('c') !== -1 &&
				keysDown.indexOf('m') !== -1 &&
				keysDown.indexOf('s') !== -1
			) {
				DebugTools.clearCMSCache();
			}

			if(keysDown.indexOf('l') !== - 1) {
				DebugTools.saveForGuide();
			}
		});

		// window.addEventListener('click', (e:Event):void => {
		//
		// });

		window.addEventListener('keyup', (e)=> {
			keysDown.splice(keysDown.indexOf(e.key), 1);
		});
	}

	public static async clearCMSCache() {
		await fetch('/cache/ClearContentCache.php', {method: 'POST'}).then(()=> {
			console.log('CONTENT CLEARED');
		});

		await fetch('/cache/ClearPersistentCache.php', {method: 'POST'}).then(()=> {
			console.log('PERSISTENT CLEARED');
		});

		await fetch('/cache/ClearSystemCache.php', {method: 'POST'}).then(()=> {
			console.log('SYSTEM CLEARED');
		});

		window.location.reload();
	}

	public static saveForGuide() {
		// let modules:NodeListOf<HTMLElement> =  document.querySelectorAll('.Module');
		let modules:NodeListOf<HTMLElement> =  document.querySelectorAll('.Module');


		modules.forEach((module:HTMLElement):void =>  {
			DebugTools._moduleToPicture.push(module);
		});

		DebugTools.grabNext();
	}

	private static grabNext():void {
		console.log('DebugTools.grabNext();');

		if(DebugTools._moduleToPicture.length === 0) {
			console.log('All done!');
			return;
		}

		let module:HTMLElement = DebugTools._moduleToPicture[0];
		DebugTools._moduleToPicture.splice(0, 1);

		let cloned:HTMLElement = module.cloneNode(true) as HTMLElement;
		cloned.innerHTML = cloned.innerHTML.replace(/data-src/g, 'src');

		let container:HTMLElement = document.createElement('div');
		container.style.position = 'relative';
		container.style.width = '1200px';
		// container.style.height = '100%';
		// container.style.display = 'flex';

		container.appendChild(cloned);
		document.body.appendChild(container);

		DebugTools._cloned = cloned;

		setTimeout(DebugTools.takePicture, 200);
	}

	private static takePicture():void {
		console.log('this._cloned.getBoundingClientRect().height : ' + DebugTools._cloned.getBoundingClientRect().height);
		DebugTools._cloned.parentElement.style.height = DebugTools._cloned.getBoundingClientRect().height + 'px';

		// console.log('cloned.innerHTML : ' + cloned.innerHTML);

		window.scrollTo(0, 0);

		html2canvas(DebugTools._cloned.parentElement, {useCORS: true}).then(function(canvas) {

			let dataURL = canvas.toDataURL('image/png');

			let request:XMLHttpRequest = new XMLHttpRequest();
			request.onreadystatechange = onReadyState;
			request.open('POST', './php/saveScreenshot.php', true);
			request.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');

			let params:string = 'img=' + dataURL + '&name=' + DebugTools._cloned.getAttribute('data-module').split(' ')[0];
			// console.log('params : ' + params);
			request.send(params);

			function onReadyState() {
				if (request.readyState === 4 && request.status === 200) {
					console.log('request.responseText : ' + request.responseText);
					DebugTools._cloned.parentNode.parentNode.removeChild(DebugTools._cloned.parentNode);

					setTimeout(DebugTools.grabNext, 200);
				}
			}

		});
	}

}