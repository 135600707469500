//Import for Webpack
import './HeroModule.scss';

import Module from 'core/ts/system/Module';
import LinkHandler from "core/ts/utils/LinkHandler";
import TextShuffler from "modules/TextShuffler/TextShuffler";
import MenuModule from "modules/MenuModule/MenuModule";

export default class HeroModule extends Module {
    private _textShuffler:TextShuffler = null;

    private _timeout:NodeJS.Timeout = null;
    private _index:number = 0;
    private _list:Array<string> = new Array<string>('Text 1', 'Text 2');

    private _interval:number = 5000;

    protected build(): void {
        LinkHandler.Instance.addAllAtags(this.getElement());

        // this._textShuffler = this.getComponentByType<TextShuffler>(TextShuffler, true, false);
    }

    protected awake(): void {
        super.awake();

        console.log('getLastUsedColor() : ' + MenuModule.getLastUsedColor());
        this.getElement().style.backgroundColor = MenuModule.getLastUsedColor();

        // this._timeout = setInterval(this.tick, this._interval, this._interval);

        // this.updateToIndex();
    }

    private tick = ():void => {


        this._index += 1;
        if(this._index > this._list.length - 1) {
            this._index = 0;
        }

        this.updateToIndex();
    };

    private updateToIndex():void {
        console.log('HeroModule.updateToIndex();');

        this._textShuffler.changeText(this._list[this._index]);
    }

    protected sleep(): void {
        LinkHandler.Instance.removeAllAtags(this.getElement());

        if(this._timeout !== null) {
            clearInterval(this._timeout);
            this._timeout = null;
        }
    }

}