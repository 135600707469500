/**PLOP::IMPORT_LIST**/
import VacantPositionsEmbedModule from 'modules/VacantPositionsEmbedModule/VacantPositionsEmbedModule';
import TransactionsModule from 'modules/TransactionsModule/TransactionsModule';
import LatestTransactionsModule from 'modules/LatestTransactionsModule/LatestTransactionsModule';
import CollapseableHeightBased from 'modules/CollapseableHeightBased/CollapseableHeightBased';
import CollapseableChildrenBased from 'modules/CollapseableV2/CollapseableChildrenBased';
import CookieBanner from 'modules/CookieBanner/CookieBanner';
import TransactionHighlight from 'modules/TransactionHighlight/TransactionHighlight';
import QuoteItem from 'modules/QuoteItem/QuoteItem';
import ContactUsLayout from 'modules/ContactUsLayout/ContactUsLayout';
import EmployeeStoriesModule from 'modules/EmployeeStoriesModule/EmployeeStoriesModule';
import LatestStoriesModule from 'modules/LatestStoriesModule/LatestStoriesModule';
import ButtonEffect from 'modules/ButtonEffect/ButtonEffect';
import OrganisationLayout from 'modules/OrganisationLayout/OrganisationLayout';
import SocialMenu from 'modules/SocialMenu/SocialMenu';
import PeopleCollection from 'modules/PeopleCollection/PeopleCollection';
import JoinUsModule from 'modules/JoinUsModule/JoinUsModule';
import ForeignDesksModule from 'modules/ForeignDesksModule/ForeignDesksModule';
import RevealText from 'modules/RevealText/RevealText';
import CareerModule from 'modules/CareerModule/CareerModule';
import HistoryModule from 'modules/HistoryModule/HistoryModule';
import PreloaderModule from 'modules/PreloaderModule/PreloaderModule';
import VacancyText from 'modules/VacancyText/VacancyText';
import ImageGrid from 'modules/ImageGrid/ImageGrid';
import TextLayout3 from 'modules/TextLayout3/TextLayout3';
import VacantPositionsModule from 'modules/VacantPositionsModule/VacantPositionsModule';
import ContactPerson from 'modules/ContactPerson/ContactPerson';
import EventList from 'modules/EventList/EventList';
import TextLayout2 from 'modules/TextLayout2/TextLayout2';
import CareerLayout from 'modules/CareerLayout/CareerLayout';
import StandardLayout from 'modules/StandardLayout/StandardLayout';
import ProfileModule from 'modules/ProfileModule/ProfileModule';
import ContactModule from 'modules/ContactModule/ContactModule';
import PersonItem from 'modules/PersonItem/PersonItem';
import OurPeopleModule from 'modules/OurPeopleModule/OurPeopleModule';
import PublicationHero from 'modules/PublicationHero/PublicationHero';
import NewsPreview from 'modules/NewsPreview/NewsPreview';
import LatestNewsModule from 'modules/LatestNewsModule/LatestNewsModule';
import CollapseModule from 'modules/CollapseModule/CollapseModule';
import DoingBusinessModule from 'modules/DoingBusinessModule/DoingBusinessModule';
import RecentPublicationsModule from 'modules/RecentPublicationsModule/RecentPublicationsModule';
import NewsletterSignupModule from 'modules/NewsletterSignupModule/NewsletterSignupModule';
import PublicationsModule from 'modules/PublicationsModule/PublicationsModule';
import SubMenu from 'modules/SubMenu/SubMenu';
import BackModule from 'modules/BackModule/BackModule';
import LinkCollection from 'modules/LinkCollection/LinkCollection';
import ExpertiseAreaModule from 'modules/ExpertiseAreaModule/ExpertiseAreaModule';
import RecognitionModule from 'modules/RecognitionModule/RecognitionModule';
import Footer from 'modules/Footer/Footer';
import QuoteModule from 'modules/QuoteModule/QuoteModule';
import TextShuffler from 'modules/TextShuffler/TextShuffler';
import PartnersModule from 'modules/PartnersModule/PartnersModule';
import ImageModule from 'modules/ImageModule/ImageModule';
import TextSection from 'modules/TextSection/TextSection';
import SimpleHero from 'modules/SimpleHero/SimpleHero';
import HeroModule from 'modules/HeroModule/HeroModule';
import ErrorModule from 'core/modules/ErrorModule/ErrorModule';
import Preloader from 'components/Preloader';
import MainMenuButton from 'components/MainMenuButton';
import CoreCollapseModule from 'core/modules/CoreCollapseModule/CoreCollapseModule';
import MenuModule from 'modules/MenuModule/MenuModule';
import CoreSlideshowModule from 'core/modules/CoreSlideshowModule/CoreSlideshowModule';
import RootModule from 'core/modules/RootModule/RootModule';
import MissingModule from 'core/modules/MissingModule/MissingModule';

import VarDump from 'components/Dumps/VarDump/VarDump';
import DefaultTemplate from 'templates/DefaultTemplate';
import LazyLoadImage from 'core/ts/utils/image/LazyLoadImage';
import Component from 'core/ts/system/Component';
import DebugGridModule from 'core/modules/DebugGridModule/DebugGridModule';
import DebugOverlayModule from 'core/modules/DebugOverlayModule/DebugOverlayModule';
import SlideshowPageInfo from 'core/modules/CoreSlideshowModule/SlideshowPageInfo';
import SlideshowComponent from '../core/modules/CoreSlideshowModule/SlideshowComponent';
import ColorItem from 'components/ColorItem';
import ColorItemBackgroundBackground from 'components/ColorItemBackgroundBackground';
import ColorItemTextToForeground from 'components/ColorItemTextToForeground';
import ColorItemBorderBottomToForeground from 'components/ColorItemBorderBottomToForeground';
import ColorItemBackgroundToForeground from 'components/ColorItemBackgroundToForeground';
import ColorItemBorderTopToForeground from 'components/ColorItemBorderTopToForeground';
import ColorItemFillToForeground from 'components/ColorItemFillToForeground';
import ColorItemStrokeToForeground from 'components/ColorItemStrokeToForeground';
import LinkHit from 'core/components/LinkHit';
import ErrorTemplate from 'templates/ErrorTemplate';
import CopySlideInfo from 'core/modules/CoreSlideshowModule/CopySlideInfo';
import ColorItemCanvasStrokeToForeground from 'components/ColorItemCanvasStrokeToForeground';
import PreloaderIcon from 'components/PreloaderIcon';
import ScaleFontToParentWidth from 'core/components/ScaleFontToParentWidth';
import ImgixImage from 'core/components/ImgixImage';
import CoreVideoModule from 'core/modules/CoreVideoModule/CoreVideoModule';
import WindowInnerHeight from 'core/components/WindowInnerHeight';
import Cursor from 'core/ts/utils/cursor/Cursor';
import SlideshowPageMark from 'core/modules/CoreSlideshowModule/SlideshowPageMark';

// @ts-ignore
export const Modules: any = {
	/**PLOP::CLASS_LIST**/
	VacantPositionsEmbedModule,
	TransactionsModule,
	LatestTransactionsModule,
	CollapseableHeightBased,
	CollapseableChildrenBased,
	CookieBanner,
	TransactionHighlight,
	QuoteItem,
	ContactUsLayout,
	EmployeeStoriesModule,
	LatestStoriesModule,
	ButtonEffect,
	OrganisationLayout,
	SocialMenu,
	PeopleCollection,
	JoinUsModule,
	ForeignDesksModule,
	RevealText,
	CareerModule,
	HistoryModule,
	PreloaderModule,
	VacancyText,
	ImageGrid,
	TextLayout3,
	VacantPositionsModule,
	ContactPerson,
	EventList,
	TextLayout2,
	CareerLayout,
	StandardLayout,
	ProfileModule,
	ContactModule,
	PersonItem,
	OurPeopleModule,
	PublicationHero,
	NewsPreview,
	LatestNewsModule,
	CollapseModule,
	DoingBusinessModule,
	RecentPublicationsModule,
	NewsletterSignupModule,
	PublicationsModule,
	SubMenu,
	BackModule,
	LinkCollection,
	ExpertiseAreaModule,
	RecognitionModule,
	Footer,
	QuoteModule,
	TextShuffler,
	PartnersModule,
	ImageModule,
	TextSection,
	SimpleHero,
	HeroModule,
	ErrorModule,
	Preloader,
	PreloaderIcon,
	MainMenuButton,
	CoreCollapseModule,
	MenuModule,
	CoreSlideshowModule,
	SlideshowComponent,
	SlideshowPageInfo,
	CopySlideInfo,
	SlideshowPageMark,
	CoreVideoModule,
	RootModule,
	DebugGridModule,
	DebugOverlayModule,
	LazyLoadImage,
	ScaleFontToParentWidth,
	LinkHit,
	ColorItem,
	ColorItemBackgroundBackground,
	ColorItemBackgroundToForeground,
	ColorItemTextToForeground,
	ColorItemBorderTopToForeground,
	ColorItemBorderBottomToForeground,
	ColorItemFillToForeground,
	ColorItemStrokeToForeground,
	ColorItemCanvasStrokeToForeground,
	MissingModule,
	ErrorTemplate,
	VarDump,
	DefaultTemplate,
	ImgixImage,
	WindowInnerHeight,
	Cursor
};

/**
 * @ignore
 */
export default class AllModules {}
