/**
 * Usage
 *
 * Set the copy target - data-copyTargetClass="description"
 *
 */

//Import for Webpack
import Component from "core/ts/system/Component";
import SlideshowComponent from "core/modules/CoreSlideshowModule/SlideshowComponent";

export default class CopySlideInfo extends SlideshowComponent {
    protected build(): void {
        super.build();
    }

    protected updateToPage(): void {
        super.updateToPage();

        let target:HTMLElement = this.slideshow.getCurrentSlide().querySelector('.' + this.params.plain.copytargetclass);


        if(this.params.plain.copytargetattribute) {
            if(this.params.plain.copytargetattribute === 'href') {
                this.getElement().innerText = (target as HTMLAnchorElement).href;
            } else {
                this.getElement().innerText = target.getAttribute(this.params.plain.copytargetattribute);
            }
        } else {
            this.getElement().innerText = target.innerText;
        }
    }
}