
import HTTPReply from "core/ts/utils/HTTP/HTTPReply";
import HTTPMethod from "core/ts/utils/HTTP/HTTPMethod";
import HTTPData from "core/ts/utils/HTTP/DataTypes/HTTPData";

export default class HTTP {

	public static call(url:string, method:string = HTTPMethod.POST, data:HTTPData, callback:(reply:HTTPReply)=>any) {
		let xhr:XMLHttpRequest = new XMLHttpRequest();
		xhr.open(method, url, true);

		if (callback) {
			xhr.onload = function(this:XMLHttpRequest) {
				let reply = new HTTPReply(this);

				callback(reply);
			};
		}
		if (data != null) {
			xhr.setRequestHeader('Content-Type', data.getContentType());

			console.dir(data.getSerializedData());

			xhr.send(data.getSerializedData());
		}

		else xhr.send();
	}

}