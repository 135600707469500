export default class StringUtils {

    public static isEmail(value:string) {
        const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        return reg.test(value);
    }

    public static URLDecode( value:string ):{ [k: string]: any } {
        let arr = value.split( '&' );
        const l = arr.length;
        let obj:{ [k: string]: any };
        for(let i = 0; i < l; i += 1) {
            let param = arr[i].split("=");
            obj[param[0]] = param[1];
        }
        return obj;
    }

    public static URLEncode( value:{ [k: string]: any } ):string {
        let arr = [];
        for ( let key in value) {
            arr.push( key + "=" + value[key] );
        }
        return arr.join( '&' );
    }

    public static getAlphaNumeric( value: string ):string {
        return value.replace(/[^0-9A-Z,.]+/gi,"");
    }
}