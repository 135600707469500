/**
 * Usage
 *
 *
 */

//Import for Webpack
import Component from "core/ts/system/Component";
import SlideshowComponent from "core/modules/CoreSlideshowModule/SlideshowComponent";
import { TweenLite, Sine } from 'gsap';

export default class SlideshowPageMark extends SlideshowComponent {
    private _items:Array<HTMLElement> = new Array<HTMLElement>();

    protected awake(): void {
        super.awake();

        this.createItems();
    }

    private createItems():void {
        // console.log('SlideshowPageMark.createItems();');
        const l:number = this.slideshow.getNumOfSlides();

        let template:HTMLElement = this.qs('.item');
        this._items.push(template.querySelector('.selected'));

        for(let i:number = 1; i < l; i += 1) {
            let clone:HTMLElement = template.cloneNode(true) as HTMLElement;
            template.parentNode.appendChild(clone);
            this._items.push(clone.querySelector('.selected'));
        }

        this._items.forEach((item:HTMLElement) => {
            TweenLite.set(item, {opacity: 0});
	        item.addEventListener('click', this.onItemClick);
        });
    }

    protected sleep(): void {
        this._items.forEach((item:HTMLElement):void => {
            item.removeEventListener('click', this.onItemClick);
        });
    }

    private onItemClick = (e:Event):void => {
        let item:HTMLElement = e.target as HTMLElement;
        let index:number = this._items.indexOf(item);
	    index !== -1 && this.slideshow.gotoIndex(index);
    };

    protected updateToPage(): void {
        super.updateToPage();

        // console.log('this.slideshow.getCurrentIndex() : ' + this.slideshow.getCurrentIndex());

        const l:number = this.slideshow.getNumOfSlides();
        const currIndex:number = this.slideshow.getCurrentIndex();

        for(let i:number = 0; i < l; i += 1) {
            TweenLite.to(this._items[i], .5, {opacity: i === currIndex ? 1 : 0, ease: Sine.easeOut});
        }
    }
}