import Component from './Component';
import {SimpleEventDispatcher, SignalDispatcher} from 'strongly-typed-events';
import {DOMParameters} from 'core/ts/utils/DOMParameters';

/**
 * parent
 * sibling
 * child
 */

export default class ComponentNode {

	public __componentNodeTypeGuard: string = 'COMPONENT_NODE';

	private static ALL_ACTIVE_NODES: ComponentNode[] = [];

	private _onChildAdded = new SimpleEventDispatcher<Component>();
	private _onChildRemove = new SimpleEventDispatcher<Component>();
	private _onSiblingAdded = new SimpleEventDispatcher<Component>();

	private _onInitialized = new SignalDispatcher();
	public get onInitialized() {
		return this._onInitialized.asEvent();
	}

	private _isInitialized: boolean = false;

	public isInitialized() {
		return this._isInitialized;
	}

	private _onElementRemove = new SignalDispatcher();
	public get onElementRemove() {
		return this._onElementRemove.asEvent();
	}

	private _onElementRemoved = new SignalDispatcher();
	public get onElementRemoved() {
		return this._onElementRemoved.asEvent();
	}

	public get onChildAdded() {
		return this._onChildAdded.asEvent();
	}

	public get onSiblingAdded() {
		return this._onSiblingAdded.asEvent();
	}

	public get onChildRemoved() {
		return this._onChildRemove.asEvent();
	}

	private _onDestroy = new SignalDispatcher();
	public get onDestroy() {
	    return this._onDestroy.asEvent();
	}

	private _onAnyChildAdded = new SimpleEventDispatcher<Component>();
	public get onAnyChildAdded() {
		return this._onAnyChildAdded.asEvent();
	}

	private _onAnyChildRemoved = new SimpleEventDispatcher<Component>();
	public get onAnyChildRemoved() {
		return this._onAnyChildRemoved.asEvent();
	}

	public params: DOMParameters;

	private _childComponents: Component[] = [];
	private _siblingComponents: Component[] = [];

	private readonly _content: Element;

	private _isDestroyed:boolean = false;

	private _parentNode: ComponentNode = null;

	constructor(element: Element, parentNode: ComponentNode = null) {
		this._content = element;
		this._parentNode = parentNode;
	}

	public init() {
		this.__activateNode();

		this.params = new DOMParameters(this._content);
		Component.createSubComponents(this.getElement(), this);

		this._isInitialized = true;
		this._onInitialized.dispatch();
	}

	/**
	 * @ignore
	 */
	public __setParentNode(node: ComponentNode) {
		if(this._parentNode === node) {
			return;
		}

		if(this._parentNode !== null) {
			//REMOVE FROM OLD NODE
			this.getComponents().forEach(item => {
				this._parentNode.removeComponent(item);
			});
		}

		//CHANGE NODE
		this._parentNode = node;
		if(node !== null) {
			this.getComponents().forEach(item => {
				//ADD TO NEW NODE
				node.__addComponent(item);
			});
		}

	}


	public hasParentComponentNode(): boolean {
		return this._parentNode !== null;
	}

	/**
	 * @returns The element this ComponentNode is attached to.
	 *
	 * <H4>HTML</H4>
	 *
	 * ```
	 * <div data-module="comp"></div>
	 * ```
	 *
	 * <H4>TypeScript</H4>
	 *
	 * ```
	 * console.log( comp.getElement() ); //<div data-module="comp"></div>
	 * ```
	 *
	 */
	public getElement(): HTMLElement {
		return this._content as HTMLElement;
	}

	/**
	 * @ignore
	 */
	public __activateNode() {
		// console.log(this.getElement());
		if(ComponentNode.ALL_ACTIVE_NODES.indexOf(this) === -1) {
			ComponentNode.ALL_ACTIVE_NODES.push(this)
		}
		// console.log(ComponentNode.ALL_ACTIVE_NODES.length);
	}

	/**
	 * @ignore
	 */
	public __deactivateNode() {
		const index = ComponentNode.ALL_ACTIVE_NODES.indexOf(this);
		if(index !== -1) {
			// console.log('DEACTIVATE NODE ' + ComponentNode.ALL_ACTIVE_NODES.length);
			ComponentNode.ALL_ACTIVE_NODES.splice(index, 1);
		}
	}


	/**
	 * CREATING AND REMOVING
	 */

	/**
	 * - A shorthand for calling [[ComponentNode.removeChild]]
	 *
	 * Removes the Element from the DOM and if @param destroy is set to true also the Node and all it's components.
	 *
	 * @param destroy If set to false, the node and all it's components wont be destroy and can be added back [[ComponentNode.addChild]] at any point.
	 * When setting this to false the node will be kept in the memory and its now your job to kill it if it does not get added to dom again.
	 * Do this by simply calling [[removeThis]] again, but with the destroy parameter et to true;
	 */
	public removeThis( destroy:boolean = true ) {
		ComponentNode.removeChild( this, destroy );
	}

	/**
	 * @ignore
	 */
	public __removeFromDOM( destroy:boolean = true ) {
		this.__setParentNode(null);

		this.__dispatchElementRemove();
		if(this.getElement().parentElement) {
			this.getElement().parentElement.removeChild(this.getElement());
		}
		this.__dispatchElementRemoved();

		if(destroy) {
			this.__destroy();
		}
	}

	public isDestroyed() {
		return this._isDestroyed;
	}

	/**
	 * @ignore
	 */
	public __destroy() {
		if(this.hasParentComponentNode()) {
			this.__removeFromDOM( true );
			return;
		}

		this.__deactivateNode();
		// console.log(this.getElement());
		this.getChildComponentNodes().forEach(item => {
			item.__destroy();
		});

		this._isDestroyed = true;
		this._onDestroy.dispatch();
	}

	/**
	 * @ignore
	 */
	public __dispatchElementRemove() {
		this.getChildComponentNodes().forEach(item => {
			item.__dispatchElementRemove();
		});
		this._onElementRemove.dispatch();
	}

	/**
	 * @ignore
	 */
	public __dispatchElementRemoved() {
		this.getChildComponentNodes().forEach(item => {
			item.__dispatchElementRemoved();
		});
		this._onElementRemoved.dispatch();
	}

	/**
	 * @ignore
	 */
	public __addComponent(component: Component): void {
		if(this._childComponents.indexOf(component) !== -1) {
			console.warn('Component already added');
			return;
		}

		this._childComponents.push(component);

		component.node.onChildRemoved.sub(this.dispatchChildRemoved);
		component.node.onChildAdded.sub(this.dispatchChildAdded);

		this.dispatchChildAdded(component);
	}

	private removeComponent(component: Component): void {
		const compIndex = this._childComponents.indexOf(component);
		if(compIndex === -1) {
			console.warn('Could not find component');
			return;
		}

		// console.log( 'removeComponent');
		this._childComponents.splice(compIndex, 1);

		component.node.onChildRemoved.unsub(this.dispatchChildRemoved);
		component.node.onChildAdded.unsub(this.dispatchChildAdded);

		// component.__kill(removeFromDOM);
		this.dispatchChildRemoved(component);
	}


	/**
	 * @ignore
	 */
	public __addSibling(sibling: Component) {
		this._siblingComponents.push(sibling);
		this._onSiblingAdded.dispatch(sibling);
	}

	/**
	 * ```
	 * <div data-module="ComponentOne, ComponentTwo, ComponentThree" ></div>
	 * ```
	 *
	 * ```
	 * const siblings = componentOne.getComponents();
	 * console.log( siblings ); //[ ComponentTwo, ComponentThree ]
	 * ```
	 */
	public getComponents(): Component[] {
		return this._siblingComponents;
	}

	/**
	 * ```
	 * <div data-module="ComponentOne, ComponentTwo, ComponentThree" ></div>
	 * ```
	 *
	 * ```
	 * const sibling:ComponentTwo = node.getSibling<ComponentTwo>(ComponentTwo);
	 * ```
	 */
	public getComponentByType<T extends Component>(typef: Function): T {
		let result: T = null;
		this.getComponents().forEach(item => {
			if(item instanceof typef) {
				result = item as T;
			}
		});
		return result;
	};

	public getParent(): ComponentNode {
		return this._parentNode;
	}

	/**
	 * ```
	 * <div data-module="compOne, compTest">
	 *     <div data-module="compTwo"></div>
	 *     <div data-module="compThree"></div>
	 *     <div data-module="compTwo"></div>
	 * </div>
	 * ```
	 *
	 * ```
	 * const comp = compOne.getAllTypes();
	 * console.log( comp ); // [compTwo, compThree, compTwo]
	 *
	 * const comp = compOne.getAllTypes( true );
	 * console.log( comp ); // [compTwo, compThree, compTwo, compTest]
	 * ```
	 *
	 * @param includeSiblings If set to true the return value will include siblings. Check [[getComponents]] function to read more about siblings.
	 *
	 */
	public getChildComponents(includeSiblings: boolean = false): Component[] {
		const result: Component[] = [];
		if(includeSiblings) {
			this._siblingComponents.forEach(item => {
				result.push(item);
			});
		}
		this._childComponents.forEach(item => {
			result.push(item);
		});
		return result;
	}

	public getChildComponentNodes(): ComponentNode[] {
		const comps = this.getChildComponents();
		const result: ComponentNode[] = [];

		const l = comps.length;
		for(let i = 0; i < l; i++) {
			if(result.indexOf(comps[i].node) === -1) {
				result.push(comps[i].node);
			}
		}
		return result;
	}

	private dispatchChildRemoved = (comp: Component) => {
		this._onChildRemove.dispatch(comp);
		this._onAnyChildRemoved.dispatch(comp);
		//TODO: Recursive dispatch all children of component as well.
		// comp.getAllTypes().forEach(item => {
		//     this.dispatchChildRemoved(item);
		// });
	};

	private dispatchChildAdded = (comp: Component) => {
		this._onChildAdded.dispatch(comp);
		this._onAnyChildAdded.dispatch(comp);
		//TODO: Recursive dispatch all children of component as well.
		// comp.getAllTypes().forEach(item => {
		//     this.dispatchChildAdded(item);
		// });
	};


	/**
	 * STATIC BELOW
	 */

	/**
	 *
	 * @param child
	 * @param parent
	 * @param autoAwake
	 */
	public static addChild(child: Element | HTMLElement | Component | ComponentNode, parent: HTMLElement | Component | ComponentNode, autoAwake: boolean = true) {
		/**
		 * Prepare element and node
		 */
		let parentNode: ComponentNode = ComponentNode.searchFirstActiveParentNodeFromElement(parent);
		let parentElement: HTMLElement = ComponentNode.getElement(parent);

		let childElement:HTMLElement = ComponentNode.getElement(child);
		if(Component.isComponent(child)) {
			if(child.isDestroyed()) {
				console.warn( "You are adding a Component that has already been killed. Component and all its child Components will be added with new instances." );
			}
		}

		/**
		 * Stop if already added
		 */
		if(childElement.parentElement === parentElement) {
			// console.log( 'Child elements parent is the same.' );
			return;
		}

		/**
		 * Activate all child nodes
		 */
		let nodes: ComponentNode[] = ComponentNode.searchFirstActiveNestedNodesFromElement(childElement);
		nodes.forEach( item=> {
			item.__activateNode();
		});

		/**
		 * Append child and set new parent node. Node can be null.
		 */
		parentElement.appendChild(childElement);
		ComponentNode.searchFirstActiveNestedNodesFromElement(childElement).forEach(item => {
			item.__setParentNode(parentNode);
		});

		/**
		 * Create all components of the child element or try to awake if components already exist.
		 */
		if(Component.isElementOfComponentType(childElement)) {
			let node = ComponentNode.searchActiveNodeFromElement( childElement );
			if( node ) {
				if(autoAwake) {
					node.getComponents().forEach(item => {
						item.__tryAwake();
					});
				}
			} else {
				Component.create(childElement, parentNode, autoAwake);
			}
		} else {
			const nested = ComponentNode.searchFirstActiveNestedNodesFromElement(childElement);
			if(autoAwake && nested.length > 0) {
				nested.forEach( node => {
					node.getComponents().forEach(comp => {
						comp.__tryAwake();
					} );
				})
			} else {

				Component.createSubComponents(childElement, parentNode, false);
			}
		}
	}

	/**
	 * Removes the Element from the DOM and if @param destroy is set to true also the Node and all it's components.
	 *
	 * @param child child to be removed.
	 *
	 * @param destroy If set to false, the node and all it's components wont be destroy and can be added back [[ComponentNode.addChild]] at any point.
	 * When setting this to false the node will be kept in the memory and its now your job to kill it if it does not get added to dom again.
	 * Do this simply by calling [[removeChild]] again, but with the destroy parameter set to true.
	 */

	public static removeChild(child: HTMLElement | Component | ComponentNode, destroy: boolean = true) {
		if(!child) {
			console.error('Cant remove null type child');
			return;
		}

		let element: HTMLElement = ComponentNode.getElement(child);
		let nodes: ComponentNode[] = ComponentNode.searchFirstActiveNestedNodesFromElement(element);
		nodes.forEach( item => {
			item.__removeFromDOM(destroy);
		});
	}

	public static replaceChild(child: Element | HTMLElement | Component | ComponentNode, replaceChild: HTMLElement | Component | ComponentNode, autoAwake: boolean = true, destroyReplacedChild:boolean = true) {
		let replaceElement: HTMLElement = ComponentNode.getElement(replaceChild);

		if(!replaceElement.parentElement) {
			console.error('The element you are trying to replace has no parent.');
			return;
		}

		ComponentNode.addChild(child, replaceElement.parentElement, autoAwake);
		replaceElement.parentElement.replaceChild(ComponentNode.getElement(child), replaceElement);
		ComponentNode.removeChild( replaceChild, destroyReplacedChild );
	}

	public static isComponentNode(el: any): el is ComponentNode {
		return (el as ComponentNode).__componentNodeTypeGuard !== undefined;
	}

	public static getElement(element: Element | HTMLElement | Component | ComponentNode ):HTMLElement {
		if(Component.isComponent(element) || ComponentNode.isComponentNode(element) ) {
			return element.getElement();
		}
		return element as HTMLElement;
	}

	public static searchFirstActiveParentNodeFromElement(element: HTMLElement | Component | ComponentNode ): ComponentNode {
		if(Component.isComponent(element)) {
			return element.node;
		} else if(ComponentNode.isComponentNode(element)) {
			return element;
		}

		let parent: HTMLElement = element;
		while(parent) {
			if(
				parent.getAttribute(Component.SEARCH_MODULE)
			) {
				break;
			}
			parent = parent.parentElement;
		}

		if(parent) {
			return ComponentNode.searchActiveNodeFromElement(parent);
		}

		return null;
	}

	public static searchActiveNodeFromElement(element: Element): ComponentNode {
		const l = ComponentNode.ALL_ACTIVE_NODES.length;

		for(let i = 0; i < l; i++) {
			let item = ComponentNode.ALL_ACTIVE_NODES[i];
			if(item.getElement() === element) {
				return item;
			}
		}
		return null;
	}

	public static searchFirstActiveNestedNodesFromElement(element: Element): ComponentNode[] {
		const node = ComponentNode.searchActiveNodeFromElement(element);
		if(node) {
			return [node];
		}

		const childDivs = Component.getAllChildComponentDivs(element, true);
		const result: ComponentNode[] = [];

		childDivs.forEach(item => {
			const node = ComponentNode.searchActiveNodeFromElement(item);
			if(node) {
				result.push(node);
			}
		});

		return result;
	}


	public static searchForParentComponentNode(node: ComponentNode, element: Element): ComponentNode {
		let searchCount = 0;
		let parentDivWithComp: Element = element;
		do {
			if(Component.isElementOfComponentType(parentDivWithComp)) {
				break;
			}
			parentDivWithComp = parentDivWithComp.parentElement;
			searchCount++;
		} while(parentDivWithComp.parentElement && searchCount < 200);

		if(node.getElement() === parentDivWithComp) {
			return node;
		} else {
			const childTrees = node.getChildComponentNodes(); // childTrees;
			const l = childTrees.length;
			let result = null;
			for(let i = 0; i < l; i++) {
				result = ComponentNode.searchForParentComponentNode(childTrees[i], parentDivWithComp);
				if(result) {
					break;
				}
			}

			return result;
		}
	}

	public static searchRootComponentNode(node: ComponentNode): ComponentNode {
		let parent: ComponentNode = node;
		while (parent.getParent()) {
			parent = parent.getParent();
		}
		return parent;
	}

}