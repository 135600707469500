import VarDumpItem from "./VarDumpItem";
import { Severity } from "./VarDumpWarning";
import Component from "core/ts/system/Component";
import VarDumpArray from "./VarDumpArray";
/**
 * @ignore
 */
export default class VarDumpNull extends VarDumpItem {
    protected length: number = 0;
    protected type: string = "null";

    
    constructor(name: string, path: string, parentArray: VarDumpArray) {
		super(name, path, parentArray);
	}

	public createElement() : HTMLElement {
        this.getContainer().classList.add('dump-variable-element');
		
		const container = <div class="dump-item dump-item--null">
			<div class="name">
				{this.name}
			</div>
			<div class="type">
				<p>null</p>
			</div>
		</div>;

		this.getContainer().appendChild(container);

		//TODO: change this to be included when variables in tsx can be used as attributes
        container.classList.add('dump-'+this.type);
        
        this.addWarning("null", Severity.severe);

		return this.getContainer();
    }
}