import VarDumpArray from "./VarDumpArray";
import VarDumpWarning, { Severity } from "./VarDumpWarning";
import Component from "core/ts/system/Component";
/**
 * @ignore
 */
export default abstract class VarDumpItem {
	private _name: string;
	private _path: string;
	private _parentArray: VarDumpArray;
	private _button: HTMLButtonElement;
	private _warnings: VarDumpWarning[] = [];
	
	protected _container: HTMLDivElement;

	constructor(name: string, path:string, parentArray: VarDumpArray) {
		this._container = <div class="item" />;
		this._name = name;
		this._path = path;
		this._parentArray = parentArray;

		this._container.addEventListener("mouseover", this.hover);
		this._container.addEventListener("mouseout", this.hide);

		this._button = <button class="copy-btn" />;
		this._container.appendChild(this._button);
		this._button.addEventListener("mouseup", this.copy);
	}

	private hover = (e:MouseEvent) => {
		e.stopPropagation();

		this._button.classList.add("hovered");
	} 

	private hide = (e:MouseEvent) => {
		this._button.classList.remove("hovered");
	}

	private copy = (e:MouseEvent) => {
		e.stopImmediatePropagation();
		e.stopPropagation();

		this.copyToClipboard(this.path);
	}
	
	protected get name(): string {
		return this._name;
	}

	protected abstract get length():number;
	protected abstract get type():string;

	protected getContainer(): HTMLDivElement {
		return this._container;
	}

	public addWarning(warning: string, severity: Severity) {
		this._warnings.push(new VarDumpWarning(warning, severity));
	}

	protected findLength(line: any): number {
		if(line == null) {
			return 0;
		}

		if(typeof(line) === 'number') return line;
		if(typeof(line) === 'string') return line.length;
		if(typeof(line) === 'object') return Object.keys(line).length;

		return undefined;
	}

	protected copyToClipboard(text: string) {
		text = text.substring(1, text.length);

		const textArea = document.createElement("textarea");
		textArea.value = text;
		this.getContainer().appendChild(textArea);
		textArea.focus();
		textArea.select();
	
		try {
			document.execCommand('copy');
		} catch (err) {
			console.error('Fallback: Oops, unable to copy', err);
		}
	
		this.getContainer().removeChild(textArea);
	}

	protected get pathAddition():string {
		if(!this._parentArray || !this._parentArray.isNumericArray) {
			return "." + this.name;
		}

		return "["+ this.name +"]";
	}

	protected get path() : string {
		return this._path+this.pathAddition;
	}

	public abstract createElement(): HTMLElement;
}