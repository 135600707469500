import Component from "core/ts/system/Component";
import ColorManager from "ts/ColorManager";
import StringUtils from "core/ts/utils/StringUtils";

export default class ColorItem extends Component {
	protected awake(): void {
		ColorManager.Instance.onBackgroundColorChange.sub(this.onBackgroundChange);
		ColorManager.Instance.onForegroundColorChange.sub(this.onForegroundChange);

		this.backgroundChange(ColorManager.Instance.backgroundColor);
		this.foregroundChange(ColorManager.Instance.foregroundColor);
	}

	protected sleep(): void {
		ColorManager.Instance.onBackgroundColorChange.unsub(this.onBackgroundChange);
		ColorManager.Instance.onForegroundColorChange.unsub(this.onForegroundChange);
	}

    private onBackgroundChange = () => {
        this.backgroundChange(ColorManager.Instance.backgroundColor);
    };

    private onForegroundChange = () => {
        this.foregroundChange(ColorManager.Instance.foregroundColor);
    };

    protected backgroundChange(color: String): void {}

    protected foregroundChange(color: String): void {}

    protected getOpacityFromBorderStyle(color:string):number {
        // return 1;

        if(color.indexOf('rgba') === -1) {
            return 1;
        }

        let split1 = color.split('rgba');
        let split2 = split1[1].split(',');
        let end = split2[split2.length - 1];

        return Number(StringUtils.getAlphaNumeric(end));
    }
}