import './DebugGridModule.scss';

import Component from "core/ts/system/Component";
import App from 'ts/app';

export default class DebugGridModule extends Component {
	private _visible: Boolean = false;
	private _columns: HTMLElement[];
	private _mousePos: {x:Number, y: Number} = {x: 0, y: 0};
	
	private _activeLayout: HTMLElement;

	protected awake() {
		document.addEventListener('keyup', this.onKeyUp);
		document.addEventListener('keydown', this.onKeyDown);

		this._columns = [].slice.call(this.getElement().querySelectorAll(".columns"));

		if(window.localStorage.getItem('DebugGridModule_visible') == 'true') {
			this._visible = true;
			this.updateVisibility();
		}
	}

	private onResize = () => {
		this._columns.forEach(column => { column.classList.add("columns--hidden") });

		let smallerBreakpoints = this._columns.filter(column => parseInt(column.dataset.size) <= App.RESIZE.windowWidth);
		smallerBreakpoints = smallerBreakpoints.sort(DebugGridModule.sortBreakpoints);

		for (let i = 0; i < smallerBreakpoints.length; i++) {			
			i === 0 ? smallerBreakpoints[i].classList.remove("columns--hidden") : smallerBreakpoints[i].classList.add("columns--hidden");
		}

		this._activeLayout = smallerBreakpoints[0];
	};

	private static sortBreakpoints(a:HTMLElement, b:HTMLElement): number {
		if(parseInt(a.dataset.size) < parseInt(b.dataset.size)) {
			return 1;
		} else if(parseInt(a.dataset.size) > parseInt(b.dataset.size)) {
			return -1;
		}
		return 0;
	}

	private onKeyDown = (e: KeyboardEvent) => {
		if(e.key === 'f' && this._visible) {
			this.expand();
		}
	};

	private onKeyUp = (e: KeyboardEvent) => {
		if(e.key === 'g') {
			this._visible = !this._visible;
			window.localStorage.setItem('DebugGridModule_visible', this._visible == true ? 'true' : 'false');
			this.updateVisibility();
		} else if(e.key === 'f' && this._visible) {
			this.contract();
		}
	};

	private updateVisibility() {
		// console.log(this._visible);
		if(this._visible) {
			this.getElement().classList.add("DebugGridModule--visible");
			this.onResize();

			document.addEventListener('mousemove', this.mouseMove);
			App.RESIZE.onResize.sub(this.onResize);
		} else {
			this.getElement().classList.remove("DebugGridModule--visible");			
			this.contract();
			
			document.removeEventListener('mousemove', this.mouseMove);
			App.RESIZE.onResize.unsub(this.onResize);
		}
	}

	private mouseMove = (e: MouseEvent) => {
		this._mousePos.x = e.screenX;
		this._mousePos.y = e.screenY;
	};

	private expand() {
		// console.log("expanding");
	}

	private contract() {
		// console.log("contracting");
	}
	
	protected sleep() {
		document.removeEventListener('keyup', this.onKeyUp);
		document.removeEventListener('keydown', this.onKeyDown);
		document.removeEventListener('mousemove', this.mouseMove);
		App.RESIZE.onResize.unsub(this.onResize);
	}
}