import Component from '../Component';
import {TweenLite} from "gsap";
import FilterNode from "core/ts/utils/FilterNode";
import StaticTemplate from 'core/ts/system/static/StaticTemplate';
import BoundingBox from 'core/ts/utils/BoundingBox';
import { SignalDispatcher } from 'strongly-typed-events';

export default class StaticElement extends Component {

	private _onEnter = new SignalDispatcher();
	public get onEnter() {
	    return this._onEnter.asEvent();
	}

	private _onExit = new SignalDispatcher();
	public get onExit() {
	    return this._onExit.asEvent();
	}

	private _isInitiating: boolean = false;

	private _filterNodes: FilterNode<StaticElement>;

    private _container: HTMLElement;
    private _fakeHeight:HTMLElement;

    public staticPageY:number = 0;

    private _isEntered = false;

    private _height = -1;
	private _width = -1;

	/**
	 * @ignore
	 */
	public __init() {
		// console.log(this.getRoot().getSibling<StaticTemplate>(StaticTemplate));
		// if(!this.getRoot().getSibling<StaticTemplate>(StaticTemplate)){
		// 	super.__init();
		// 	return;
		// }

		this._isInitiating = true;
		this._filterNodes = new FilterNode<StaticElement>(this, StaticElement);

        this._filterNodes.onAdded.sub( this.onNewAdded );
		this._filterNodes.onRemoved.sub( this.onRemoved );

		this.setupContainer();
		super.__init();

		this._isInitiating = false;
	}

	private onNewAdded = ( comp:StaticElement ) => {
		const template = this.getRoot().getComponentByType<StaticTemplate>(StaticTemplate);
		if(!template) {
			console.error( "Root element must be an instance of StaticTemplate" );
			return;
		}

		template.calculatePageHeight();
    };

	private onRemoved = ( comp:StaticElement ) => {
		const template = this.getRoot().getComponentByType<StaticTemplate>(StaticTemplate);
		if(!template) {
			console.error( "Root element must be an instance of StaticTemplate" );
			return;
		}
		template.calculatePageHeight();
	};

	private setupContainer() {

        this._fakeHeight = document.createElement('div');
        this._fakeHeight.classList.add('static-fake-height');
        this._container = document.createElement('div');
        this._container.classList.add('static-module');

        //TODO:Move this to .scss
        this._fakeHeight.style.position = 'relative';
        this._fakeHeight.style.pointerEvents = 'none';

        this._container.style.position = 'absolute';
        this._container.style.width = '100%';
        this._container.style.height = 'auto';

        TweenLite.set( this._fakeHeight, {height:this.getElement().offsetHeight} );

        this.getElement().parentElement.insertBefore(this._container, this.getElement());
        this.getElement().parentElement.insertBefore(this._fakeHeight, this.getElement());
        this._container.appendChild(this.getElement());
    }


    /**
     * @ignore
     */
    public __updateStaticPageY() {
        this.updateStaticPageY();

        this._filterNodes.children.forEach(item=>{
            item.__updateStaticPageY();
        });
    }

    public updateViewBounds(bounds:BoundingBox ) {
		if(
			this.staticPageY < bounds.y + bounds.height &&
			this.staticPageY + this.getHeight() > bounds.y
		) {
			if(!this.isEntered()) {
				this.__enter();
			}

			this._filterNodes.children.forEach(item=>{
				item.updateViewBounds(bounds);
			});

		} else {
			if(this.isEntered()) {
				this.__exit();
				this._filterNodes.children.forEach(item=>{
					item.__exit();
				});
			}
		}
    }

    public updateStaticPageY() {
        this.staticPageY = 0;
        let el = this.getElement();
        while( el ) {
            this.staticPageY += el.offsetTop;
            el = el.parentElement;
        }
    }

    public getHeight() {
        return this._height;
    }

	public getWidth() {
		return this._width;
	}

    public isEntered() {
        return this._isEntered;
    }

	/**
	 * @ignore
	 */
	public __enter() {
        this._isEntered = true;
        this.showElementContainer( false );
        this.enter();
		this._onEnter.dispatch();
    }

	/**
	 * @ignore
	 */
    public __exit() {
        this._isEntered = false;
        this.hideElementContainer( false );
        this.exit();
        this._onExit.dispatch();
    }

    protected enter() {
    }

    protected exit() {
    }

	/**
	 * @ignore
	 */
    public __updateStaticSize() {
        //Update children before parent to calculate the right height of the parent;
        this._filterNodes.children.forEach(item=>{
            item.__updateStaticSize();
        });

        this.updateStaticSize();
    }

    public updateStaticSize() {
	    // if( this.getElement().offsetHeight === 0){
		//     return;
	    // }



	    const st = window.getComputedStyle(this.getElement());
	    this._height = this.getElement().offsetHeight + parseInt(st.marginTop) + parseInt(st.marginBottom);
	    this._width = this.getElement().offsetWidth + parseInt(st.marginLeft) + parseInt(st.marginRight);

        // console.log(this._height);
        TweenLite.set( this._fakeHeight, {height:this._height, width:this._width} );
    }

    /**
     * @ignore
     */
    protected __sleep() {
	    // if(!this.getRoot().getSibling<StaticTemplate>(StaticTemplate)){
		//     super.__sleep();
		//     return;
	    // }
        //Remove fake container;
	    this._container.parentElement.appendChild(this._container.children[0]);
	    this._container.parentElement.removeChild( this._container );

        if(this._fakeHeight.parentElement){
            this._fakeHeight.parentElement.removeChild( this._fakeHeight );
        }
        super.__sleep();
        this._filterNodes.kill();
    }

    public showElementContainer( recursive:boolean = true ) {
    	if(!this._container) {
    		return;
	    }
        this._container.style.display = null;

        if(recursive) {
	        this._filterNodes.children.forEach(item=>{
	            item.showElementContainer();
	        });
        }
    }

    public hideElementContainer( recursive:boolean = true ) {
	    if(!this._container) {
		    return;
	    }
        this._container.style.display = 'none';

        if(recursive) {
	        this._filterNodes.children.forEach(item=>{
	            item.hideElementContainer();
	        });
        }
    }

}
