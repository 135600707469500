//Import for Webpack
import './ForeignDesksModule.scss';

import Module from 'core/ts/system/Module';
import LinkHandler from "core/ts/utils/LinkHandler";

export default class ForeignDesksModule extends Module {

    /**
    * Template and modules will never be added or build before all nested modules are loaded
    * If you need something loaded before this and all childmodules gets build() and added to the dom. This is where you do it.
    * Call onComplete() parameter when you are done loading. (Dont forget this, also make sure to listen for fail and call onComplete).
    *
    * --This will only ever get called once.
    */
    // protected load( onComplete:Function ): void {
    //      //Call onComplete when loading is done
    //      onComplete();
    // }

    /**
    * Called when this and all nested modules are loaded.
    * Use this function to setup and manipulate the dom.
    *
    * --This will only ever get called once.
    * --Element is not yet added to the DOM
    */
    // protected build(): void {
    // }

    /**
    * Called right after the element is added to the DOM
    * Add eventlisteners and prepare/init the component
    */
    protected awake(): void {
        LinkHandler.Instance.addAllAtags(this.getElement());
    }

    /**
    * Called when the component is about to leave the scene
    * Use this to stop any ongoing loadings.
    * So that new loading content doesnt get added during an animation out or other exit/leave/destroy operations are in progress.
    *
    * --This gets called before animating out of screen or destroy/kill commands
    * --Added new content (by async loading) while destroying could potentially create some errors.
    */
    //protected unload(): void {
    //}

    /**
    * Called right before element gets removed from the dom
    * Remove listeners and kill stuff
    */
    protected sleep(): void {
        LinkHandler.Instance.removeAllAtags(this.getElement());
    }

}