export default class BoundingBox {

    public x:number = 0;
    public y:number = 0;
    public width:number = 0;
    public height:number = 0;

    constructor( x:number = 0, y:number = 0, width:number = 0, height:number = 0 ) {
        this.x = x;
        this.y = y;
        this.width = width;
        this.height = height;
    }

    public apply( box:BoundingBox ) {
        this.x += box.x;
        this.y += box.y;
        this.width += box.width;
        this.height += box.height;
    }

    public convertFrom( box:ClientRect | DOMRect ) {
        this.x = box.left;
        this.y = box.top;
        this.width = box.width;
        this.height = box.height;
    }

    public static from( box:ClientRect | DOMRect ) : BoundingBox {
        return new BoundingBox( box.left, box.top, box.width, box.height );
    }

}