//Import for Webpack
import './ButtonEffect.scss';

import Module from 'core/ts/system/Module';
import {TweenLite, Expo, Ease, Quad, Sine, Quart} from 'gsap';

export default class ButtonEffect extends Module {
    private _svg:HTMLElement = null;
    private _container:HTMLElement = null;

    private _tween:any = {ratio: 0};

    private _endY:number;
    private _width:number;

    protected awake(): void {
        return;
        this.getElement().addEventListener('mouseover', this.onMouseOver);
        this.getElement().addEventListener('mouseout', this.onMouseOut);

        this._endY = 3 - Math.random() * 6;

        this._width = this.getElement().offsetWidth;

        this._container = document.createElement('div');
        this._container.style.pointerEvents = 'none';
        this._container.style.position = 'absolute';
        this._container.style.top = '2px';
        this._container.style.left = '-10px';
        // this._container.style.width = '200px';
        // this._container.style.backgroundColor = 'red';
        this.getElement().appendChild(this._container);

        this._svg = document.createElement('div');
        // this._svg.style.position = 'absolute';
        this._svg.style.top = 20 + 'px';


        this._container.appendChild(this._svg);
    }

    protected sleep(): void {
        this.getElement().removeEventListener('mouseover', this.onMouseOver);
        this.getElement().addEventListener('mouseout', this.onMouseOut);

    }

    private onMouseOver = (e:Event):void => {

        TweenLite.to(this._tween, .3, {ratio: 1, ease: Expo.easeInOut, overwrite: true, onUpdate: this.updateToRatio});

    };

    private onMouseOut = (e:Event):void => {

        TweenLite.to(this._tween, .3, {ratio: 0, ease: Expo.easeInOut, overwrite: true, onUpdate: this.updateToRatio});

    };




    private updateToRatio = ():void => {
        let startX:number = 10;
        let startY:number = 20;

        let endX:number = startX + this._width;

        let currPt:any = this.interpolate(startX, startY, endX, startY + this._endY, this._tween.ratio);

        let data:string = '<svg xmlns="http://www.w3.org/2000/svg"><line stroke-width="1.5" x1="' + startX + '" y1="' + startY + '" x2="' + currPt.x + '" y2="' + currPt.y + '" stroke="white" /></svg>';
        // console.log('data : ' + data);
        this._svg.innerHTML = data;
    };

    private interpolate(x1:number, y1:number, x2:number, y2:number, ratio:number):any {
        let nx:number = x1 + (x2 - x1) * ratio;
        let ny:number = y1 + (y2 - y1) * ratio;
        return {x: nx,  y: ny};
    }
}