export default class ColorUtils {

    public static getRandomHex(addHex:boolean = true):string {
        let preFix = addHex ? '#' : '';
        return preFix + Math.floor(Math.random() * 16777215).toString(16);
    }

    public static hexToRGB(hex:string, alpha:number) {
        let r = parseInt(hex.slice(1, 3), 16),
            g = parseInt(hex.slice(3, 5), 16),
            b = parseInt(hex.slice(5, 7), 16);

        if (alpha) {
            return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
        } else {
            return "rgb(" + r + ", " + g + ", " + b + ")";
        }
    }

    public static rgbToHex(r:number, g:number, b:number) {
        return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
    }

    public static mix(color_1: string, color_2: string, weight: number) {
        function d2h(d:number) { return d.toString(16); }  // convert a decimal value to hex
        function h2d(h:string) { return parseInt(h, 16); } // convert a hex value to decimal
        
        color_1 = color_1.replace(/#/g , '');
        color_2 = color_2.replace(/#/g , '');
        
        weight = (typeof(weight) !== 'undefined') ? weight : 50; // set the weight to 50%, if that argument is omitted
        
        let color = "#";

        for(var i = 0; i <= 5; i += 2) { // loop through each of the 3 hex pairs—red, green, and blue
            var v1 = h2d(color_1.substr(i, 2)), // extract the current pairs
                v2 = h2d(color_2.substr(i, 2)),
                
                // combine the current pairs from each source color, according to the specified weight
                val = d2h(Math.round(v2 + (v1 - v2) * (weight / 100.0))); 
        
            while(val.length < 2) { val = '0' + val; } // prepend a '0' if val results in a single digit
            
            color += val; // concatenate val to our new color string
          }
            
          return color; // PROFIT!
    }
}

