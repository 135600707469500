//Import for Webpack
import Component from "core/ts/system/Component";
import SlideshowComponent from "core/modules/CoreSlideshowModule/SlideshowComponent";

export default class SlideshowPageInfo extends SlideshowComponent {
    private _format: string;

    protected build(): void {
        this._format = this.params.plain.format;

        if(this._format == null || this._format == '') {
            this._format = '[CURRENT]/[TOTAL]';
        }

        super.build();
    }

    protected updateToPage(): void {
        super.updateToPage();

        let final = this._format.replace('[CURRENT]', (this.slideshow.getCurrentIndex() + 1).toString());
        final = final.replace('[TOTAL]', this.slideshow.getNumOfSlides().toString());
        this.getElement().innerText = final;
    }
}