import { SimpleEventDispatcher } from 'strongly-typed-events';
import TemplateManager from 'core/ts/system/TemplateManager';
import Path from 'core/ts/utils/Path';

export default class LinkHandler {

	private _links: LinkHandlerItem[] = [];

	private _activeKeyCodes:Array<number> = [];

	private static _instance: LinkHandler;
	public static get Instance(): LinkHandler {
		if(this._instance == null) {
			this._instance = new LinkHandler();
		}

		return this._instance;
	}

	constructor() {
		window.addEventListener('keydown', this.onKeyDown);
		window.addEventListener('keyup', this.onKeyUp);


		window.addEventListener('focus', this.onFocus);
	}

	private onFocus = (event:any):void => {
		// console.log('LinkHandler.onFocus();');

		this._activeKeyCodes = [];

		// console.log('this._activeKeyCodes : ' + this._activeKeyCodes);
	};

	private onKeyDown = (event:KeyboardEvent):void => {
		let index:number = this._activeKeyCodes.indexOf(event.keyCode);
		if(index === -1) {
			this._activeKeyCodes.push(event.keyCode);
		}

		// console.log('this._activeKeyCodes : ' + this._activeKeyCodes);
	};

	private onKeyUp = (event:KeyboardEvent):void => {
		// console.log('event.keyCode : ' + event.keyCode);

		let index:number = this._activeKeyCodes.indexOf(event.keyCode);
		if(index !== -1) {
			this._activeKeyCodes.splice( index, 1 );
		}

		// console.log('this._activeKeyCodes : ' + this._activeKeyCodes);
	};

	public add(link: Element): void {
		let item = new LinkHandlerItem(link);
		item.onClick.subscribe(this.onLinkClick);
		this._links.push(item);
	}

	public addAllAtags(link: Element) {
		let aTags = link.querySelectorAll('a');
		aTags.forEach(c => this.add(c));
	}
	public remove(link: Element) {
		const l = this._links.length;
		for (let i = 0; i < l; i++) {
			if (this._links[i].target === link) {
				this._links[i].dispose();
				this._links.splice(i, 1);

				return;
			}
		}
	}

	public removeAllAtags(link: Element) {
		let aTags = link.querySelectorAll('a');
		aTags.forEach(c => this.remove(c));
	}

	private onLinkClick = (item: LinkHandlerItem) => {
		this.handleLinkClick(item.link);
	};

	public handleLinkClick(link:string):void {
		const baseUrl: string = Path.getBaseUrl();

		// console.log(this._activeKeyCodes);
		console.log('link : ' + link);


		if (this.useTemplate(link)) {
			let internalPath:string = link.replace(baseUrl, '');

			console.log('internalPath : ' + internalPath);

			if(this._activeKeyCodes.indexOf(91) === - 1) {
				console.log("template stuff")
				TemplateManager.Instance.goto(internalPath);
			} else {
				window.open(internalPath, "_blank");
			}
		} else {
			if (link.indexOf('mailto:') !== -1) {
				window.location.href = link;
			} else {				
				// let target:string = this._activeKeyCodes.indexOf(91) !== -1 ? '_blank' : '_self';
				let target:string = 'blank';
				window.open(link, target);
			}
		}
	}


	public useTemplate(url: string) {
		return Path.isInternal(url) && !Path.isSubdomain(url) && !Path.isEmail(url) && !Path.isFile(url);
	}

}

class LinkHandlerItem {
	private _onClick = new SimpleEventDispatcher<LinkHandlerItem>();
	public get onClick() {
		return this._onClick.asEvent();
	}

	public target: Element;
	public link: string = '';

	constructor(element: Element) {
		this.target = element;
		this.link = this.target.toString();
		// console.log( this.link );
		this.target.addEventListener('click', this.handleClickEvent);
	}

	public dispose() {
		this.onClick.clear();
		this.target.removeEventListener('click', this.handleClickEvent);
	}

	private handleClickEvent = (e: MouseEvent) => {
		e.preventDefault();
		e.stopPropagation();

		this._onClick.dispatch(this);
	};
}
