//Import for Webpack
import './NewsPreview.scss';

import Module from 'core/ts/system/Module';
import LazyLoadImage from 'core/ts/utils/image/LazyLoadImage';

export default class NewsPreview extends Module {

    private _shortDescription:string = null;
    private _outerModule:HTMLElement = null;

    protected build(): void {
        this._shortDescription = this.qs('.description').innerHTML.toLowerCase();
	    const images = this.getComponentsByType<LazyLoadImage>(LazyLoadImage);
	    images.forEach(img=> {
		    img.setImageHeightToParent = false;
        })

        /* ****** Quick height fix on insigths *******/
        const img:HTMLElement = this.qs(".image");
        
        this._outerModule = this.getElement().parentElement.parentElement.parentElement.parentElement
        
        if(this._outerModule.className === "Module PublicationsModule") {
            img.style.height = "140px";
        }
        /******** quick fix end ********* */
    }

    public matchesSearch(search:string):boolean {

        // console.log('search : ' + search);
        // console.log('this._shortDescription : ' + this._shortDescription);

        if(this._shortDescription === null || this._shortDescription === undefined || this._shortDescription.length === 0) {
            return false;
        }

        if(this._shortDescription.toLowerCase().indexOf(search.toLowerCase()) !== - 1) {
            return true;
        }

        return false;
    }
}