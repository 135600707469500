//Import for Webpack
import './PreloaderModule.scss';

import Module from 'core/ts/system/Module';
import RenderLoop, {RenderItem} from "core/ts/utils/RenderLoop";
import TemplateManager from "core/ts/system/TemplateManager";
import {Ease, Expo, TweenLite} from "gsap";
import {SignalDispatcher} from "strongly-typed-events";

export default class PreloaderModule extends Module {
    private _onTransitionUpdate = new SignalDispatcher();
    public get onTransitionUpdate() {
        return this._onTransitionUpdate.asEvent();
    }

    private _transitionSpeed:number = 1;
    private _ease:Ease = Expo.easeInOut;

    private _render:RenderItem = null;

    private _container:HTMLElement = null;
    private _colors:Array<string> = new Array<string>('#283C37', '#B49646', '#87A0A5', '#423C41');

    public static Instance:PreloaderModule;

    public tweenValues = {offsetTop: 0};

    protected awake(): void {
        PreloaderModule.Instance = this;

        this._container = this.qs('.container');

        TemplateManager.Instance.onLoadingStart.sub(this.onNewTemplateStartLoading);
        TemplateManager.Instance.onLoadingDone.sub(this.onNewTemplateLoaded);
    }

    private onNewTemplateStartLoading = ():void => {
        // console.log('PreloaderModule.onNewTemplateStartLoading();');

        let siteWrapper:HTMLElement = document.getElementById('SiteWrapper');

        const topOffset:number = 12;

        TweenLite.to(this.tweenValues,
            this._transitionSpeed,{ease: this._ease,
                offsetTop: topOffset,
                delay: this._transitionSpeed / 2,

                onStart: () => {
                    PreloaderModule.Instance.start();
                },

                onUpdate: () => {
                    TweenLite.set(siteWrapper, {y: this.tweenValues.offsetTop});
                    this._onTransitionUpdate.dispatch();
                },
                overwrite: true
            });
    };

    private onNewTemplateLoaded = ():void => {
        // console.log('PreloaderModule.onNewTemplateLoaded();');

        let siteWrapper:HTMLElement = document.getElementById('SiteWrapper');

        // console.log('this.tweenValues.offsetTop : ' + this.tweenValues.offsetTop);

        TweenLite.to(this.tweenValues,
            this._transitionSpeed, {
                ease: this._ease,
                offsetTop: 0,
                // scale: .9

                onUpdate: () => {
                    TweenLite.set(siteWrapper, {y: this.tweenValues.offsetTop});
                    this._onTransitionUpdate.dispatch();
                },
                overwrite: true
            }
        );
    };


    private render = (delta:number, total:number):void => {
        let l:number = this._colors.length;

        let progress:number = (total * .5) % 1;

        let middleColorStart = Math.floor(l * progress);
        let middleColorEnd = (middleColorStart + 1) % l;
        let middleColorProgress = l * progress - middleColorStart;

        let middleColor = this.lerpColor(this._colors[middleColorStart], this._colors[middleColorEnd], middleColorProgress);

        let leftColorStart = Math.floor(l * progress) - 1;
        if(leftColorStart < 0) {
            leftColorStart = l + leftColorStart;
        }
        let leftColorEnd = (leftColorStart + 1) % l;

        let leftColor = this.lerpColor(this._colors[leftColorStart], this._colors[leftColorEnd], middleColorProgress);
        let style:string = 'linear-gradient(90deg, ' + leftColor + ' 0%, ' + middleColor + ' 100%' + ')';

        // console.log('style : ' + style);

        this._container.style.background = style;
    };

    public start():void {
        if(this._render !== null) {
            return;
        }
        this._render = RenderLoop.Instance.add(this.render, 60);
    }

    public end():void {
        if(this._render === null) {
            return;
        }
        RenderLoop.Instance.remove(this._render);
        this._render = null;
    }

    //@ts-ignore
    private lerpColor(a, b, amount) {
        var ah = parseInt(a.replace(/#/g, ''), 16),
            ar = ah >> 16, ag = ah >> 8 & 0xff, ab = ah & 0xff,
            bh = parseInt(b.replace(/#/g, ''), 16),
            br = bh >> 16, bg = bh >> 8 & 0xff, bb = bh & 0xff,
            rr = ar + amount * (br - ar),
            rg = ag + amount * (bg - ag),
            rb = ab + amount * (bb - ab);

        return '#' + ((1 << 24) + (rr << 16) + (rg << 8) + rb | 0).toString(16).slice(1);
    }
}